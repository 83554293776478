import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoOrdenCosecha.css';
import { BiEdit } from "react-icons/bi";
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaLotesCortes, getListaZonas, getListaRanchosDisponibles, getCatalogoOrdenCosecha } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import ModalFormularioOrdenCosecha from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalFormularioOrdenCosecha';


export default class CatalogoOrdenCosecha extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      txtselected: '0',
      txtselectedzona: '0',
      txtselectedrancho: '0',

      filtrolistapaginada: [],
      elementosfiltrados: [],
      listazonas: [],
      listaranchos: [],
      listalotes:[],
      estatus: [],

      tabla: {
        encabezados: [
          "Fecha cosecha",
          "Zona",
          "Rancho",
          "Lote",
          "Estimado",
          "Estatus",
          "",
          ""],
        dimensiones: [
          "20%",
          "25%",
          "25%",
          "12%",
          "12%",
          "8%",
          "130px",
          "150px"
        ],
        mindimensiones: [
          "20%",
          "25%",
          "25%",
          "12%",
          "12%",
          "8%",
          "130px",
          "150px"
        ],
        elementos: []
      },

      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso: false,

      //Modal
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,

      redireccionando: false,
      rutadireccionar: '',

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,
    }
  }

  async componentDidMount() {

    this.setState({ txtbuscador: '' })
    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if ( !this.state.loadingspinners ) {
      this.setState({ loadingspinners: true });
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis (estatus)) {
      return;
    }

    //obtiene catalogo de ordenes de cosecha
    var resp = await getCatalogoOrdenCosecha(); 
    if ( this.manejadorLlamadasApis (resp) ) {
      return;
    }

    //obtiene catalogo de zonas
    var respzonas = await getListaZonas();
    if ( this.manejadorLlamadasApis (respzonas) ) {
      return;
    }

    //obtiene catalogo de ranchos
    var respranchos = await getListaRanchosDisponibles();
    if ( this.manejadorLlamadasApis (respranchos) ) {
      return;
    }

    //obtiene catalogo de lotes
    var resplotes = await getListaLotesCortes();
    if ( this.manejadorLlamadasApis (resplotes) ) {
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = resp.data;

    await this.setState({
      txtbuscador: '',
      estatus: estatus.data,
      listazonas: respzonas.data,
      listaranchos: respranchos.data,
      listalotes:resplotes.data,
      tabla: tabla
    });

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ txtselectedzona: '0' })
    this.setState({ txtselectedrancho: '0' })
    this.setState({ errordeacceso: false })
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ txtselectedzona: '0' })
    this.setState({ txtselectedrancho: '0' })
    this.setState({ errordeacceso: false })
    this.setState({ loadingspinners: bandera });
  }

  //Funcion para filtrar por los select
  saveInputLocacion = async (e) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtrozonas = [];
    var filtroranchos = [];

    if(e.target.id=='zonas'){
      this.setState({ txtselectedzona: e.target.value });

      if(e.target.value==0){
        filtrozonas = data;
        this.setState({ txtselectedrancho: 0 });
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==e.target.value)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
    }

    else if(e.target.id=='ranchos'){
      this.setState({ txtselectedrancho: e.target.value });
      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(e.target.value==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==e.target.value)
      }
    }

    if(filtrozonas.length < 1 || filtroranchos.length < 1) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.filtro(1, [])
    }
    if(e.target.id=='zonas'){
      this.filtro(1, filtrozonas)
    }
    else if(e.target.id=='ranchos'){
      this.filtro(1, filtroranchos)
    }
  }

  format_numero_decimales = (number) => {
    return new Intl.NumberFormat('en-US',{ minimumFractionDigits: 2 }).format(number);
  }

  format_moneda = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
  }

  filtro = (pagenumber, array) => {

    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  cambiarContenido = (pagenumber) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: [],
      listapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);

  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nueva orden de cosecha', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar orden de cosecha', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })

    if(accion == 1){
      toast.success("Orden creada exitósamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }

    else if(accion == 2){
      toast.success("Orden editada exitósamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }

  }

  redireccionar = (cosecha) => {
    localStorage.setItem("ordencosecha", JSON.stringify(cosecha.contenido));
    if(cosecha.opcion==1){
      this.setState({ redireccionando: true, rutadireccionar: 'detallesordencosecha' })
    } else if(cosecha.opcion==2){
      this.setState({ redireccionando: true, rutadireccionar: 'seguridadordencosecha' })
    } else if(cosecha.opcion==3){
      this.setState({ redireccionando: true, rutadireccionar: 'operacionesordencosecha' })
    }
    this.setState({ redireccionando: true })
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  render() {
    return (
      <>
        {/*///////// Sección de modales, spinner y redireccionamiento ////////*/}

        {this.state.salir ?
            <>
              <SpinnerModal tipo="full" show={true} />
              <AccionesClientes bandera={'salir'} />
            </> 
        :null}

        {(this.state.redireccionando) ?
          (<AccionesClientes bandera={this.state.rutadireccionar}/>) 
        :null}

        <ToastContainer />
        {(this.state.loadingspinners == true) ?
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        :null}

        {(this.state.modaldetalles == true) ?
          <ModalFormularioOrdenCosecha titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModal}
            estatus={this.state.estatus}
            show={this.state.modaldetalles}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            listazonas={this.state.listazonas}
            listaranchos={this.state.listaranchos}
            listalotes={this.state.listalotes}
            setLoadingSpinner={this.setLoadingSpinner}
          />
        :null}

        {/*///////// Sección de navbar ////////*/}
        <Navbar />

        {/*///////// Sección de encabezado ////////*/}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end'>
              <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Orden de cosecha</label>
              </div>

              {/*///////// Filtro de zonas ////////*/}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                  <div className="selectBox ">
                      <select id='zonas' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                            {this.state.txtselectedzona == 0 ? <option selected="true" value='0'>Todas</option> : <option value='0'>Todas</option>}  
                            {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((item, index) => {
                              return (<option key={"z_"+index} value={item.idcatalogo_zona}>{item.zona}</option>);
                            })}
                      </select>
                  </div>
                </div>
              </div>

              {/*///////// Filtro de ranchos ////////*/}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Rancho</label>
                  <div className="selectBox ">
                      <select id='ranchos' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                            {this.state.txtselectedrancho == 0 ? <option selected="true" value='0'>Todos</option> : <option value='0'>Todos</option>}  
                            {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((item, index) => {
                              return (item.idcatalogo_zona==this.state.txtselectedzona || this.state.txtselectedzona==0 ? <option key={"r_"+index} value={item.idcatalogo_rancho}>{item.rancho}</option> : null);
                            })}
                      </select>
                  </div>
                </div>
              </div>

              {/*///////// Botón para refrescar lista ////////*/}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              {/*///////// Botón para agregar nueva orden de cosecha ////////*/}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>
        <br />

        {/*///////// Sección de tabla ////////*/}
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximo2 ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 && this.state.txtselectedzona == 0 && this.state.txtselectedrancho == 0 ?
                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorencabezadostabla  }}>
                          {item.fechacosecha}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla  }}>
                          {item.zona}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorencabezadostabla  }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorencabezadostabla  }}>
                          {item.lote}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4], color: this.state.colorencabezadostabla,textAlign: 'right'  }}>
                          {item.tipocorte == 'Tonelada' ? 
                            'Tn '
                          : item.tipocorte == 'Reja' ?
                            'Rejas '
                          :null}
                          {item.estimado}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5], color: this.state.colorencabezadostabla  }}>
                          {item.idestatus == 1 ? "Activo" : "Inactivo"}
                        </td>
                        {/*
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }} >
                            <label onClick={this.redireccionar.bind(this, { contenido: item, opcion: 2 })} style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                              <b className='btnVerTabla'>Seguridad</b>
                            </label>
                          </td>
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }} >
                            <label onClick={this.redireccionar.bind(this, { contenido: item, opcion: 3 })} style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                              <b className='btnVerTabla'>Operaciones</b>
                            </label>
                          </td>
                        */}
                        <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item})} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }} >
                          <button className='btnEditar' onClick={this.redireccionar.bind(this, { contenido: item, opcion: 1 })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}> Ver detalles</button>
                        </td>
                      </tr>
                    ))
                  :
                    this.state.filtrolistapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorencabezadostabla  }}>
                          {item.fechacosecha}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla  }}>
                          {item.zona}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorencabezadostabla  }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorencabezadostabla  }}>
                          {item.lote}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4], color: this.state.colorencabezadostabla,textAlign: 'right'  }}>
                          {this.format_moneda(item.estimado)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5], color: this.state.colorencabezadostabla  }}>
                          {item.idestatus == 1 ? "Activo" : "Inactivo"}
                        </td>
                        {/*
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }} >
                            <label onClick={this.redireccionar.bind(this, { contenido: item, opcion: 2 })} style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                              <b className='btnVerTabla'>Seguridad</b>
                            </label>
                          </td>
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }} >
                            <label onClick={this.redireccionar.bind(this, { contenido: item, opcion: 3 })} style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                              <b className='btnVerTabla'>Operaciones</b>
                            </label>
                          </td>
                        */}
                        <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item})} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }} >
                          <button className='btnEditar' onClick={this.redireccionar.bind(this, { contenido: item, opcion: 1 })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}> Ver detalles</button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {/*///////// Sección de paginación ////////*/}
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtselectedzona == 0 && this.state.txtselectedrancho == 0 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

function AccionesClientes({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  if (bandera == 'detallesordencosecha') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/detallesordencosecha"} />)
  } else if (bandera == 'seguridadordencosecha') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/seguridadordencosecha"} />)
  } else if (bandera == 'operacionesordencosecha') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/detallesordencosecha"} />)
  }

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }

}