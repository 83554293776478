import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './DetallesCotizacionToronja.css';
import { Table, Button, InputGroup, InputGroupText, Input} from 'reactstrap';
import {estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloBtnNuevo,estiloBtnEditar,estiloColorError} from '../../Services/PaletaDeColores';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal'
import { getDetallesCotizacionToronja, postGuardarCotizacionToronjaTraslado, postGuardarCotizacionToronjaDetalles } from '../../Services/Api'
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from 'react-icons/fa';
import { validarNumerosDecimales2,validarLetrasNumerosLongitudV2 } from '../../Services/Validaciones';
import ModalAutorizarVenta from '../../Complements/Modales/ModalFormularioCotizacionesToronjaFletes/ModalAutorizarVenta';
import ModalFormularioCotizacionesToronjaFletes from '../../Complements/Modales/ModalFormularioCotizacionesToronjaFletes/ModalFormularioCotizacionesToronjaFletes';
import ModalConfirarAccion from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalConfirmarAccion';

export default class DetallesCotizacionToronja extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,
      regresarcatalogo:false,

      accionmodal: 1,
      titulomodal: '',
      itemeliminar: undefined,
      modalautorizacion:false,

      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      idcatalogo_cotizacion_toronja:0,
      informaciondetalles: {
        detalles: {
          pesoinicialtotal: 0.0,
          pesofinaltotal:0.0,
          kilogramos:0.0,
          preciokilogramos:0.0,
          gastos: 0.00,
          comision: 0.00,
          total: 0.00,
          factura:'',
        },
        fletes: []
      },
      tablafletes: {
        encabezados: [
          "Folio",
          "Rancho",
          "Lote",
          "Cultivo",
          "Ticket",
          "Peso inicial",
          "Peso final",
          "Peso neto",
          ""
        ],
        dimensiones: [
          "10%",
          "15%",
          "15%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "5%"
        ],
        mindimensiones: [
          "150px",
          "120px",
          "120px",
          "130px",
          "130px",
          "140px",
          "140px",
          "140px",
          "50px",
        ],
        maxdimensiones: [
          "150px",
          "150px",
          "150px",
          "150px",
          "150px",
          "140px",
          "140px",
          "140px",
          "50px",
        ]
      },
      rejastotales:0,
      pesototal:0.0,
      pesopromedio:0.0,
     
      totalrejascorridas:0,
      totalpesocorrido:0.0,
      pesomerma:0.0,
      rejasmerma:0,
      diferencia:0.0,
      rendimiento:0.0,
      totallimones:0.0,
      total:0.0,

      redireccionando: false,
      redireccionandobandera: "",
      modaltraslados: false,
      modaladdplancuadrilla: false,
      modalconfirmaraccion: false,
      itemseleccionado:undefined,
      idcatalogo:0,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      colormsgerror: estiloColorError().msgerror,

      vermsgerrorpreciokilogramos:false,
      vermsgerrorgastos:false,
      vermsgerrorfactura:false,

      msgerrorpreciokilogramos:'',
      msgerrorgastos:'',
      msgerrorfactura:''
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var catalogotoronjaaux = await localStorage.getItem('dato')
    var catalogotoronja = JSON.parse(catalogotoronjaaux)
    var idcatalogo_cotizacion_toronja = catalogotoronja.id
    if (idcatalogo_cotizacion_toronja != null) {
      var respcotizaciontoronja = await getDetallesCotizacionToronja({"idcatalogo_cotizacion_toronja": idcatalogo_cotizacion_toronja});
      if (this.manejadorLlamadasApis(respcotizaciontoronja)) {
        return;
      }

      if (respcotizaciontoronja.code == 200) {
        await toast.error(respcotizaciontoronja.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 3000);
        return;
      }

      var informaciondetalles = this.state.informaciondetalles
      informaciondetalles.detalles = respcotizaciontoronja.data ? respcotizaciontoronja.data : {};
      informaciondetalles.fletes = respcotizaciontoronja.data.traslados ? respcotizaciontoronja.data.traslados : {};

      this.calcularRejasPesoPromedio(informaciondetalles)

      this.setState({
        informaciondetalles: informaciondetalles,
        idcatalogo_cotizacion_toronja:idcatalogo_cotizacion_toronja
      });
    } else {
      this.redireccionando();
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
        if (!this.state.errordeacceso) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        this.setState({ loadingspinners: false, errordeacceso: true })
        return true
    }
    else if (resp.code == 400) {
        toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.setState({ loadingspinners: false })
        return true
    }
    else if (resp.code == 401) {
        this.setState({ salir: true })
        return true
    }
    if (resp.code == 1000) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.setState({ loadingspinners: false })
        return true
    }
    return false
  }

  escribir = (prop) => (event) => {
    var estado = this.state.informaciondetalles.detalles
    estado[prop] = event.target.value;

    var total_fruta = Number(estado.preciokilogramos) * Number(estado.kilogramos);
    var total = total_fruta - estado.gastos;

    estado.total = total;
    this.setState({
      estado
    });
  }


  calcularRejasPesoPromedio = (informaciondetalles) =>{
    var modificaciones = informaciondetalles;
    var peso_inicial_total = 0;
    var peso_final_total = 0;
    var peso_total = 0;

    modificaciones.fletes.map((i) => {
      peso_inicial_total += Number(i.pesoinicial);
      peso_final_total += Number(i.pesofinal);
      peso_total += (Number(i.pesoneto));
    });

    modificaciones.detalles.pesoinicialtotal = peso_inicial_total;
    modificaciones.detalles.pesofinaltotal = peso_final_total;
    modificaciones.detalles.kilogramos = peso_total;
    modificaciones.detalles.total = (Number(modificaciones.detalles.preciokilogramos) * peso_total) - Number(modificaciones.detalles.gastos);

    return modificaciones;
  }

  format_entero = (number) => {
    return new Intl.NumberFormat('en-US').format(Math.floor(number));
  }

  format_numero_decimales = (number) => {
    return new Intl.NumberFormat('en-US',{ minimumFractionDigits: 2 }).format(number);
  }

  format_numero = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  }

  format_moneda = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
  }

  format_kilogramos = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'unit', unit: 'kilogram', minimumFractionDigits:2 }).format(number);
  }

  format_porcentaje = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2 }).format(number);
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setLoadingSpinner = (band) => {
    this.setState({ loadingspinners: band })
  }

  verModalConfirmarVenta = async (accion) => {
    this.setState({ vermsgerrorpreciokilogramos: false });
    this.setState({ vermsgerrorgastos: false });
    this.setState({ vermsgerrorfactura: false });

    let validacionescorrectas = true
    let longitudvalida = undefined;
    let textvalid = undefined;

    let numeroinvalido = false;
    let longitudinvalida = false;
    let campovacio = false;

    longitudvalida = {longitudminima: 1, longitudmaxima: 12};

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.preciokilogramos.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorpreciokilogramos: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.gastos, longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorgastos: true })
      validacionescorrectas = false;
    }

    if(this.state.informaciondetalles.detalles.factura == null){
      campovacio = true;
      this.setState({ vermsgerrorfactura: true })
      validacionescorrectas = false;
    }
    else{
      var longitudinvalidafactura = false;
      var facturavalida = false;
      longitudvalida = {longitudminima: 1, longitudmaxima: 100};
      textvalid = validarLetrasNumerosLongitudV2(this.state.informaciondetalles.detalles.factura, longitudvalida);
      if (textvalid.codigo == "invalid") {
        if(textvalid.mensaje == "Este campo no puede superar más de 100 caracteres"){
          longitudinvalidafactura = true;
        }
        else if(textvalid.mensaje == "Solo se permiten letras y números"){
          facturavalida = true;
        }
        else if(textvalid.mensaje == "Este campo es obligatorio"){
          campovacio = true;
        }
        this.setState({ vermsgerrorfactura: true })
        validacionescorrectas = false;
      }
    }

    if(campovacio){
      toast.warning("No se permiten campos vacíos.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(numeroinvalido){
      toast.warning("Escribe un número válido.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(longitudinvalida){
      toast.warning("No se permiten datos mayores a 12 caracteres.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(longitudinvalidafactura){
      toast.warning("La factura no puede tener más de 100 caracteres.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(facturavalida){
      toast.warning("Sólo se permiten letras y números en factura.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    var resp = undefined;
    if (validacionescorrectas) {
      this.setLoadingSpinner(true)
      let json ={
        "idcatalogo_cotizacion_toronja": this.state.idcatalogo_cotizacion_toronja,
        "kilogramos": this.state.informaciondetalles.detalles.kilogramos,
        "preciokilogramos": this.state.informaciondetalles.detalles.preciokilogramos,
        "gastos":  this.state.informaciondetalles.detalles.gastos,
        "factura": this.state.informaciondetalles.detalles.factura,
      }
  
      resp = await postGuardarCotizacionToronjaDetalles(json);

      if(resp.code == 200){
        this.setLoadingSpinner(false)
        //Abre modal
        this.setState({
          modalautorizacion: !this.state.modalautorizacion,
          titulomodal:"Autorizar venta",
          accionmodal: accion
        })
      }
      else if (resp.code == 1000) {
        this.setLoadingSpinner(false);
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
      else if ( resp.code == 300) {
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else{
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error('Error de conexión.', { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
    }
  }

  cerrarModalAtutorizacion = (accion) => {
    this.setState({ modalautorizacion: !this.state.modalautorizacion })
    if (accion == 1) {
      toast.success("Cotización finalizada.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  verModal = (ban) => {
    if (ban.opcion == 1) { //Nuevo traslado
      this.setState({ titulomodal: 'Nuevo flete', accionmodal: 1, modaltraslados: true })
    }
    else if (ban.opcion == 2) { //confirmar accion eliminar traslado
      this.setState({ titulomodal: 'Confirmar acción', accionmodal: "flete", modalconfirmaraccion: true, itemeliminar: ban.item });
    }
  }

  cerrarModalFletes = (accion) => {
    this.setState({ modaltraslados: !this.state.modaltraslados })
    if(accion == 1){
      toast.success("Traslado agregado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  eliminarElemento = async (elemento) => {
    var resp = undefined;
    this.setState({ modalconfirmaraccion: !this.state.modalconfirmaraccion })
    if(elemento.accion==1){
      this.setState({ loadingspinners: true })
      if(elemento.plan=="flete"){
        let json = {
          "idcatalogo_cotizacion_toronja_traslados": elemento.item.idcatalogo_cotizacion_toronja_traslados ,
          "idcatalogo_cotizacion_toronja": this.state.idcatalogo_cotizacion_toronja,
          "idcatalogo_bitacora_traslados_detalles":elemento.item.idcatalogo_bitacora_traslados_detalles,
          "eliminar": 1,
        }
        var resp = await postGuardarCotizacionToronjaTraslado(json);

      }
      
      if(resp.code == 200){
        this.setState({ loadingspinners: false })
        toast.success("Se borró el elemento exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.componentDidMount();
      }
      else if(resp.code == 300){
        this.setState({ loadingspinners: false })
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 400){
        this.setState({ loadingspinners: false })
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if (resp.code == 1000) {
        this.setState({ loadingspinners: false })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
    }
  }

  guardardatos = async () => {
    this.setState({ vermsgerrorpreciokilogramos: false });
    this.setState({ vermsgerrorgastos: false });
    this.setState({ vermsgerrorfactura: false });

    let validacionescorrectas = true
    let longitudvalida = undefined;
    let textvalid = undefined;

    let numeroinvalido = false;
    let longitudinvalida = false;
    let campovacio = false;

    longitudvalida = {longitudminima: 1, longitudmaxima: 12};

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.preciokilogramos.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorpreciokilogramos: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.gastos, longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorgastos: true })
      validacionescorrectas = false;
    }

    if(this.state.informaciondetalles.detalles.factura == null){
      campovacio = true;
      this.setState({ vermsgerrorfactura: true })
      validacionescorrectas = false;
    }
    else{
      var longitudinvalidafactura = false;
      var facturavalida = false;
      longitudvalida = {longitudminima: 1, longitudmaxima: 100};
      textvalid = validarLetrasNumerosLongitudV2(this.state.informaciondetalles.detalles.factura, longitudvalida);
      if (textvalid.codigo == "invalid") {
        if(textvalid.mensaje == "Este campo no puede superar más de 100 caracteres"){
          longitudinvalidafactura = true;
        }
        else if(textvalid.mensaje == "Solo se permiten letras y números"){
          facturavalida = true;
        }
        else if(textvalid.mensaje == "Este campo es obligatorio"){
          campovacio = true;
        }
        this.setState({ vermsgerrorfactura: true })
        validacionescorrectas = false;
      }
    }

    if(campovacio){
      toast.warning("No se permiten campos vacíos.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(numeroinvalido){
      toast.warning("Escribe un número válido.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(longitudinvalida){
      toast.warning("No se permiten datos mayores a 12 caracteres.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(longitudinvalidafactura){
      toast.warning("La factura no puede tener más de 100 caracteres.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(facturavalida){
      toast.warning("Sólo se permiten letras y números en factura.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    var resp = undefined;
    if (validacionescorrectas) {
      this.setLoadingSpinner(true)
      let json ={
        "idcatalogo_cotizacion_toronja": this.state.idcatalogo_cotizacion_toronja,
        "kilogramos": this.state.informaciondetalles.detalles.kilogramos,
        "preciokilogramos": this.state.informaciondetalles.detalles.preciokilogramos,
        "gastos":  this.state.informaciondetalles.detalles.gastos,
        "factura": this.state.informaciondetalles.detalles.factura,
      }
  
      resp = await postGuardarCotizacionToronjaDetalles(json);

      if (resp.code == 1000) {
        this.setLoadingSpinner(false);
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
      else if ( resp.code == 300) {
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 200){
        this.setLoadingSpinner(false)
        toast.success("Datos guardados correctamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.componentDidMount();
      }
      else{
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
    }
  }

  regresar = () =>{
    this.setState({loadingspinners:true, regresarcatalogo:true})
  }


  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        {this.state.regresarcatalogo == true ?
          <AccionesVista bandera={'regresar'} />
        :null}
        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesVista bandera={'goreferencias'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalautorizacion == true) ?
          <ModalAutorizarVenta
            idcatalogo_cotizacion_toronja = {this.state.idcatalogo_cotizacion_toronja}
            show={this.state.modalautorizacion}
            titulomodal={this.state.titulomodal}
            cerrarModalAtutorizacion={this.cerrarModalAtutorizacion}
            setloadingspinner={this.setLoadingSpinner}
            accion={this.state.accionmodal}
          />
        :null}

        {(this.state.modaltraslados == true) ? (
          <ModalFormularioCotizacionesToronjaFletes
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalFletes}
            show={this.state.modaltraslados}
            accion={this.state.accionmodal}
            fecha={this.state.informaciondetalles.detalles.fecha}
            idcatalogo_cotizacion_toronja={this.state.idcatalogo_cotizacion_toronja}
            setLoadingSpinner={this.setLoadingSpinner}/>
        ) : null}

        {(this.state.modalconfirmaraccion == true) ? (
          <ModalConfirarAccion 
            titulomodal={this.state.titulomodal} 
            cerrarModalConfirmar={this.eliminarElemento}
            show={this.state.modalconfirmaraccion} 
            cuerpo={this.state.accionmodal}
            item={this.state.itemeliminar}
            />
        ) : null} 

        <Navbar />

        {/* ////////////////////   Inicio Sección datos informativos   ////////////////////////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezadoAutoCot'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Detalles de cotización de toronja</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetalles.detalles.fecha}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Cliente: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetalles.detalles.cliete}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetalles.detalles.estatus}</label>
              </div>
              {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                  <button
                    className='lbljoract letraTitulos'
                    style={{ color: this.state.colorbtnnuevo }}
                    onClick={this.verModalConfirmarVenta.bind(this, { opcion: "1" })}
                  >
                      Vender
                  </button>
                </div>
              :null}

            </div>
            <br />
            <br />

          </div>
        </div>

        <br />
        <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>

          {/* ////////////////////   Tabla traslados  ////////////////////////////////////// */}
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1240px', maxWidth: '1240px' }}>
                <div className='row align-items-end' style={{ paddingBottom: '20px'}}>
                  <div className='col-10 minPaddingTopBottomAutoCot divMinWidthAutoCot'>
                    <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Fletes</b></label>
                  </div>

                  {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                    <div className='col-2 paddingBottomEncabezados  ' >
                      <button className='btnNuevo letraNormal'
                        style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                        onClick={this.verModal.bind(this, { opcion: "1" })}>+ Agregar</button>
                    </div>
                  :null}
                </div>
                <div className='divMinWidthAutoCot'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                      <tr>
                        {this.state.tablafletes.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablafletes.mindimensiones[index],
                              maxWidth: this.state.tablafletes.maxdimensiones[index],
                              width: this.state.tablafletes.dimensiones[index],
                              color: this.state.colorencabezadostabla
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.informaciondetalles.fletes.map((item, index) => (
                        <tr key={"tabla" + index}
                          style={{
                            borderBottom: (index == this.state.informaciondetalles.fletes.length - 1) ? 'solid 1px #91b9f9' : ''
                          }}>
                         
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.folio}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.rancho}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.lote}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.cultivo}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.ticket}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                            {this.format_kilogramos(item.pesoinicial)}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                            {this.format_kilogramos(item.pesofinal)}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                            {this.format_kilogramos(item.pesoneto)}
                          </td>
                          <td key={"col-"+index} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                              <div className="modalDetallesCheck" >
                                <button className='btnEliminarFila' onClick={this.verModal.bind(this, { opcion: "2", item })}><FaTrashAlt className="icoBotones" /></button>
                              </div>
                            :null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                 
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* ////////////////////   Fin Tabla traslados ////////////////////////////////////// */}

          {/* ////////////////////   Sección de corrida  ////////////////////////////////////// */}
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1240px', maxWidth: '1240px' }}>

                <div className='row align-items-end' style={{ paddingBottom: '20px'}}>
                  <div className='col-12 minPaddingTopBottomAutoCot divMinWidthAutoCot'>
                    <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Corrida</b></label>
                  </div>
                </div>
                <div className='divMinWidthAutoCot'>
                  <Table>
                    <tbody className='tablaCorrida' style={{ verticalAlign: "bottom" }}>
                      <tr>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Peso inicial total
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Peso final total
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Peso neto total
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Factura
                        </td>
                      </tr>
                      <tr>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.format_kilogramos(this.state.informaciondetalles.detalles.pesoinicialtotal)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.format_kilogramos(this.state.informaciondetalles.detalles.pesofinaltotal)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.format_kilogramos(this.state.informaciondetalles.detalles.kilogramos)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <input
                              className={this.state.vermsgerrorfactura ? 'txtInputNumeroConError': 'txtInputNumero'}
                              type="text"
                              value={this.state.informaciondetalles.detalles.factura}
                              onChange={this.escribir("factura")}>
                            </input>
                          :
                            <>
                              {this.state.informaciondetalles.detalles.factura}
                            </>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Precio unidad
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Comisión
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Total
                        </td>
                      </tr>
                      <tr>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <InputGroupText className={this.state.vermsgerrorpreciokilogramos ? 'txtSimboloPrecioConError': 'txtInputSimbolo'}>
                                $
                              </InputGroupText>
                              <input
                                className={this.state.vermsgerrorpreciokilogramos ? 'txtInputPrecioConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.preciokilogramos}
                                onChange={this.escribir("preciokilogramos")}>
                              </input>
                            </InputGroup>
                          :
                            <>
                              {this.format_moneda(this.state.informaciondetalles.detalles.preciokilogramos)}
                            </>
                          }
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <InputGroupText className={this.state.vermsgerrorgastos ? 'txtSimboloPrecioConError': 'txtInputSimbolo'}>
                                $
                              </InputGroupText>
                              <input
                                className={this.state.vermsgerrorgastos ? 'txtInputPrecioConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.gastos}
                                onChange={this.escribir("gastos")}>
                              </input>
                            </InputGroup>
                          :
                            <>
                              {this.format_moneda(this.state.informaciondetalles.detalles.gastos)}
                            </>
                          }
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.format_moneda(this.state.informaciondetalles.detalles.total)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* ////////////////////   Fin sección de corrida ////////////////////////////////////// */}
          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
            <>
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
              </div>
              <br />
              <div className="divBtnContenedor">
                <Button className="btnCancelModal" onClick={() => this.regresar()}>Cancelar</Button>
                <Button className="btnAceptarModal" onClick={() => this.guardardatos()} disabled={!this.state.confirmarenvio}>Guardar</Button>
              </div>
            </>
          :null}
        </div>
        <br />
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoordencosecha"} />)
  }else if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }else if (bandera == 'regresar') {
    return (<Redirect from='/' to={"/catalogocotizaciontoronja"} />)
  }
}
