import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare, BsTelephoneFill } from 'react-icons/bs';
import { RiRefreshLine } from 'react-icons/ri';
import "./ModalAsignarUsuarioSeguridad.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios, validateEmail, validarTelefono2, validarCodigoPostal, validarLetrasConEspacios } from '../../../Services/Validaciones';
import { Asignarusuario, AsignarUsuarioSeguridad, getListaCatalogoUsuariosUpdate, setGenerarNombreUsuario, setVerificarNombreUsuario } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from "react-tooltip";
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { ladapaises } from '../ModalFormularioVentanaProveedor/Util';


//agregar todos los ranchoss desde cero 

export default class ModalAsignarUsuario extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            elegirranchoPrincipal: false,
            personalseguridad:false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            txtbuscador: "",
            listausuarios: props.listausuarios,
            idusuario: '-1',

            area: '',
            puesto: '',
            zona: '',
            rancho: '',

            vermsgerrorarea: false,
            vermsgerrorpuesto: false,
            vermsgerrortipousuario: false,

            msgerrorarea: "",
            msgerrorpuesto: "",
            txtselectedrancho: '0',
            msgerrortipousuario: "",
            areaseleccionado: "TodasLasAreas",
            puestoseleccionado: "TodosLosPuestos",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            filtrolistapaginada: [],
            listafiltropuesto: [],
            filtrolistapuesto: [],
            listapuesto: this.props.listapuesto,
            listaarea: this.props.listaarea,


            listatipousuario: props.listatipousuario != undefined ? props.listatipousuario : []
        }

    }
    async componentDidMount() {
        this.setState({ listafiltropuesto: this.props.listapuesto })

        if (this.props.accion == 2) {

            var resplistapuestos = this.state.listapuesto.filter((item) => item.idcatalogo_areas == this.state.area)
            await this.setState({
                filtrolistapuesto: resplistapuestos,
            })

        }

    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    seleccionarUser = (index) => {

        var array = this.state.listausuarios;
        for (let i = 0; i < array.length; i++) {
            array[i].activo = false;
        }
        array[index].activo = true;
        this.setState({ idusuario: array[index].idcatalogousuarios })
        this.setState({ listausuarios: array })

    }

    seleccionaritemtabla = (index) => {

        var array = this.state.filtrolistapaginada;
        for (let i = 0; i < array.length; i++) {
            array[i].activo = false;
        }
        array[index].activo = true;
        this.setState({ idusuario: array[index].idcatalogousuarios })
        this.setState({ filtrolistapaginada: array })

    }



    filtrarArea = async (itemfiltrado, accion) => {
        var data1 = JSON.parse(JSON.stringify(this.state.listausuarios))
        var data = data1;

        await this.setState({
            areaseleccionado: itemfiltrado
        })

        var filtroarea = [];
        var filtropuesto = [];
        var filtrofinal = [];

        filtroarea = data;
        if (itemfiltrado != "TodasLasAreas") {

            var resplistapuestos = this.state.listapuesto.filter((item) => item.idcatalogo_areas == itemfiltrado)

            await this.setState({
                listafiltropuesto: resplistapuestos,
                txtbuscador: ""
            })

            if (accion == 0) {
                await this.setState({
                    puestoseleccionado: "TodosLosPuestos"
                })
            }

            filtroarea = data.filter((item) => item.idarea == itemfiltrado)
        } else {
            await this.setState({
                listafiltropuesto: this.state.listapuesto,
                txtbuscador: ""
            })

            if (accion == 0) {
                await this.setState({
                    puestoseleccionado: "TodosLosPuestos"
                })
            }
        }

        if (this.state.puestoseleccionado != "TodosLosPuestos") {

            filtropuesto = filtroarea.filter((item) => item.idpuesto == this.state.puestoseleccionado)
        } else {
            filtropuesto = filtroarea;
        }

        if (this.state.txtbuscador.length >= 3) {
            filtrofinal = filtropuesto.filter((item) => item.nombres.toLocaleLowerCase().includes(this.state.txtbuscador)
                || item.apellidos.toLocaleLowerCase().includes(this.state.txtbuscador)
                || item.usuario.toLocaleLowerCase().includes(this.state.txtbuscador))
        } else {
            filtrofinal = filtropuesto;
            await this.setState({
                txtbuscador: ""
            })
        }

        if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

        this.setState({ filtrolistapaginada: filtrofinal })



    }

    filtrarPuesto = async (itemfiltrado) => {
        var data1 = JSON.parse(JSON.stringify(this.state.listausuarios))
        var data = data1;


        await this.setState({
            puestoseleccionado: itemfiltrado
        })

        var filtroarea = [];
        var filtropuesto = [];
        var filtrofinal = [];
        filtroarea = data;
        if (this.state.areaseleccionado != "TodasLasAreas") {

            filtroarea = data.filter((item) => item.idarea == this.state.areaseleccionado)
        }

        filtropuesto = filtroarea;
        if (itemfiltrado != "TodosLosPuestos") {

            filtropuesto = filtroarea.filter((item) => item.idpuesto == itemfiltrado)
        }

        if (this.state.txtbuscador.length >= 3) {
            filtrofinal = filtropuesto.filter((item) => item.nombres.toLocaleLowerCase().includes(this.state.txtbuscador)
                || item.apellidos.toLocaleLowerCase().includes(this.state.txtbuscador)
                || item.usuario.toLocaleLowerCase().includes(this.state.txtbuscador))
        } else {
            filtrofinal = filtropuesto;
            await this.setState({
                txtbuscador: ""
            })
        }

        if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        // this.filtro(1, filtrofinal)
        this.setState({ filtrolistapaginada: filtrofinal })

    }

    setLoadingSpinner = (bandera) => {
        this.props.activarSpinner(bandera);
    }

    guardarCambios = async () => {

        if (this.state.idusuario == -1 || this.state.idusuario == "-1") {
            toast.info("Selecciona un usuario", { autoClose: 3000, position: toast.POSITION.TOP_LEFT });
            return
        }

        this.setState({ loadingspinners: true })

        var json = {
            "idusuario": this.state.idusuario
        }
        
        var resp = await AsignarUsuarioSeguridad(json)

        if(resp.code == 0){
            if (this.state.accion == 1) {
                this.showModal('1')
            }
            else if (this.state.accion == 2) {
                this.showModal('2')
            }
        }
        else if(resp.code == 200){
            this.setLoadingSpinner(false);
            this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners: false})
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        else if(resp.code == 300){
            this.setLoadingSpinner(false);
            this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners: false})
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        else if(resp.code == 400){
            this.setLoadingSpinner(false);
            this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners: false})
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        else if (resp.code == 1000) {
            this.setLoadingSpinner(false);
            this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners: false})
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            return;
        }else{
            this.setLoadingSpinner(false);
            this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners: false})
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

    }





    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >Asignar usuario de seguridad</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <div className='row'>
                                    {/* ////////////////////   Inicio Sección selector área   ////////////////////////////////////// */}
                                    <div className='col-6'>
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Área</p>

                                        <div className='col lblSubTitulosModal '>
                                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                <div className="selectBox ">
                                                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                        onChange={(event) => this.filtrarArea(event.target.value, 0)}>
                                                        {this.state.areaseleccionado == "TodasLasAreas" ?
                                                            <option selected="true" value='TodasLasAreas'>Todas</option> : <option value='TodasLasAreas'>Todas</option>}
                                                        {this.state.listaarea.map((i, index) => (
                                                            <option value={i.idcatalogo_areas} key={"tipo_" + index}  >{i.area} </option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </div>
                                        </div>

                                        {this.state.vermsgerrorarea ?
                                            <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorarea} </p>
                                        :null}

                                    </div>

                                    {/* ////////////////////   Inicio Sección selector puesto   ////////////////////////////////////// */}
                                    <div className='col-6'>
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Puesto</p>

                                        <div className='col lblSubTitulosModal '>
                                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                <div className="selectBox ">
                                                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                        onChange={(event) => this.filtrarPuesto(event.target.value)}>
                                                        {this.state.puestoseleccionado == "TodosLosPuestos" ?
                                                            <option selected="true" value='TodosLosPuestos'>Todos</option> : <option value='TodosLosPuestos'>Todos</option>}
                                                        {this.state.listafiltropuesto.map((i, index) => (
                                                            <option value={i.idcatalogo_puestos} key={"tipo_" + index}  >{i.puesto} </option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </div>
                                        </div>

                                        {this.state.vermsgerrorpuesto ?
                                            <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpuesto}</p>
                                        :null}

                                    </div>
                                </div>

                                {this.state.txtbuscador.length < 3
                                    && this.state.puestoseleccionado == 'TodosLosPuestos'
                                    && this.state.areaseleccionado == 'TodasLasAreas'
                                    ? <ul class="list-group">

                                        {this.state.listausuarios.map((item, index) => (
                                            <li key={index} class="list-group-item" style={{ backgroundColor: item.activo == true ? "#f7f5f2" : "white" }} onClick={this.seleccionarUser.bind(this, index)}>{item.nombres} {item.apellidos}</li>
                                        ))}

                                    </ul>

                                    :

                                    <ul class="list-group">

                                        {this.state.filtrolistapaginada.map((item, index) => (
                                            <li key={index} class="list-group-item" style={{ backgroundColor: item.activo == true ? "#f7f5f2" : "white" }} onClick={this.seleccionaritemtabla.bind(this, index)}>{item.nombres} {item.apellidos}</li>
                                        ))}

                                    </ul>
                                }
                            </div>

                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

