import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioOrdenCosecha.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import {  validarLetrasNumerosPuntoComa,  validarLetrasNumerosPuntoComaOpcional,  validarNumerosDecimales2 } from '../../../Services/Validaciones';
import { guardarEditarOrdenCosecha } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

import { FaCalendar } from "react-icons/fa";
import DatePicker from "react-datepicker";

export default class ModalFormularioOrdenCosecha extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loadingspinners: false,
            confirmarenvio: false,

            show: props.show,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            listazonas: props.listazonas,
            listaranchos: props.listaranchos,
            listalotes:props.listalotes,

            listaranchosfiltrados:[],
            listalotesfiltrados:[],
            loteseleccionado:null,
            tipocorte:(props.accion == 2) ? (props.item.tipocorte) : '',

            selectrancho:true,
            selectlote:true,

            txtzona: (props.accion == 2) ? (props.item.idcatalogo_zona) : '0',
            txtrancho: (props.accion == 2) ? (props.item.idcatalogo_rancho) : '0',
            txtlote: (props.accion == 2) ? (props.item.idcatalogo_rancho) : '0',
            txtfechacosecha: (props.accion == 2) ? (props.item.fechacosecha) : '',
            fechacosecha: (props.accion == 2) ? (this.convertirFechaCompleta(props.item.fechacosecha)) : '',
            txtestimado: (props.accion == 2) ? (props.item.estimado) : '',
            txtcomentario: (props.accion == 2) ? (props.item.comentarios) : '',

            arrayestatus: props.estatus,
            idestatus: 1,

            vermsgerrorfechacosecha: false,
            vermsgerrorzonas: false,
            vermsgerrorranchos: false,
            vermsgerrorestimado:false,
            vermsgerrorcomentario:false,
            vermsgerrorlote:false,

            msgerror: '',
            msgerrorzona: "",
            msgerrorrancho: "",
            msgerrorfechacosecha: "",
            msgerrorestimado:"",
            msgerrorcomentario:"",
            msgerrorlote:"",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
        }

    }

    async componentDidMount() {
        this.cambiarValor((this.props.accion == 2) ? (this.props.item.idestatus) : this.state.idestatus)
    }

    convertirFechaCompleta = (fecha) => {
        const fechagen = new Date();
        var [year, month, day] = fecha.split("-");
        var fechaseteada=fechagen.setFullYear(year, month -1 , day);

        return fechaseteada;
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
        if (prop == "txtzona") {
            this.filtroRanchosPorZona(event.target.value)
        }
        if (prop == "txtrancho") {
            this.filtroLotesPorRancho(event.target.value)
        }
        if(prop == "txtlote"){
            this.loteSeleccionado(event.target.value)
        }
    };

    filtroRanchosPorZona = (idzona) => {
        const ranchosFiltrados = this.state.listaranchos.filter(rancho => rancho.idcatalogo_zona == idzona);
        this.setState({listaranchosfiltrados:ranchosFiltrados})
        if(ranchosFiltrados.length == 0){
            this.filtroLotesPorRancho(0)
            this.setState({selectrancho:false})
            this.setState({ txtrancho: 0 }, () => {
                this.setState({ selectrancho: true });
            });
        }else{
            if(this.state.txtrancho != 0){
                this.filtroLotesPorRancho(ranchosFiltrados[0].idcatalogo_rancho)
                this.setState({txtrancho: ranchosFiltrados[0].idcatalogo_rancho})
            }
        }
    }

    filtroLotesPorRancho = (idrancho) => {
        const lotesFiltrados = this.state.listalotes.filter(lote => lote.idcatalogo_rancho == idrancho);
        this.setState({listalotesfiltrados:lotesFiltrados})
        if(lotesFiltrados.length == 0){
            this.setState({ selectlote: false, tipocorte:'' });
            this.setState({ txtlote: 0 }, () => {
                this.setState({ selectlote: true });
            });
        }else{
            if(this.state.txtlote != 0){
                this.loteSeleccionado(lotesFiltrados[0].idcatalogo_ranchos_lotes_cultivo)
                this.setState({txtlote:lotesFiltrados[0].idcatalogo_ranchos_lotes_cultivo})
            }
        }
    }

    loteSeleccionado = (idlote) => { 
        const loteSeleccionado = this.state.listalotes.filter(lote => lote.idcatalogo_ranchos_lotes_cultivo == idlote);
        this.setState({loteseleccionado: loteSeleccionado[0], tipocorte: loteSeleccionado[0].tipocorte})
    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    }

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrorfechacosecha: false })
        this.setState({ vermsgerrorranchos: false })
        this.setState({ vermsgerrorzonas: false })
        this.setState({ vermsgerrorestimado: false })
        this.setState({ vermsgerrorcomentario: false })
        this.setState({ vermsgerrorlote: false })

        let validacionescorrectas = true
        let longitudvalida = ""
        let tipocorrecto = ""

        if (this.state.txtzona < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorzona: "Selecciona una zona" })
            this.setState({ vermsgerrorzonas: true })
        }

        if (this.state.txtrancho < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorrancho: "Seleccione un rancho" })
            this.setState({ vermsgerrorranchos: true })
        }

        if (this.state.txtlote < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorlote: "Seleccione un lote" })
            this.setState({ vermsgerrorlote: true })
        }

        if (this.state.txtfechacosecha == "" || this.state.txtfechacosecha == null) {
            validacionescorrectas = false;
            this.setState({ msgerrorfechacosecha: "Selecciona una fecha" })
            this.setState({ vermsgerrorfechacosecha: true })
        }

        longitudvalida = { longitudminima: 1, longitudmaxima: 12 };
        tipocorrecto = validarNumerosDecimales2(this.state.txtestimado.trim(), longitudvalida)
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrorestimado: tipocorrecto.mensaje })
            this.setState({ vermsgerrorestimado: true })
            validacionescorrectas = false;
        }

        longitudvalida = { longitudminima: 0, longitudmaxima: 50 };
        tipocorrecto = validarLetrasNumerosPuntoComaOpcional(this.state.txtcomentario, longitudvalida)
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrorcomentario: tipocorrecto.mensaje + 'coment' })
            this.setState({ vermsgerrorcomentario: true })
            validacionescorrectas = false;
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);
            var resp = undefined;

            if (this.state.accion == 1) {
                let json = {
                    "idcatalogo_ordenes_cosecha":0,
                    "idcatalogo_rancho": this.state.txtrancho,
                    "idcatalogo_ranchos_lotes_cultivo": this.state.txtlote,
                    "fechacosecha": this.state.txtfechacosecha,
                    "estimado":this.state.txtestimado,
                    "comentarios":this.state.txtcomentario,
                }

                resp = await guardarEditarOrdenCosecha(json);
            } else if (this.state.accion == 2) {
                let json = {
                    "idcatalogo_ordenes_cosecha": this.props.item.idcatalogo_ordenes_cosecha,
                    "idcatalogo_ranchos_lotes_cultivo": this.state.txtlote,
                    "fechacosecha": this.state.txtfechacosecha,
                    "estimado":this.state.txtestimado,
                    "comentarios":this.state.txtcomentario,
                }

                resp = await guardarEditarOrdenCosecha(json);
            }

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }
    }

    render() {
        return (
            <>
                {/*///////// Sección de spinner ////////*/}
                <ToastContainer />
                {(this.state.loadingspinners == true) ?
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                :null}

                {/*///////// Sección del modal ////////*/}
                <Modal
                    isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/*///////// Sección de zona /////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona</p>
                                {this.state.accion == 2 ?
                                    <>
                                        <div className='col lblSubTitulosModal '>
                                            {this.props.item.zona}
                                        </div>

                                        {/*
                                            <div className='col lblSubTitulosModal '>
                                                <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                    <div className="selectBox ">
                                                        <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtzona")}>
                                                        <option disabled="disabled">Seleccionar</option>
                                                            {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((i, index) => (
                                                                <option value={i.idcatalogo_zona} key={"tipo_" + i.idcatalogo_zona} selected={i.idcatalogo_zona==this.state.txtzona ? true : false} >{i.zona} </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        */}
                                    </>
                                :
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtzona")}>
                                                    <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((i, index) => (
                                                        <option value={i.idcatalogo_zona} key={"tipo_" + i.idcatalogo_zona}>{i.zona}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.state.vermsgerrorzonas ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorzona}</p> 
                                :null}

                                {/*///////// Sección de rancho /////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho</p>
                                {this.state.accion == 2 ?
                                    <>
                                        <div className='col lblSubTitulosModal '>
                                            {this.props.item.rancho}
                                        </div>
                                        {/*
                                            <div className='col lblSubTitulosModal '>
                                                <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                    <div className="selectBox ">
                                                        <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtrancho")}>
                                                            <option disabled="disabled" selected={this.state.txtrancho==0 ? true : false}>Seleccionar</option>
                                                            {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((i, index) => (
                                                                this.state.txtzona == i.idcatalogo_zona ?
                                                                <option value={i.idcatalogo_rancho} key={"tipo_" + i.idcatalogo_rancho} selected={i.idcatalogo_rancho==this.state.txtrancho ? true : false} >{i.rancho} </option> : null
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        */}
                                    </>
                                :
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtrancho")}>
                                                    <option disabled={this.state.selectrancho} value={0} selected="true">Seleccionar</option>
                                                    {this.state.listaranchosfiltrados == undefined || this.state.listaranchosfiltrados.length == 0 ?
                                                        <option disabled={true}>No hay ranchos</option>
                                                    :
                                                        this.state.listaranchosfiltrados.map((i, index) => (
                                                            <option value={i.idcatalogo_rancho} key={"tipo_" + i.idcatalogo_rancho}>{i.rancho}</option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.state.vermsgerrorranchos ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorrancho}</p> 
                                :null}

                                {/*///////// Sección de lote /////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Lote </p>
                                {this.state.accion == 2 ?
                                    <>
                                        <div className='col lblSubTitulosModal '>
                                            {this.props.item.lote}
                                        </div>

                                    </>
                                :
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtlote")}>
                                                    <option disabled={this.state.selectlote} value={0} selected="true">Seleccionar</option>
                                                    {this.state.listalotesfiltrados == undefined || this.state.listalotesfiltrados.length == 0 ?
                                                        <option disabled={true}>No hay lotes</option>
                                                    :
                                                        this.state.listalotesfiltrados.map((i, index) => (
                                                            <option value={i.idcatalogo_ranchos_lotes_cultivo} key={"tipo_" + i.idcatalogo_ranchos_lotes_cultivo} >{i.lote}</option> 
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.state.vermsgerrorlote ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorlote}</p>
                                :null}

                                {/*///////// Sección de tipo corte /////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo corte</p>

                                {/*
                                    this.state.txtlote !== 0 ?
                                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtcultivo} readOnly></input>
                                    :
                                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} placeholder="Elige un lote" readOnly></input>
                                */}
                                {this.state.tipocorte == '' ?
                                    <>
                                        <input
                                            className='lblTextoModal letraNormal'
                                            style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                            type={'text'}
                                            placeholder="Elige un lote"
                                            value={this.state.tipocorte}
                                            readOnly>
                                        </input>
                                    </>
                                :
                                    <>
                                        <input
                                            className='lblTextoModal letraNormal'
                                            style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                            type={'text'}
                                            value={this.state.tipocorte}
                                            readOnly>
                                        </input>
                                    </>
                                }
                                
                                {/*///////// Sección de estimado /////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estimado</p>
                                {this.state.accion == 2 || this.state.accion == 1 ?
                                    <input
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'number'}
                                        placeholder={this.state.tipocorte == 'Tonelada' ? 'Estimación en tonelaje': this.state.tipocorte == 'Reja' ? 'Estimación en cantidad de rejas': 'Elige un lote'}
                                        value={this.state.txtestimado}
                                        onChange={this.escribir("txtestimado")}>
                                    </input>
                                :
                                    <label
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    >
                                        {this.state.txtestimado}
                                    </label>
                                }

                                {this.state.vermsgerrorestimado ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorestimado}</p> 
                                :null}

                                {/*///////// Sección de comentario /////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Comentario</p>
                                {this.state.accion == 2 || this.state.accion == 1 ?
                                    <input
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'}
                                        value={this.state.txtcomentario}
                                        onChange={this.escribir("txtcomentario")}>
                                    </input>
                                :
                                    <label
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                                        {this.state.txtcomentario}
                                    </label>
                                }

                                {this.state.vermsgerrorcomentario ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcomentario}</p> 
                                :null}

                                {/*///////// Sección de fecha de cosecha /////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha de cosecha</p>
                                {this.state.accion == 2 || this.state.accion == 1 ?
                                    <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                        <DatePicker
                                            selected={this.state.fechacosecha}
                                            onChange={(date) => this.cambiarFecha({ llave: "fechacosecha", valor: date })}
                                            //dateFormat="dd/MM/yyyy"
                                            dateFormat="yyyy-MM-dd"
                                            locale="es"
                                            customInput={
                                                <DateButton />
                                            }
                                        />
                                    </div>
                                :
                                    <label
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                                        {this.state.txtfechacosecha}
                                    </label>
                                }

                                {this.state.vermsgerrorfechacosecha ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfechacosecha}</p> 
                                :null}
                            </div>
                        </div>

                        {/*///////// Sección de botones /////////*/}
                        {this.state.accion == 2 || this.state.accion == 1 ?
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        :null}
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

{/*///////// Botón de calendario /////////*/}
const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686', width: '96%', margin: '0px 8px 18px 8px'
        }}
    >
        <label style={{ width: '90%', textAlign: 'left', cursor: 'pointer' }}>{value ? value : 'dd/mm/aaaa'}</label>
        <FaCalendar className="icoBotones" style={{ minWidth: '28px' }} />
    </button>
));
