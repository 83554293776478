import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoNuevaRequisicion.css';
import './EditarRequisicion.css';
import { FaTrashAlt } from 'react-icons/fa';
import TablaProductos from './TablaProductos';
import {Table,TableBody,TableCell,TableContainer,Paper,TableHead,TableRow,Collapse,Box,Typography } from '@material-ui/core';
import { estiloBtnActualizar, estiloBtnEditar, estilosModal, estiloFiltroEncabezados } from '../../Services/PaletaDeColores';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';


export default class TablaAbastecimientoStock extends React.Component {
  constructor(props) {
    super(props);
console.log("tabla abastecimiento stock", props)
    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso:false,
      accion: props.accion, // 1 Nuevo, 2 editar
      opcioneditar: props.accion == 1 ? false : true,

      tabla: props.itemtabla,
      listaproductosgregados: [],
  
      colapsartabla:true,
      eliminartabla:false,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,

      btnbordereditar: estiloBtnEditar().btnbordereditar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }
  
  actualizarContenido = (listaproductos) => {
    this.setState({ 
     listaproductosgregados: listaproductos
    })

    var auxtabla = this.state.tabla;
    auxtabla.productos = listaproductos;

    this.props.actualizarContenidoStock(listaproductos);
  }

  render() {
    return (

      <>
       
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

            <div className="">
              <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                        <div className='row flexNoWrap'>
                          <div className='col-auto minPaddingTopBottom'>
                            <button className='btnCollapsar letraNormal'
                              style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                              onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                          </div>
                          <div className='col-auto minPaddingTopBottom d-flex align-items-center'>
                          <p className='sinMargen'><b>Abastecimiento Stock</b></p>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={this.state.colapsartabla} timeout="auto" >
                          <Box margin={1}>
                            <TablaProductos style={{ backgroundColor: "#f7f5f2" }}
                              key={"tabla_" + 1} accion={this.state.accion} 
                              productos={this.props.itemtabla.productos}
                              actualizarContenido={this.actualizarContenido}
                              listaprodresumen={this.props.listaprodresumen}
                            />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}

      </>
    )
  }
}