import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoNuevaRequisicion.css';
import { Button} from 'reactstrap';

import { Table } from 'reactstrap';
import {  estiloComboBox, estiloFiltroEncabezados } from '../../Services/PaletaDeColores';

import Zona from './Zona';
import TablaAbastecimientoStock from './TablaAbastecimientoStock';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {  getInformacionRequesicionRectificar, guardarRectificacion} from '../../Services/Api';

import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import ModalPedirPassword from '../../Complements/Modales/ModalRectificacion/ModalPedirPassword';


export default class RectificarProductos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso:false,
      redireccionandoarequisicion:false,
      
      opcioneditar: false,
      estatus: [],
    
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      
    
      zonas: [],
      inforequisicion:{},

      infoDummy: undefined,

      requestbodyrequisicion:{},
      tablasrequestbody: [],
      stockrequestbody: {
        idstock:"0",
        productos:[
        ]
      },

      stock: {
        productos:[
        ]
      },
      stockactivo: false,
     
      confirmarenvio: false,

      tablaresumen: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          /*"Proveedor",
          ""*/
        ],
        dimensiones: [
          "30%",
          "20%",
          "15%",
          "30%",
          "5%",
        ],
        mindimensiones: [
          "130px",
          "170px",
          "120px",
          "120px",
          "30px",
        ],
        elementos: [
        ],
        total:""
      },

      tablaresumenProductos: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "",
          ""],
        dimensiones: [
          "20%",
          "20%",
          "15%",
          "20%",
          "35%"
        ],
        mindimensiones: [
          "120px",
          "170px",
          "100px",
          "150px",
          "190px"
        ],
        maxdimensiones: [
          "120px",
          "160px",
          "100px",
          "150px",
          "200px"
        ],
        elementos: [],
        total: 0.00,
      },

      productoresumenseleccionado: undefined,
      modalguardar: false,
      modaldestino: false,
      modalasignarproveedor: false,
      modalpedirpassword: false,
      banderaref: "",

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
    

      //lista catálogos
      listazonas: [],
      listaranchos: [],
      listalotes: [],
      listatablas: [],
      listaproductos: [],
      productosresumeninfo:[],
      productosresumenprincipal:[],
      listaproductossustitutos: [],
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    let stockrequestbody_aux = {idstock:"0",productos:[]}
    this.state.tablaresumenProductos.elementos = [];

    await this.setState({ 
      loadingspinners: true, 
      opcioneditar: true,
      listaproductossustitutos: [], 
      tablasrequestbody: [], 
      stock: {productos:[]},
      zonas: [],
      stockrequestbody: stockrequestbody_aux, 
      tablaresumenProductos: this.state.tablaresumenProductos, 
      haysustitutos: false
    })


    var idcotizacion = localStorage.getItem ("dato")
    var inforequisicion = await getInformacionRequesicionRectificar(idcotizacion);

    //console.log("datos rectificacion", inforequisicion)
      
    if ( this.manejadorLlamadasApis (inforequisicion) ) {
      return;
    }

    if(inforequisicion.data!=undefined){

      setTimeout(() => {
      }, 300);

      var procesadoinfo = JSON.parse(JSON.stringify(this.iniciarSustitutos(inforequisicion.data)));
            
      var backupresumen = this.state.tablaresumenProductos;
      backupresumen.elementos = procesadoinfo.procesados.resumencotizacion;

      var stockactivoaux = false;
      let existproduct = procesadoinfo.procesados.stock.hasOwnProperty("productos");
      var stockrequestbodyaux = this.state.stockrequestbody;
      if(existproduct){
        stockrequestbodyaux.idstock = procesadoinfo.procesados.stock.idstock;
        stockactivoaux = true;
      }

      await this.setState({ 
        inforequisicion: procesadoinfo.procesados,
        zonas: procesadoinfo.procesados.zonas,
        stock: procesadoinfo.procesados.stock,
        stockactivo: stockactivoaux,
        productosresumeninfo: procesadoinfo.procesados.resumenrequisicion.productos,
        productosresumenprincipal: procesadoinfo.procesados.resumencotizacion,
        infoDummy: procesadoinfo.procesados,
        tablaresumenProductos: backupresumen,
        listaproductossustitutos: procesadoinfo.sustitutos
      })
    }


    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }else if (resp.code== 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }else if(resp.code == 300){
      if(!this.state.errordeacceso){
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
        
      this.setState({ 
        loadingspinners: false,
        errordeacceso:true })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  calcularResumen = async (listazonasagregadas) => {
//console.log("calcular resumen", listazonasagregadas)
    // se actualizan las tablas y resumen
    var listazonas = listazonasagregadas;
    var listaproductosresumen = [];
    var tresumen = this.state.tablaresumen;
    var auxtresumenPrincipal = this.state.infoDummy.resumencotizacion;
    var listatablasrequestbody = [];

    for (let index = 0; index < listazonas.length; index++) {
      var listaranchos = listazonas[index].ranchos;
      
      for (let index = 0; index < listaranchos.length; index++) {
        var listalotes = listaranchos[index].lotes;

        for (let index = 0; index < listalotes.length; index++) {
          var listatablas = listalotes[index].tablas;

          for (let index = 0; index < listatablas.length; index++) {
            var listaproductos = listatablas[index].productos;

            var tabla = listatablas[index];
            var tablaaux = {
              "idtabla": tabla.idtabla,
              "productos": []
            }

            var productos = [];
            for (let index = 0; index < listaproductos.length; index++) {
              var producto = listaproductos[index];

              var cantidadAuxiliar = 0;

              if(producto.cantidad=="" || parseInt(producto.cantidad,10)<0){
                cantidadAuxiliar = 0;
              }else{
                cantidadAuxiliar = parseInt(producto.cantidad,10)
              }
  
              if(cantidadAuxiliar >= 0){

                /// ACTUALIZAMOS RESUMEN PRINCIPAL

                var indexproductoresprincipal = -1;
                var isOriginal = false;
                if(producto.sustituido!=true){
                  auxtresumenPrincipal.map((item, index)=>{
                    if(item.idproducto==producto.idproducto && item.sustitutode!="Sustituido" ){
                      indexproductoresprincipal = index;
                      if(item.sustitutode=="" && item.sustituir=="" ){
                        isOriginal = true;
                      }
                    }
                  })
                }

                if(indexproductoresprincipal > -1){
                  
                  var indexproducto =  listaproductosresumen.findIndex((item => item.idproducto == producto.idproducto && item.sustitutode!="Sustituido"));
                  var indexproductosus =  this.state.listaproductossustitutos.findIndex((item => item.idproducto == producto.idproducto && item.sustitutode!="Sustituido"));
                  // si en una tabla ya existe el producto, se suman sus cantidades para mostrar en el resumen de compra
                  if(indexproducto > -1){
                    var prodcutoenresumen = listaproductosresumen[indexproducto];
                    if(prodcutoenresumen.cantidad=="" || parseFloat(prodcutoenresumen.cantidad)<0){
                      prodcutoenresumen.cantidad = 0;
                    }
                    var productoaux = {
                      "idproducto": producto.idproducto,
                      "Producto": producto.Producto,
                      "Unidadmedida":producto.Unidadmedida,
                      "cantidad": parseFloat(cantidadAuxiliar) + parseFloat(prodcutoenresumen.cantidad),
                    //  "precio": parseFloat(producto.precio) + parseFloat(prodcutoenresumen.precio),
                      "proveedorsugerido": "Sin proveedor",
                      "idproveedorsugerido": 0
                    };

                    var idproductoaux = "" + producto.idproducto;
                    if(idproductoaux.toString().includes("nuevo")){
                      productoaux.idcatalogo_unidadmedida = producto.Idunidadmedida
                    }
                    
                    listaproductosresumen[indexproducto] = productoaux;
                    if(!isOriginal){
                   
                      auxtresumenPrincipal[indexproductoresprincipal].cantidadVariable = parseFloat(auxtresumenPrincipal[indexproductoresprincipal].cantidadStatic) - parseFloat(productoaux.cantidad)
                    }
                    if(this.state.listaproductossustitutos[indexproductosus]!=undefined){
                    
                      this.state.listaproductossustitutos[indexproductosus].cantidadVariable = parseFloat(this.state.listaproductossustitutos[indexproductosus].cantidadStatic) - parseFloat(productoaux.cantidad)

                    }
                    
                    //si el producto aparece solo en una tabla
                  }else{
                    var productoaux = {
                      "idproducto": producto.idproducto,
                      "Producto": producto.Producto,
                      "Unidadmedida":producto.Unidadmedida,
                      "cantidad": producto.cantidad,
                      
                      "proveedorsugerido": "Sin proveedor",
                      "idproveedorsugerido": 0
                    };

                    var idproductoaux = "" + producto.idproducto;
                    if(idproductoaux.includes("nuevo")){
                      productoaux.idcatalogo_unidadmedida = producto.Idunidadmedida
                    }

                    listaproductosresumen.push(productoaux)
                    if(!isOriginal){
                    
                      auxtresumenPrincipal[indexproductoresprincipal].cantidadVariable = parseFloat(auxtresumenPrincipal[indexproductoresprincipal].cantidadStatic) - parseFloat(cantidadAuxiliar)
                    }
                    if(this.state.listaproductossustitutos[indexproductosus]!=undefined){
                     
                      this.state.listaproductossustitutos[indexproductosus].cantidadVariable = parseFloat(this.state.listaproductossustitutos[indexproductosus].cantidadStatic) - parseFloat(cantidadAuxiliar)

                    }
                    
                  }

                  var productodetabla = {
                    "idproducto": producto.idproducto,
                    "cantidad": producto.cantidad,
                  }
                  productos.push(productodetabla)
                }               
              }
            }

            tablaaux.productos = productos;
            listatablasrequestbody.push(tablaaux);
          }
        }
      }
    }

    
    // se actualizan las tablas de stock y resumen
    var productosstock = [];
    var productosstockrqb = [];
    let existproduct = this.state.stock.hasOwnProperty("productos");
    
    if(existproduct){
      productosstock = this.state.stock.productos;
    }
    
    for (let index = 0; index < productosstock.length; index++) {
      var producto = productosstock[index];

      var cantidadAuxiliar = 0;

      if(producto.cantidad=="" || parseInt(producto.cantidad,10)<0){
        cantidadAuxiliar = 0;
      }else{
        cantidadAuxiliar = parseInt(producto.cantidad,10)
      }

      if(cantidadAuxiliar>=0){

        var indexproductoresprincipal = -1;
        var isOriginal = false;

        if(producto.sustituido!=true){
          auxtresumenPrincipal.map((item, index)=>{
            if(item.idproducto==producto.idproducto && item.sustitutode!="Sustituido"){
              indexproductoresprincipal = index;
              if(item.sustitutode=="" && item.sustituir=="" ){
                isOriginal = true;
              }
            }
          })
        }

        if(indexproductoresprincipal > -1){
          var indexproducto =  listaproductosresumen.findIndex((item => item.idproducto == producto.idproducto && item.sustitutode!="Sustituido"));
          var indexproductosus =  this.state.listaproductossustitutos.findIndex((item => item.idproducto == producto.idproducto && item.sustitutode!="Sustituido"));
          if(indexproducto > -1){
            var prodcutoenresumen = listaproductosresumen[indexproducto];
            if(prodcutoenresumen.cantidad=="" || parseFloat(prodcutoenresumen.cantidad)<0){
              prodcutoenresumen.cantidad = 0;
            }
            
            var productoaux = {
              "idproducto": producto.idproducto,
              "Producto": producto.Producto,
              "Unidadmedida":producto.Unidadmedida,
              "cantidad": parseFloat(cantidadAuxiliar) + parseFloat(prodcutoenresumen.cantidad),
             // "precio": parseFloat(producto.precio) + parseFloat(prodcutoenresumen.precio),
              "proveedorsugerido": "Sin proveedor",
              "idproveedorsugerido": 0
            };
  
            var idproductoaux = "" + producto.idproducto;
            if(idproductoaux.toString().includes("nuevo")){
              productoaux.idcatalogo_unidadmedida = producto.Idunidadmedida
            }
            
            listaproductosresumen[indexproducto] = productoaux;
            if(!isOriginal){
             
              auxtresumenPrincipal[indexproductoresprincipal].cantidadVariable = parseFloat(auxtresumenPrincipal[indexproductoresprincipal].cantidadStatic) - parseFloat(productoaux.cantidad)
            }
          
              if(this.state.listaproductossustitutos[indexproductosus]!=undefined){
          
                this.state.listaproductossustitutos[indexproductosus].cantidadVariable = parseFloat(this.state.listaproductossustitutos[indexproductosus].cantidadStatic) - parseFloat(productoaux.cantidad)
            }
            
          }else{
            var productoaux = {
              "idproducto": producto.idproducto,
              "Producto": producto.Producto,
              "Unidadmedida":producto.Unidadmedida,
              "cantidad": producto.cantidad,
            //  "precio": producto.precio,
              "proveedorsugerido": "Sin proveedor",
              "idproveedorsugerido": 0
            };
            var idproductoaux = "" + producto.idproducto;
            if(idproductoaux.includes("nuevo")){
              productoaux.idcatalogo_unidadmedida = producto.Idunidadmedida
            }
  
            listaproductosresumen.push(productoaux)
            if(!isOriginal){
         
                auxtresumenPrincipal[indexproductoresprincipal].cantidadVariable = parseFloat(auxtresumenPrincipal[indexproductoresprincipal].cantidadStatic) - parseFloat(cantidadAuxiliar)
            }
       
            if(this.state.listaproductossustitutos[indexproductosus]!=undefined){
         
                this.state.listaproductossustitutos[indexproductosus].cantidadVariable = parseFloat(this.state.listaproductossustitutos[indexproductosus].cantidadStatic) - parseFloat(cantidadAuxiliar)
            }
            
          }
          var productodestock = {
            "idproducto": producto.idproducto,
            "cantidad": producto.cantidad,
          }
          productosstockrqb.push(productodestock)
        }
      }
    }
    
    tresumen.elementos = listaproductosresumen;
    tresumen.elementos = await this.actualizarProveedoresResumen(listaproductosresumen);

    var stockrequestbodyaux = this.state.stockrequestbody;
    stockrequestbodyaux.productos = productosstockrqb;

    this.state.tablaresumenProductos.elementos = auxtresumenPrincipal;
    this.state.infoDummy.resumencotizacion = auxtresumenPrincipal;

    var tresumenPrincipal = this.state.tablaresumenProductos;
    var auxtresumenPrincipal2 = this.state.infoDummy;
    var auxsustitutos = this.state.listaproductossustitutos;

    await this.setState({ 
      tablaresumen: tresumen,
      tablasrequestbody: listatablasrequestbody,
      stockrequestbody: stockrequestbodyaux,
      tablaresumenProductos: tresumenPrincipal,
      infoDummy: auxtresumenPrincipal2,
      listaproductossustitutos: auxsustitutos
    });

  }


  verModalPedirPassword = () => {
    this.setState({ modalpedirpassword: true });
  }

  cerrarModalPedirPassword = (accion) => {
    this.setState({modalpedirpassword: !this.state.modalpedirpassword})
    if(accion!=0){
      this.guardarRectificacion(accion)
    }
  }

  
  eliminarElemento = async (elementoeliminado) => {
    //Se ejecuta proceso de eliminación de seleccionados
    var listazonas = this.state.zonas.filter(zona => zona.idzona != elementoeliminado.idzona);
    this.calcularResumen(listazonas);

    this.setState({ 
      zonas: listazonas,
    })
  }

  eliminarElementoStock= async () => {
    var stock = this.state.stock;
    stock.productos = []
    
    this.calcularResumen(this.state.zonas);

    this.setState({ 
      stock: stock,
      stockactivo:false
    })
  }

  actualizarContenido = async (zona) => {
    var listazonas = this.state.zonas;

    var index =  listazonas.findIndex((obj => obj.idzona == zona.idzona));
    if(index > -1){
      listazonas[index] = zona;
    }

    this.calcularResumen(listazonas);

    this.setState({ zonas: listazonas })
  }
  
  actualizarContenidoStock = async (productos) => {

    //console.log("actualizarcontenidostock", productos)
    var stock = this.state.stock;

    stock.productos = productos
    
    this.calcularResumen(this.state.zonas); 

    this.setState({ stock: stock })
  }

  actualizarProveedoresResumen = async (listaproductosresumen) =>{
  //console.log("actualizarProveedoresResumen")
    var productostablaresumen =  listaproductosresumen;
    var productosresumeninfo = this.state.productosresumeninfo;
    for (let index = 0; index < productostablaresumen.length; index++) {
      var element = productostablaresumen[index];
      var indexproducto =  productosresumeninfo.findIndex((item => item.idproducto == element.idproducto));
      if(indexproducto != -1){
        element.proveedorsugerido = productosresumeninfo[indexproducto].proveedorsugerido;
        element.idproveedorsugerido = productosresumeninfo[indexproducto].idproveedorsugerido == "" ? 0 : productosresumeninfo[indexproducto].idproveedorsugerido;
      }
    }
    return productostablaresumen;
  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  iniciarSustitutos = (inforequisicion) => {
    //console.log("iniciar sustitutos", inforequisicion)
    var sustitutos = [];

    if(inforequisicion){
      inforequisicion.resumencotizacion.map((pd) => {
        if(pd.sustitutode!="" && pd.sustitutode!="Sustituido"){
          this.setState({haysustitutos: true})
          pd.cantidadStatic = pd.cantidad;
          pd.cantidadVariable = pd.cantidad;
          pd.sustituido = false;
          sustitutos.push(pd)
          var indexcotizacioneditar = inforequisicion.resumencotizacion.findIndex((item => item.idproducto==pd.sustitutode))
          if(indexcotizacioneditar > -1){
            inforequisicion.resumencotizacion[indexcotizacioneditar].sustitutode = "Sustituido";
            inforequisicion.resumencotizacion[indexcotizacioneditar].pendientes = 0;
            inforequisicion.resumencotizacion[indexcotizacioneditar].sustituido = true;
          }
        }
      })

      inforequisicion.zonas.map((zona)=>{
        zona.ranchos.map((rancho)=>{
          rancho.lotes.map((lote)=>{
            lote.tablas.map((tabla)=>{
              tabla.productos.map((pd)=>{
                var aux = inforequisicion.resumencotizacion.filter((item) => item.idproducto==pd.idproducto)
                if(aux.length > 0){
                  if(aux[0].sustitutode=="Sustituido"){
                    pd.sustituido = true;
                  }else if(aux[0].sustitutode=="" && (aux[0].sustituir=="0" || aux[0].sustituir=="")){
                    pd.original = true;
                    pd.sustituido = false;
                    pd.cantidadStatic = parseFloat(aux[0].cantidad)
                    pd.cantidadVariable = parseFloat(aux[0].cantidad)
                  }else{
                    pd.sustituido = false;
                    pd.cantidadStatic = parseFloat(aux[0].cantidad)
                    pd.cantidadVariable = parseFloat(aux[0].cantidad)
                  }
                }
              })
            })
          })
        })
      })


      let existproduct = inforequisicion.stock.hasOwnProperty("productos");
      if(existproduct){
        inforequisicion.stock.productos.map((prod)=>{
            var aux = inforequisicion.resumencotizacion.filter((item) => item.idproducto==prod.idproducto)
            if(aux.length > 0){
              if(aux[0].sustitutode=="Sustituido"){
                prod.sustituido = true;
              }else if(aux[0].sustitutode=="" && (aux[0].sustituir=="0" || aux[0].sustituir=="")){
                prod.original = true;
                prod.sustituido = false;
                prod.cantidadStatic = parseFloat(aux[0].cantidad)
                prod.cantidadVariable = parseFloat(aux[0].cantidad)
              }else{
                prod.sustituido = false;
                prod.cantidadStatic = parseFloat(aux[0].cantidad)
                prod.cantidadVariable = parseFloat(aux[0].cantidad)
              }
            }
        })
      }


    }

    let objeto = {
      sustitutos: sustitutos,
      procesados: inforequisicion
    }

    return objeto;
  }

  RenderColumns = (item, index) => {
    return (
      <>
        <tr className={"sustituidoFila"}>
                              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                {item.producto}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                {item.unidadmedida}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                {item.cantidad}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                {item.proveedor_recomendado!="" ? item.proveedor_recomendado : "Sin proveedor"}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                { "Producto a cambiar"}
                              </td> 
        </tr>

         {this.state.listaproductossustitutos ? this.state.listaproductossustitutos.map((item2, index2) => ( 
                          item2.sustitutode==item.idproducto ? 
                            <tr className={item2.pendientes == 0 ? "sustitutosFila sinPendientes" : "sustitutosFila"} >
                              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                {item2.producto}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                {item2.unidadmedida}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                {item2.cantidad}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                {item2.proveedor_recomendado!="" ? item2.proveedor_recomendado : "Sin proveedor"}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                { "Sustituto de "+item.producto}
                              </td>
                            </tr> 
                          : null
        )):null}
      </>
    );
  }

  guardarRectificacion = async (password) => {
    
    //valida que todos los productos fueron repartidos en su totalidad
    var repartidosAll = true;
    var productoNoreparidoTotal = "";
    var productoExcede = "";
    this.state.listaproductossustitutos.map((item)=>{
      if(item.cantidadVariable!=0){
        repartidosAll = false;
        productoNoreparidoTotal = item.producto;
      }
    })

    if(repartidosAll){
      //valida que todos los productos fueron repartidos en su totalidad y con la cantidad especificada en el resumen
      this.state.tablaresumen.elementos.map((prod)=>{
        var aux = this.state.tablaresumenProductos.elementos.filter((item) => item.idproducto==prod.idproducto && item.sustituir!=1)
        if(aux.length > 0){
          if(parseFloat(aux[0].cantidad)!=parseFloat(prod.cantidad)){
            repartidosAll = false;
            productoExcede = prod.producto;
          }
        }
      })
    }
    
    if(repartidosAll){
      //validaremos en las tablas que no existe un producto con cantidades vacios o negativas
        var productosValidos = true;
        this.state.tablasrequestbody.map((tabla)=>{
          tabla.productos.map((pd)=>{
            if(parseFloat(pd.cantidad)<1){
              productosValidos = false;
            }
          })
        })

        if(productosValidos){
          //validamos los productos STOCK
          var stockvalido = true;
          this.state.stockrequestbody.productos.map((pd)=>{
            if(parseFloat(pd.cantidad)<1){
              stockvalido = false;
            }
          })

          if(stockvalido){
            //validaremos resumen compra

            // SE ARMA EL JSON PARA GUARDAR
            let json = {
              idcatalogo_requisicion: this.state.inforequisicion.idcatalogo_requisicion,
              password: password,
              tablas: this.state.tablasrequestbody,
              //stock: [],
              //Resumen: []
            }

            if(this.state.stockrequestbody.idstock!=0){
              json.stock = this.state.stockrequestbody;
            }
            

            var productosResumenCompraAux = [];
            this.state.tablaresumen.elementos.map((elemento) => {
              let prod = {
                idproducto: elemento.idproducto,
                cantidad: elemento.cantidad
                //idproveedorsugerido: elemento.idproveedorsugerido
              }
              productosResumenCompraAux.push(prod)
            })

            let resumenProdAux = {
              productos: productosResumenCompraAux
            }

            json.Resumen = resumenProdAux;

            //console.log("JSON ARMADO: ",json)

            this.setState({ loadingspinners: true })

            var resp = await guardarRectificacion(json);
            
            if (resp == -1 || resp.code == 1000) {
              this.setState({ loadingspinners: false })
              toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
              return;
            }
            else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
              this.setState({ loadingspinners: false })
              toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
              return;
            }
            else {
              this.setState({  redireccionandoarequisicion: true})
            }
          }else{
            toast.error("Hay productos con cantidades no validas en el Stock", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
        }else{
            toast.error("Hay productos con cantidades no validas en las tablas", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
    }else{
      if(productoExcede!=""){
        toast.error("La cantidad de "+productoExcede+" es invalida, ver en el resumen la cantidad disponible", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
      }else if(productoNoreparidoTotal!=""){
        toast.error("Cantidad de "+productoNoreparidoTotal+" incompleta, verifica que se haya repartido por completo", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
      }
    }
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionandoarequisicion) ? (<Acciones bandera={this.state.banderaref!="" ? this.state.banderaref : 'irarequisicion'}/>) : null}
        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}


        {(this.state.modalpedirpassword == true) ? (
          <ModalPedirPassword titulomodal={"Autorizar acción"} 
            cerrarModal={this.cerrarModalPedirPassword} 
            show={this.state.modalpedirpassword} 
            accion={this.state.accionmodal}/>
        ) : null}
 
        <Navbar />
         {/* ////////////////////   Inicio Sección informacion   ////////////////////////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezado'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.inforequisicion.nombre}</label>
              </div>

              <div className='col-12 col-sm-12 col-md-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Plan anual 2023, Febrero </label>
              </div>

              <div className='col-8 col-sm-8 col-md-8 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>ID: </label>
                <label className='lblTexto letraTitulos' >{this.state.inforequisicion.idcatalogo_requisicion}</label>
              </div>

              <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha estimada de entrega: </label>
                <label className='lblTexto letraTitulos' >{this.state.inforequisicion.fechaestimada_compra}</label>
              </div>

              <div className='col-8 col-sm-8 col-md-8 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Actividad: </label>
                <label className='lblTexto letraTitulos' >{"Nombre de la actividad"}</label>
              </div>

              <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus: </label>
                <label className='lblTexto letraTitulos' >{this.state.inforequisicion.Estatusrequisicion}</label>
              </div>

              <div className='col-8 col-sm-8 col-md-8 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Tipo de requisición: </label>
                <label className='lblTexto letraTitulos' >{this.state.inforequisicion.tiporequisicion}</label>
              </div>

              <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de creación: </label>
                <label className='lblTexto letraTitulos' >{this.state.inforequisicion.fechacreacion}</label>
              </div>

              <div className='col-8 col-sm-8 col-md-8 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Solicitante: </label>
                <label className='lblTexto letraTitulos' >{this.state.inforequisicion.solicitante}</label>
              </div>

              <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de actualización: </label>
                <label className='lblTexto letraTitulos' >{this.state.inforequisicion.fechaactualizacion}</label>
              </div>

            </div>
            <br />
            <br />
          </div>
        </div>
        {/* ////////////////////   Fin Sección informacion   ////////////////////////////////////// */}



        {/* ////////////////////   Inicio Sección  Resumen requisicion  ////////////////////////////////////// */}
        <br />
        <div className="container-fluid containerTabla ">
          <div className="container-fluid" style={{ minWidth: '1020px', maxWidth: '90%', background: '#f8f5f2'}}>
            <div className='containerSubTablaAA divMinWidth'>
              <Table>
                <thead style={{ verticalAlign: "bottom"}}>
                  <tr>
                    {this.state.tablaresumenProductos.encabezados.map((titulo, index) => (
                      <th className="lblEncabezadosTabla letraTitulos"
                        style={{
                          minWidth: this.state.tablaresumenProductos.mindimensiones[index],
                          maxWidth: this.state.tablaresumenProductos.maxdimensiones[index],
                          width: this.state.tablaresumenProductos.dimensiones[index],
                          color: this.state.colorencabezadostabla
                        }}>
                        {index == 0 ? "Resumen" : null}
                      </th>
                    ))}
                  </tr>
                  <tr style={{ borderBottom: 'solid 2px #91b9f9'}}>
                    {this.state.tablaresumenProductos.encabezados.map((titulo, index) => (
                      <th className="lblEncabezadosTabla letraTitulos"
                        style={{
                          minWidth: this.state.tablaresumenProductos.mindimensiones[index],
                          maxWidth: this.state.tablaresumenProductos.maxdimensiones[index],
                          width: this.state.tablaresumenProductos.dimensiones[index],
                          color: this.state.colorencabezadostabla,
                          textAlign: (index > 0 && index < 6) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px'
                        }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {/* ////////////////////   lista de productos resumen  ////////////////////////////////////// */}
                  {
                    this.state.tablaresumenProductos.elementos ? this.state.tablaresumenProductos.elementos.map((item, index) => (

                      item.sustitutode=="" ?

                      <tr className={item.pendientes == 0 ? "sinPendientes" : ""} style={{ borderBottom: (index == (this.state.tablaresumenProductos.elementos.length-1 )) ? 'solid 2px #91b9f9' : '' }}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.producto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                          {item.unidadmedida}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                          {item.cantidad}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                          {item.proveedor_recomendado!="" ? item.proveedor_recomendado : "Sin proveedor"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          { "Producto orginal" }
                        </td>
                      </tr>

                      : item.sustitutode=="Sustituido" ?
                        this.RenderColumns(item, index)
                        
                      : null
                    )) : null
                  }
                  <br></br>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección  Resumen requisicion ////////////////////////////////////// */}



        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

        <div className="container-fluid">
          <div className="container-fluid anchoMaximo">
            <div className="container-fluid">
              {this.state.zonas.length > 0 ?
                this.state.zonas.map((item, index) => (
                  <Zona key={"zona" + item.idzona} 
                  itemzona={item} 
                  actualizarContenido={this.actualizarContenido}
                  eliminarElemento={this.eliminarElemento}
                  opcioneditar={this.state.opcioneditar} accion={this.state.opcioneditar ? 2:1}
                  listalotes={this.state.listalotes}
                  listatablas={this.state.listatablas}
                  listaprodresumen={this.state.infoDummy.resumencotizacion}
                  />
                ))
                : <div></div>
              }
            </div>
            
            <br/>
            <div className="container-fluid">
              {this.state.stockactivo?
                <div className='spacePaddingBottom'>
                  <TablaAbastecimientoStock key={"tabla_stock"} accion={this.state.accion} 
                  itemtabla={this.state.stock.hasOwnProperty("productos") ? this.state.stock: {productos: []}} 
                  actualizarContenidoStock={this.actualizarContenidoStock}
                  eliminarElementoStock={this.eliminarElementoStock}
                  listaprodresumen={this.state.infoDummy.resumencotizacion}
                  />
                </div>:null
              }
            </div>

          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}



        {/* ////////////////////   Inicio Sección Tabla  Resumen  ////////////////////////////////////// */}
        <br />
        {
          this.state.tablaresumen.elementos.length > 0 ?
          (<div className="container-fluid containerTabla contenedorResumenNR">
              <div className="container-fluid  ">
                <div className='col-auto containerSubTabla minPaddingTopBottom d-flex align-items-center'>
                  <label className='letraTitulos'><b>Resumen de compra</b></label>
                </div>
                <div className="containerSubTabla">
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        {this.state.tablaresumen.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} 
                          className={(index == 1||index==2) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                            style={{ 
                              minWidth: this.state.tablaresumen.mindimensiones[index], 
                              width: this.state.tablaresumen.dimensiones[index], 
                              color: this.state.colorencabezadostabla }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      {
                        this.state.tablaresumen.elementos.map((item, index) => (
                          <tr key={"tabla" + index}>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                              {item.Producto}
                            </td>
                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                              {item.Unidadmedida}
                            </td>
                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                              {item.cantidad}
                            </td>
                          
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>):null
        }
        {/* ////////////////////   Fin Sección Tabla  Resumen ////////////////////////////////////// */}

        <>
          <br />
          <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
              <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
          </div>
          <br />
          <div className="divBtnContenedorModal spacePaddingBottom" >
              <Button className="btnCancelModal" onClick={() => this.setState({  redireccionandoarequisicion: true })}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.verModalPedirPassword()} disabled={!this.state.confirmarenvio}>Aprobar</Button>
          </div>
        </>
     
      </>
    )
  }
}

function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }else if(bandera == "irarequisicion"){
    return (<Redirect from='/' to={"/catalogorequisicionesagroquimicos"} />)
  }else if(bandera == "rectificar"){
    return (<Redirect from='/' to={"/rectificarproductos"} />)
  }
}