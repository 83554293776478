import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import "./ModalFormularioCorridasLimonTraslados.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import { CgClose } from 'react-icons/cg';
import { postGuardarCorridaLimonTraslado, getListaBitacoraTraslados, getListaTrasladosRecepcionDisponiblesRejas } from '../../../Services/Api';

export default class ModalFormularioCorridasLimonTraslados extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            accion:props.accion,

            idcatalogo_corridas_limon:props.idcatalogo_corridas_limon,
            
            fechainicio:"",
            fechafin:this.props.fecha,

            vermsgerrorfecha: false,
            vermsgerrortraslado: false,

            msgerrorfecha: '',
            msgerrortraslado: '',

            listatraslados: [],
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            txttraslado:0,

        }

    }


    async componentDidMount() {
        var respbitacoratraslados = await getListaTrasladosRecepcionDisponiblesRejas();
        if (this.manejadorLlamadasApis(respbitacoratraslados)) {
            return;
        }

        // filtrar fletes por fecha, solo deben ser de 5 días at´ras hasta la fecha actual
        var fechaactualFin = new Date(this.props.fecha);
        const fechaa = fechaactualFin.getDate();
        fechaactualFin.setDate(fechaa);

        var fechaactualIni = new Date(this.props.fecha);
        fechaactualIni.setDate(fechaactualIni.getDate() - 5);
        const defaultValue = fechaactualIni.toLocaleDateString('en-CA');


        const resultado = respbitacoratraslados.data.filter(item => {
            const fechaItem = new Date(item.fecha);
            return fechaItem >= fechaactualIni && fechaItem <= fechaactualFin;
        });

      
        this.setState({ listatraslados: resultado, fechainicio:defaultValue, fechafin:this.props.fecha })

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop, item) =>  {
        this.setState({ [prop]: item });
    }

    cambiarFecha = async (item) => {
        this.setState({ [item.llave]: item.valor })

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

        var respbitacoratraslados = await getListaBitacoraTraslados({
            "fecha": year + '-' + month + '-' + day
        });
        if (this.manejadorLlamadasApis(respbitacoratraslados)) {
            return;
        }

        this.setState({listatraslados:respbitacoratraslados.data})
    }

    guardardatos = async () => {
        this.setState({ vermsgerrortraslado: false })
        //this.setState({ vermsgerrorfecha: false })

        let validacionescorrectas = true

        /*
        if (this.state.txtfecha == "" || this.state.txtfecha == null) {
            validacionescorrectas = false;
            this.setState({ msgerrorfecha: "Selecciona una fecha" })
            this.setState({ vermsgerrorfecha: true })
        }

        */
        if (this.state.txttraslado < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortraslado: "Seleccione un traslado" })
            this.setState({ vermsgerrortraslado: true })
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);
            var resp = undefined;

            let json = {
                "idcatalogo_corridas_limon_traslados": 0,
                "idcatalogo_corridas_limon": this.state.idcatalogo_corridas_limon,
                "idcatalogo_bitacora_traslados_detalles": this.state.txttraslado,
                "eliminar": "0"
            }

            resp = await postGuardarCorridaLimonTraslado(json);

            if(resp.code == 200){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }

    }


    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
                        <br />
                        Disponibles de {this.state.fechainicio} a {this.state.fechafin}
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                {/*
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha</p>
                                <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                    <DatePicker
                                        selected={this.state.fecha}
                                        onChange={(date) => this.cambiarFecha({ llave: "fecha", valor: date })}
                                        //dateFormat="dd/MM/yyyy"
                                        dateFormat="yyyy-MM-dd"
                                        locale="es"
                                        customInput={
                                            <DateButton />
                                        }
                                    />
                                </div>

                                {this.state.vermsgerrorfecha ?
                                    <p
                                        className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}
                                    >
                                        {this.state.msgerrorfecha}
                                    </p>
                                :null}
                                */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Traslado </p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={(e)=> this.escribir("txttraslado",e.target.value)}>
                                                {this.state.listatraslados == undefined || this.state.listatraslados.length == 0 ?
                                                    <>
                                                        <option disabled="disabled" selected={true}>Seleccionar</option>
                                                        <option disabled="disabled">No hay folios</option>
                                                    </>
                                                :
                                                    <>
                                                        <option disabled="disabled" selected={this.state.txttraslado==0 ? true : false}>Seleccionar</option>
                                                        {this.state.listatraslados.map((i) => (
                                                                <option
                                                                    value={i.idcatalogo_bitacora_traslados_detalles} 
                                                                    key={"tipo_" + i.idcatalogo_bitacora_traslados_detalles}
                                                                    selected={i.idcatalogo_bitacora_traslados_detalles==this.state.txttraslado ? true : false}
                                                                >
                                                                    {i.folio} ({i.tipo})
                                                                </option>
                                                        ))}
                                                    </>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrortraslado ?
                                    <p
                                        className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}
                                    >
                                        {this.state.msgerrortraslado}
                                    </p>
                                :null}

                            </div>
                        </div>

                        <div className="divBtnContenedorModal" >
                            <br />
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardardatos()} >Continuar</Button>
                        </div>
                    </ModalBody>

                </Modal>
            </>
        )
    }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686', width: '96%', margin: '0px 8px 18px 8px'
        }}
    >
        <label style={{ width: '90%', textAlign: 'left', cursor: 'pointer' }}>{value ? value : 'dd/mm/aaaa'}</label>
        <FaCalendar className="icoBotones" style={{ minWidth: '28px' }} />
    </button>
));
