import React from 'react'
import { FaCalendar } from 'react-icons/fa';
import { Button, Modal, ModalBody } from 'reactstrap';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../Services/PaletaDeColores';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createNuevaNomina, editarNomina } from '../../Services/Api';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { validarLetrasMayusculasYNumerosNomina } from '../../Services/Validaciones';


export default class ModalNuevaNomina extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            cuerpo: props.cuerpomodal,
            loadingspinners: false,

            fechacompraini: props.ingreso == 1 ? this.formatofechas(props.item.fechainicio) : new Date(),
            fechacomprafin: props.ingreso == 1 ? this.formatofechas(props.item.fechafin) : new Date(),

            clavenomina: props.ingreso == 1 ? props.item.clave : "",


            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

        }

    }
    formatofechas(fecha) {
        if(fecha !=""){
            var cadenas = fecha.split("-");
            var formato = cadenas[1] + "/" + cadenas[2] + "/" + cadenas[0];
            return new Date(formato)

        }else{
            return fecha
        }
       
    }

    showModal = async (accion) => {

        if (accion == 1) {
            this.setState({ loadingspinners: true })
            const result = format(this.state.fechacompraini, 'yyyy-MM-dd');
            const result2 = format(this.state.fechacomprafin, 'yyyy-MM-dd');

            let validarclave = validarLetrasMayusculasYNumerosNomina(this.state.clavenomina, { longitudmaxima: 6, longitudminima: 6 })

            if (validarclave.codigo == 'invalid') {

                toast.info("Clave nómina: "+validarclave.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.setState({ loadingspinners: false })
                return true
            }


            console.log("fechas", result, result2)
           if(result>=result2){
            toast.info("La fecha de inicio debe ser menor que la fecha fin", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.setState({ loadingspinners: false })
            return true

           }
           

            localStorage.setItem("idcatalogo_nominas", "0")

            var json = {
                "fechainicio": result,
                "fechafin": result2,
                "fechainiciojson": this.state.fechacompraini,
                "fechafinjson": this.state.fechacomprafin,
                "idestatus": "5"
            }

            var json2 = {
                "fechainicio": result,
                "fechafin": result2,
                "idestatus": "5",
                "clave": this.state.clavenomina,
                "idcatalogo_nominas": this.props.ingreso == 1 ? this.props.item.idcatalogo_nominas : 0
            }

            var json3= {
                "clave": this.state.clavenomina,
                "idcatalogo_nominas": this.props.ingreso == 1 ? this.props.item.idcatalogo_nominas : 0
            }

            var resp =  this.props.ingreso==1 ? await editarNomina(json3):await createNuevaNomina(json2);
        
            if (this.manejadorLlamadasApis (resp)) {
                return;
              }

  
            localStorage.setItem("nominanueva", 1);
            toast.success("Nómina guardada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            setTimeout(() => {
                this.props.cerrarmodal(1, json);
            }, 200);

        }
        else {
            this.setState({ loadingspinners: false })

            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarmodal(0);
            }, 200);

        }

    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300 || resp.code == 200) {
           // if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            //}
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }


    escribir = (clave) => {
        const updatedValue = clave.replace(/[a-z]/g, (match) => match.toUpperCase());
        this.setState({ clavenomina: updatedValue })
    }

    render() {
    
        return (
           

            <>
                <ToastContainer />

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div className="divTituloModal">
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >
                            {this.props.ingreso==1? "Editar Nómina":"Nueva Nómina"}</label><br />
                            {this.props.ingreso==1? null:<small style={{ paddingLeft: "8px" }}>Selecciona fecha inicio y fecha fin</small>}
                        </div>


                        <div className="divModalEliminar">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                                <div className='row'>



                                    <div className='col-12'>
                                        <p className='lblSubTitulosModal ' >
                                            Clave nómina:

                                            <div>
                                                <input className='inTextoModal letraNormal'
                                                    style={{
                                                        color: this.state.estilosmodal.colorlbltextomodal,
                                                        backgroundColor: this.state.estilosmodal.fondocontenedormodal,
                                                        border: this.state.estilosmodal.colorborderbottom,//" solid 1px #8f8f90"                                   
                                                        textAlign: 'center'
                                                    }}
                                                    type={'text'} value={this.state.clavenomina}
                                                    onChange={(e) => this.escribir(e.target.value)}
                                                    placeholder='202401'
                                                ></input>
                                            </div>

                                        </p>

                                    </div>

                                </div>
                                <div className='row'>

                                    <div className='col-6'>
                                        <p className='lblSubTitulosModal ' >
                                            Fecha Inicio: 

                                            {this.props.ingreso==1 ?                                                 
                                                <div>{this.props.item.fechainicio}</div>:                                         
                                            <DatePicker
                                                selected={this.state.fechacompraini}
                                                onChange={(date) => this.cambiarFecha({ llave: "fechacompraini", valor: date })}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={
                                                    <DateButton />
                                                }
                                            />}
                                        </p>

                                    </div>

                                    <div className='col-6'>
                                        <p className='lblSubTitulosModal ' >
                                            Fecha Fin:
                                            {this.props.ingreso==1 ? 
                                                
                                                <div>{this.props.item.fechafin}</div>:

                                            <DatePicker
                                                selected={this.state.fechacomprafin}
                                                onChange={(date) => this.cambiarFecha({ llave: "fechacomprafin", valor: date })}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={
                                                    <DateButton />
                                                }
                                            />}
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <br />
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.showModal(1)} disabled={!this.state.confirmarenvio}>Continuar</Button>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }
}


const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}>

        <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
));

