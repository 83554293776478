import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoAsignarUsuarios.css';
import { BiEdit, BiSearchAlt2, } from "react-icons/bi";

import { BsTrash } from "react-icons/bs";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloTabla, estiloBtnVerde, estiloBtnRojo, estiloBtnRestablecer, estiloBtnEliminar } from '../../Services/PaletaDeColores';
import ModalFormularioUsuarios from '../../Complements/Modales/ModalFormularioUsuarios/ModalFormularioUsuarios';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaCatalogoUsuarios, getComboAreasDeTrabajo, getListaPuestosDeTrabajo, getlistazonass, setRestablecerUsuario, setEstatusUsuario, getListaZonas, getListaRanchosDisponibles, getListaAgregarusuario, EliminarAsignarusuario, EditarAsignarusuario } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ModalAsignarUsuario from '../../Complements/Modales/ModalAsignarUsuario/ModalAsignarUsuario';
import Modalconfirmaasignar from './Modalconfirmaasignar';
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoCheckbox } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";




export default class CatalogoAsignarUsuarios extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      loadingspinners: true,
      seleccionar_todos: false,

      tabla: {
        encabezados: [

          "Nombre(s)",
          "Zona",
          "Rancho",
          "Estatus",
        

          ""],
        dimensiones: [

          "15%",
          "9%",
          "9%",
          "7%",  
          "5%"
        ],
        mindimensiones: [

          "110px",
          "80px",
          "80px",
          
          "60px",
          "80px"

        ],
        elementos: []
      },
      estatus: [],
      listaDatosGenerales: [],
      listaelementosfiltrados: [],


      txtbuscador: '',
      txtselected: '0',
      txtselectedzona: '0',
      txtselectedrancho: '0',
      txtselectedtipocultivo: '0',
      txtselectedcultivo: '0',

      elegirItems: {},
      jsoneliminar: {},
      banderamodaleditar: "",
      nombreusuaremodal: "",
      mensajeasignar: "",
      vermodaleliminar: false,
      areaseleccionado: "TodasLasAreas",
      puestoseleccionado: "TodosLosPuestos",
      listaranchosModal: [],
      listaranchos: [],
      listazonas: [],
      listaarea: [],
      listapuesto: [],
      listafiltropuesto: [],
      listafiltroarea: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,

      errordeacceso: false,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEliminar().colorfondobtneliminar,
      btncoloreditar: estiloBtnEliminar().colorbtneliminar,
      btnbordereditar: estiloBtnEliminar().colorbordebtneliminar,




      colorfondobtnverde: estiloBtnVerde().colorfondobtnverde,
      colorbtnverde: estiloBtnVerde().colorbtnverde,
      colorbordebtnverde: estiloBtnVerde().colorbordebtnverde,

      colorfondobtnrojo: estiloBtnRojo().colorfondobtnrojo,
      colorbtnrojo: estiloBtnRojo().colorbtnrojo,
      colorbordebtnrojo: estiloBtnRojo().colorbordebtnrojo,

      colorfondobtnrestablecer: estiloBtnRestablecer().colorfondobtnrestablecer,
      colorbtnrestablecer: estiloBtnRestablecer().colorbtnrestablecer,
      colorbordebtnrestablecer: estiloBtnRestablecer().colorbordebtnrestablecer,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var resplistaareas = await getComboAreasDeTrabajo();
    if (this.manejadorLlamadasApis(resplistaareas)) {
      return;
    }

    var resplistapuestos = await getListaPuestosDeTrabajo();
    if (this.manejadorLlamadasApis(resplistapuestos)) {
      return;
    }
    var resplistaranchos = await getListaRanchosDisponibles();
    if (this.manejadorLlamadasApis(resplistaranchos)) {
      return;
    }

    var resplistazonas = await getListaZonas();
    if (this.manejadorLlamadasApis(resplistazonas)) {
      return;
    }

    var respcatalogousuarios = await getListaAgregarusuario();
    if (this.manejadorLlamadasApis(respcatalogousuarios)) {
      return;
    }

    var respcatalogousuarios2 = await getListaCatalogoUsuarios();
    if (this.manejadorLlamadasApis(respcatalogousuarios2)) {
      return;
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    var tabla = this.state.tabla;
    //
    var tablanueva = respcatalogousuarios2.data;
    //tabla.elementos = 

    for (let i = 0; i < tablanueva.length; i++) {
      tablanueva[i].activo = false;
    }

    this.setState({ listaDatosGenerales: tablanueva })
    // respcatalogousuarios.data;;
  

    tabla.elementos = respcatalogousuarios.data

    await this.setState({
      txtbuscador: '',
      tabla: tabla,
      estatus: estatus.data,
      listaarea: resplistaareas.data,
      listapuesto: resplistapuestos.data,
      listafiltropuesto: resplistapuestos.data,
      listazonas: resplistazonas.data,
      listaranchos: resplistaranchos.data,
      listaranchosModal: resplistaranchos.data
    })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }


  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({
      loadingspinners: true,
      txtbuscador: '',
      txtselectedzona: 0,
      txtselectedrancho: 0,
      puestoseleccionado: "TodosLosPuestos",
      areaseleccionado: "TodasLasAreas",
      errordeacceso: false,
    })
    this.componentDidMount();
  }

  cambiarValor = () => {
    //console.log("AAA");
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  filtrarArea = async (itemfiltrado, accion) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    await this.setState({
      areaseleccionado: itemfiltrado
    })

    var filtroarea = [];
    var filtropuesto = [];
    var filtrofinal = [];

    filtroarea = data;
    if (itemfiltrado != "TodasLasAreas") {

      var resplistapuestos = this.state.listapuesto.filter((item) => item.idcatalogo_areas == itemfiltrado)

      await this.setState({
        listafiltropuesto: resplistapuestos,
        txtbuscador: ""
      })

      if (accion == 0) {
        await this.setState({
          puestoseleccionado: "TodosLosPuestos"
        })
      }

      filtroarea = data.filter((item) => item.idarea == itemfiltrado)
    } else {
      await this.setState({
        listafiltropuesto: this.state.listapuesto,
        txtbuscador: ""
      })

      if (accion == 0) {
        await this.setState({
          puestoseleccionado: "TodosLosPuestos"
        })
      }
    }

    if (this.state.puestoseleccionado != "TodosLosPuestos") {

      filtropuesto = filtroarea.filter((item) => item.idpuesto == this.state.puestoseleccionado)
    } else {
      filtropuesto = filtroarea;
    }

    if (this.state.txtbuscador.length >= 3) {
      filtrofinal = filtropuesto.filter((item) => item.nombres.toLocaleLowerCase().includes(this.state.txtbuscador)
        || item.apellidos.toLocaleLowerCase().includes(this.state.txtbuscador)
        || item.usuario.toLocaleLowerCase().includes(this.state.txtbuscador))
    } else {
      filtrofinal = filtropuesto;
      await this.setState({
        txtbuscador: ""
      })
    }

    if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    this.filtro(1, filtrofinal)
  }

  filtrarPuesto = async (itemfiltrado) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    await this.setState({
      puestoseleccionado: itemfiltrado
    })

    var filtroarea = [];
    var filtropuesto = [];
    var filtrofinal = [];
    filtroarea = data;
    if (this.state.areaseleccionado != "TodasLasAreas") {

      filtroarea = data.filter((item) => item.idarea == this.state.areaseleccionado)
    }

    filtropuesto = filtroarea;
    if (itemfiltrado != "TodosLosPuestos") {

      filtropuesto = filtroarea.filter((item) => item.idpuesto == itemfiltrado)
    }

    if (this.state.txtbuscador.length >= 3) {
      filtrofinal = filtropuesto.filter((item) => item.nombres.toLocaleLowerCase().includes(this.state.txtbuscador)
        || item.apellidos.toLocaleLowerCase().includes(this.state.txtbuscador)
        || item.usuario.toLocaleLowerCase().includes(this.state.txtbuscador))
    } else {
      filtrofinal = filtropuesto;
      await this.setState({
        txtbuscador: ""
      })
    }

    if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    this.filtro(1, filtrofinal)

  }

  saveInputLocacionwe = async (e) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenast > tamanocadenain) && (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value });

    var filtropuesto = [];
    var filtroarea = [];
    var filtrofinal = [];

    if (tamanocadenain >= 3 || banderacasosespeciales) {
      filtroarea = data;
      if (this.state.areaseleccionado != "TodasLasAreas") {
        filtroarea = data.filter((item) => item.idcatalogo_zona == this.state.areaseleccionado)
      }
      filtropuesto = filtroarea;

      if (this.state.puestoseleccionado != "TodosLosPuestos") {
        filtropuesto = filtroarea.filter((item) => item.idcatalogo_rancho == this.state.puestoseleccionado)
      } else { filtropuesto = filtroarea; }

      filtrofinal = filtropuesto;
      if (e.target.value.toLocaleLowerCase().length >= 3) {
        filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
          || item.apellido.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
      }
      if (filtrofinal.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.filtro(1, filtrofinal)

    }

  }

  //Funcion para filtrar por los select y texto en buscador
  saveInputLocacion = async (e) => {

    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtrozonas = [];
    var filtroranchos = [];
    var filtrotipocultivo = [];
    var filtrocultivo = [];
    var filtronombre = [];

    if(e.target.id=='Search'){
      await this.setState({ txtbuscador: e.target.value });

      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
      if(this.state.txtselectedtipocultivo==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==this.state.txtselectedtipocultivo)
      }
      if(this.state.txtselectedcultivo==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==this.state.txtselectedcultivo)
      }
      filtronombre = filtrocultivo.filter((item) => item.nombre.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
      || item.apellido.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
    }else if(e.target.id=='zonas'){
      await this.setState({
        txtselectedzona: e.target.value,
        txtselectedrancho:0,
        txtselectedtipocultivo:0,
        txtselectedcultivo:0
      });

      if(e.target.value==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==e.target.value)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
      if(this.state.txtselectedtipocultivo==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==this.state.txtselectedtipocultivo)
      }
      if(this.state.txtselectedcultivo==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==this.state.txtselectedcultivo)
      }
      if(this.state.txtbuscador.length < 3) {
        filtronombre = filtrocultivo;
      }else{
        filtronombre = filtrocultivo.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase())
        || item.apellido.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }

    }else if(e.target.id=='ranchos'){
      await this.setState({
        txtselectedrancho: e.target.value,
        txtselectedtipocultivo:0,
        txtselectedcultivo:0
      });
      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(e.target.value==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==e.target.value)
      }
      if(this.state.txtselectedtipocultivo==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==this.state.txtselectedtipocultivo)
      }
      if(this.state.txtselectedcultivo==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==this.state.txtselectedcultivo)
      }
      if(this.state.txtbuscador.length < 3) {
        filtronombre = filtrocultivo;
      }else{
        filtronombre = filtrocultivo.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase())
        || item.apellido.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }
    }else if(e.target.id=='tipocultivos'){
      await this.setState({
        txtselectedtipocultivo: e.target.value,
        txtselectedcultivo:0
      });
      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
      if(e.target.value==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==e.target.value)
      }
      if(this.state.txtselectedcultivo==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==this.state.txtselectedcultivo)
      }
      if(this.state.txtbuscador.length < 3) {
        filtronombre = filtrocultivo;
      }else{
        filtronombre = filtrocultivo.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase())
        || item.apellido.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }
    }else if(e.target.id=='cultivos'){
      await this.setState({ txtselectedcultivo: e.target.value });
      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
      if(this.state.txtselectedtipocultivo==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==this.state.txtselectedtipocultivo)
      }
      if(e.target.value==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==e.target.value)
      }
      if(this.state.txtbuscador.length < 3) {
        filtronombre = filtrocultivo;
      }else{
        filtronombre = filtrocultivo.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase())
        || item.apellido.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }
    }else if(e.target.id=='CSearch'){
      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
      if(this.state.txtselectedtipocultivo==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==this.state.txtselectedtipocultivo)
      }
      if(this.state.txtselectedcultivo==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==this.state.txtselectedcultivo)
      }
      filtronombre = filtrocultivo;
      this.setState({ txtbuscador: '' })
    }

    if (filtronombre.length < 1) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.filtro(1, [])
    }else{
      this.filtro(1, filtronombre)
    }
  }

  setEstatusUsuario = async (ban) => {

    let json = {
      "idcatalogousuarios": ban.idcatalogousuarios,
      "idestatus": ban.estatus
    }
    this.activarSpinner(true);
    var resp = await setEstatusUsuario(json);
    if (resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      this.activarSpinner(false);
      return;
    }
    else if (resp.code == 200 || resp.code == 300) {
      this.activarSpinner(false);
      this.setState({ confirmarenvio: !this.state.confirmarenvio })
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return;
    }
    else {
      this.activarSpinner(false);
      this.actualizarTabla();

    }
  }

  restablecerUsuario = async (ban) => {

    let json = {
      "idcatalogousuarios": ban.idcatalogousuarios
    }
    this.activarSpinner(true);
    var resp = await setRestablecerUsuario(json);
    if (resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      this.activarSpinner(false);
      return;
    }
    else if (resp.code == 200 || resp.code == 300) {
      this.activarSpinner(false);
      this.setState({ confirmarenvio: !this.state.confirmarenvio })
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return;
    } else {
      this.activarSpinner(false);
    }

  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo usuario', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar usuario', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {

    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      toast.success("Usuario asignado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Usuario editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }

  }

  filtro = (pagenumber, array) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        listaelementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  cambiarContenido = (pagenumber) => {

    this.setState({
      activepage: pagenumber,
      listapaginada: [],
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.listaelementosfiltrados),
      });
    }, 0);

  }


  activarprincipal = async (item) => {

    var tabla = this.state.tabla;
    var tabla2 = tabla.elementos;

    for (let i = 0; i < tabla2.length; i++) {
      if (item.idcatalogo_ranchos_usuario == tabla2[i].idcatalogo_ranchos_usuario) {
        if (tabla2[i].principal == "1") {
          tabla2[i].principal = "0";
        }
        else {
          tabla2[i].principal = "1";
        }
      }
      else {
        tabla2[i].principal = "0";
      }
    }

    tabla.elementos = tabla2
    this.setState({ tabla: tabla })

  }

  eliminardatos = async (item, bandera) => {


    var json = {
      "idcatalogo_ranchos_usuario": item.idcatalogo_ranchos_usuario
    }


    this.setState({
      elegirItems: item,
      jsoneliminar: json,
      titulomodal: "Aviso",
      nombreusuaremodal: item.nombre + " " + item.apellido,
      mensajeasignar: "Estas seguro de eliminar a ",
      banderamodaleditar: bandera,
      vermodaleliminar: !this.state.vermodaleliminar
    })

  }

  continuar_eliminar_datos = async (item, json) => {

    this.setState({ banderamodaleditar: "", vermodaleliminar: !this.state.vermodaleliminar })
    if (item == 1) {

      this.setState({ loadingspinners: true })
      var resp = await EliminarAsignarusuario(this.state.jsoneliminar);

      if(resp.code == 200){
        this.actualizarTabla();
        toast.success("Se borró el usuario exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      else if(resp.code == 300){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 400){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if (resp.code == 1000) {
        this.setLoadingSpinner(false);
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }

    }
    else if (item == 2) {

      //this.setState({ loadingspinners: false })

      //var resp = await EliminarAsignarusuario(this.state.jsoneliminar);


      var jsonmandar = {
        "idcatalogo_ranchos_usuario": this.state.elegirItems.idcatalogo_ranchos_usuario,
        "idcatalogo_rancho": json.idcatalogo_rancho,
        "idusuario": this.state.elegirItems.idusuario
      }



      var resp = await EditarAsignarusuario(jsonmandar);
      if (this.manejadorLlamadasApis(resp)) {
        return;
      }
      this.actualizarTabla();

    }
  }


  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  borrarBusqueda = () => {
    this.setState({
      txtbuscador: '',
    })
    this.filtrarArea(this.state.areaseleccionado, 1)
    this.cambiarContenido(1);
    this.setState({ filtrolistapaginada: [] });
  }




  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalAsignarUsuario titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus}
            show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            activarSpinner={this.activarSpinner}
            listausuarios={JSON.parse(JSON.stringify(this.state.listaDatosGenerales))}
            listaarea={JSON.parse(JSON.stringify(this.state.listaarea))}
            listapuesto={JSON.parse(JSON.stringify(this.state.listapuesto))}
            listazonas={JSON.parse(JSON.stringify(this.state.listazonas))}
            listaranchos={JSON.parse(JSON.stringify(this.state.listaranchosModal))}

          />
        ) : null}


        {(this.state.vermodaleliminar == true) ? (
          <Modalconfirmaasignar titulomodal={this.state.titulomodal} cerrarModal={this.continuar_eliminar_datos}
            show={this.state.vermodaleliminar} mensaje={this.state.mensajeasignar} nombre={this.state.nombreusuaremodal}
            bandera={this.state.banderamodaleditar}
            
            elegirItemsV = {JSON.parse(JSON.stringify(this.state.elegirItems))}
            listaranchosV={JSON.parse(JSON.stringify(this.state.listaranchosModal))}
            listazonasV={JSON.parse(JSON.stringify(this.state.listazonas))}
          />
        ) : null}


        <Navbar />

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Asignar Usuario</label>
              </div>


              {/* ////////////////////   Filtro zonas ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divComboas' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                  <div className="selectBox ">
                    <select id='zonas' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                      {this.state.txtselectedzona == 0 ? <option selected="true" value='0'>Todas</option> : <option value='0'>Todas</option>}
                      {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((item, index) => {
                        return (<option key={"z_" + index} value={item.idcatalogo_zona}>{item.zona}</option>);
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro ranchos ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divComboas' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Rancho</label>
                  <div className="selectBox ">
                    <select id='ranchos' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                      {this.state.txtselectedrancho == 0 ? <option selected="true" value='0'>Todos</option> : <option value='0'>Todos</option>}
                      {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((item, index) => {
                        return (item.idcatalogo_zona == this.state.txtselectedzona || this.state.txtselectedzona == 0 ? <option key={"r_" + index} value={item.idcatalogo_rancho}>{item.rancho}</option> : null);
                      })}
                    </select>
                  </div>
                </div>
              </div>


              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar Usuario"></input>
                  {this.state.txtbuscador ? (<IoMdClose id='CSearch' className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.saveInputLocacion} />) : null}
                </div>
              </div>


              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine />&nbsp;Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>Agregar</button>
              </div>

            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximoTabCultivo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (


                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{
                          minWidth: this.state.tabla.mindimensiones[index],
                          width: this.state.tabla.dimensiones[index],
                          color: this.state.colorencabezadostabla,
                          textAlign: index == 6 || index == 4 ? "center" : "start"
                        }}>
                        {titulo}
                      </th>

                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 && this.state.txtselectedzona == 0 && this.state.txtselectedrancho == 0 ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index} style={{ backgroundColor: item.principal == "0" ? "white" : "#ececec" }}>


                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.nombre} {item.apellido}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.zona}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                          <CiEdit className='icoeditarclass' onClick={this.eliminardatos.bind(this, item, "combo")} />  {item.rancho}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }}>
                          {item.estatus}
                        </td>

                      

                        <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >
                          <button className='btnEliminar' onClick={this.eliminardatos.bind(this, item)} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BsTrash className="icoBotones" /> Eliminar</button>
                        </td>


                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index} style={{ backgroundColor: item.principal == "0" ? "white" : "#ececec" }}>


                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.nombre} {item.apellido}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.zona}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                          <CiEdit className='icoeditarclass' onClick={this.eliminardatos.bind(this, item, "combo")} />  {item.rancho}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }}>
                          {item.estatus}
                        </td>

                      

                        <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >
                          <button className='btnEliminar' onClick={this.eliminardatos.bind(this, item)} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BsTrash className="icoBotones" /> Eliminar</button>
                        </td>


                      </tr>

                    ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}


        {/* ////////////////////   Inicio Sección Paginacion   ////////////////////////////////////// */}
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}

            totalitemscount={this.state.txtbuscador.length < 3
              && this.state.txtselectedzona == 0
              && this.state.txtselectedrancho == 0
              ? this.state.tabla.elementos : this.state.listaelementosfiltrados}

            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
        {/* ////////////////////   Fin Sección Paginacion   ////////////////////////////////////// */}
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}