import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import { BsListUl } from "react-icons/bs";
import { RiRefreshLine } from 'react-icons/ri';
import { Button, Table } from 'reactstrap';
import { estiloBtnActualizar, estiloBtnEditar, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalRelacionNivelesAutorizacion from '../../Complements/Modales/ModalNivelesAutorizacion/ModalRelacionNivelesAutorizacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getConfiguraciones } from '../../Services/Api';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { formatoNumero2 } from '../../Services/Validaciones'; 
import ModalContrasenaConfiguraciones from './ModalContrasenaConfiguraciones';


export default class Configuraciones extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            salir: false,
            altura: window.innerHeight,
            anchura: window.innerWidth,
            loadingspinners: true,

            listaareastrabajo: [],
            respconfigs: [],
            configuraciones: [],
            autorizado:0,

            tabla: {
                encabezados: [
                    "Nivel de autorización",
                    "Puestos",
                    "Descripción"],
                dimensiones: [
                    "30%",
                    "20%",
                    "30%"
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px"
                ],
                elementos: []
            },
            accionmodal: 1,
            titulomodal: '',
            modaldetalles: false,
            itemseleccionado: null,
            errordeacceso: false,


            modalaccionestatuscontrasena:false,



            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortitulo: estiloFiltroEncabezados().colortitulos,

            colorencabezadostabla: estiloTabla().colorencabezadostabla,
            colorcontenidotabla: estiloTabla().colorcontenidotabla,

            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

            btnfondoeditar: estiloBtnEditar().btnfondoeditar,
            btncoloreditar: estiloBtnEditar().btncoloreditar,
            btnbordereditar: estiloBtnEditar().btnbordereditar,

        }
    }

    async componentDidMount() {

        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
            return;
        }

        if (!this.state.loadingspinners) {
            this.setState({ loadingspinners: true });
        }

        var respconfigs = await getConfiguraciones()        
      
        if (this.manejadorLlamadasApis(respconfigs)) {
            return;
        }

        this.setState({
            configuraciones: respconfigs.data.mandos,
            autorizado: respconfigs.data.autorizado,
        });

        this.dimensiones();
        window.addEventListener("resize", this.dimensiones);
        this.setState({ loadingspinners: false })

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

  
    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }

   
 


    guardarCambios = () => {

       

        let agroquimicos = []
        let generales = []
        let compras_dir = []
        let servicios = []
 /*let lista = this.state.configuraciones
        lista.forEach(el => {

            el.limites.forEach(limit => {

                if (limit.idconfiguracion_limite_presupuesto_requisicion == 2
                    || limit.idconfiguracion_limite_presupuesto_requisicion == 4) {
                    delete limit.limite
                    agroquimicos.push(limit)
                } else if (limit.idconfiguracion_limite_presupuesto_requisicion == 6
                    || limit.idconfiguracion_limite_presupuesto_requisicion == 8) {
                    delete limit.limite
                    generales.push(limit)
                } else if (limit.idconfiguracion_limite_presupuesto_requisicion == 9
                    || limit.idconfiguracion_limite_presupuesto_requisicion == 10) {
                    delete limit.limite
                    compras_dir.push(limit)
                } else if (limit.idconfiguracion_limite_presupuesto_requisicion == 12
                    || limit.idconfiguracion_limite_presupuesto_requisicion == 13) {
                    delete limit.limite
                    servicios.push(limit)
                }

            });

        });*/

        let lista = []

        this.state.configuraciones.forEach(el => {
            el.limites.forEach(sub => {
                lista.push(sub)
            });
        });



        let json = {
            "password": "",
            "limites": lista            
        }


       this.verModalFormularioAccionEstatusContrasena(json)

    }



    cambiarLimite = (obj) => {

        let limites = this.state.configuraciones
        let lista = []

        limites.forEach(el => {
            el.limites.forEach(sub => {
                lista.push(sub)
            });
        });

        //verificar los límites 
        lista.forEach(sub => {

            if (sub.idconfiguracion_limite_presupuesto_requisicion == obj.limite) {
                sub[obj.pos] = obj.val
            }



            if (obj.limite == 2 && sub.idconfiguracion_limite_presupuesto_requisicion == 4) {
                sub.presupuesto_minimo = obj.val
            }

            if (obj.limite == 4 && sub.idconfiguracion_limite_presupuesto_requisicion == 2) {
                sub.presupuesto_maximo = obj.val
            }

            if (obj.limite == 6 && sub.idconfiguracion_limite_presupuesto_requisicion == 8) {
                sub.presupuesto_minimo = obj.val
            }

            if (obj.limite == 8 && sub.idconfiguracion_limite_presupuesto_requisicion == 6) {
                sub.presupuesto_maximo = obj.val
            }

            if (obj.limite == 9 && sub.idconfiguracion_limite_presupuesto_requisicion == 10) {
                sub.presupuesto_minimo = obj.val
            }

            if (obj.limite == 10 && sub.idconfiguracion_limite_presupuesto_requisicion == 9) {
                sub.presupuesto_maximo = obj.val
            }

            if (obj.limite == 12 && sub.idconfiguracion_limite_presupuesto_requisicion == 13) {
                sub.presupuesto_minimo = obj.val
            }

            if (obj.limite == 13 && sub.idconfiguracion_limite_presupuesto_requisicion == 12) {
                sub.presupuesto_maximo = obj.val
            }


        });

        limites.forEach(el => {
            el.limites.forEach(sub => {
                let x = lista.find(x => x.idconfiguracion_limite_presupuesto_requisicion == sub.idconfiguracion_limite_presupuesto_requisicion)

                if (x) {
                    sub.presupuesto_maximo = x.presupuesto_maximo
                    sub.presupuesto_minimo = x.presupuesto_minimo
                }
            });
        });



        this.setState({ configuraciones: limites })

    }








    verModalFormularioAccionEstatusContrasena = (json) => {
       
          this.setState({ 
            modalaccionestatuscontrasena: true, 
            jsonguardar: json });
    
      }




      cerrarModalContrasena = (accion) => {
        this.setState({ modalaccionestatuscontrasena: false})
        if (accion == 1) {
            toast.success("Configuraciones guardadas correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.componentDidMount();
        }
       
    }























refrescar = () =>{
    this.setState({ loadingspinners: true })

    setTimeout(() => {
         this.componentDidMount()
    }, 300);
   
}































    render() {
        return (

            <>
                {this.state.salir ?
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <AccionesVista bandera={'salir'} />
                    </>
                    : null}

                <ToastContainer />

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}


                <Navbar />
                <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                    <div className="container-fluid anchoMaximo">

                        <div className='row paddingEncabezados align-items-end'>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 containerSubTabla paddingBottomEncabezados'>
                                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Configuración Límites de presupuesto</label>
                            </div>

                           <div className='col-auto offset-lg-4 offset-xl-5 containerSubTabla paddingBottomEncabezados'>
                                <button className='btnActualizar letraNormal'
                                    style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                    onClick={()=>this.refrescar()}><RiRefreshLine /> Refrescar vista</button>
                            </div>

                        </div>
                    </div>
                </div>


                   {(this.state.modalaccionestatuscontrasena == true) ? (
                          <ModalContrasenaConfiguraciones 
                          titulomodal={"Guardar Configuración"} 
                          cerrarModal={this.cerrarModalContrasena} 
                        
                            show={this.state.modalaccionestatuscontrasena} 
                            item={this.state.jsonguardar} />
                        ) : null}


                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />

                <div className="container-fluid containerTabla">
                    <div className="container-fluid anchoMaximo ">

                    {this.state.autorizado==0 ? <div style={{textAlign:"center"}}>No autorizado</div>: null}

                        {/* item x */}

                        <div style={{ display: "flex" }}>
                            {this.state.configuraciones.map((item, index) => (
                                <div className="containerSubTabla col-6" style={{ paddingRight: "40px", textTransform: "uppercase" }} >
                                    <div className='' style={{ textAlign: "center", fontWeight: "bold" }}>
                                        {item.mando}
                                    </div>
                                    <br />

                                    {/* subitem x */}

                                    {
                                        item.limites.map((sub, idx) => (
                                            <>
                                                <br />
                                                <div className='' style={{ textAlign: "center" }}>
                                                    {sub.limite}
                                                </div>
                                                <Table >
                                                    <tbody className='' style={{ textAlign: "center", fontWeight: "bold" }}>
                                                        <tr>
                                                            <td className='col-6' >
                                                                <p>Min</p>
                                                                {sub.idconfiguracion_limite_presupuesto_requisicion == 2
                                                                    || sub.idconfiguracion_limite_presupuesto_requisicion == 6
                                                                    || sub.idconfiguracion_limite_presupuesto_requisicion == 9
                                                                    || sub.idconfiguracion_limite_presupuesto_requisicion == 12 
                                                                    || this.state.autorizado==0 ?
                                                                    "$ " + formatoNumero2(sub.presupuesto_minimo)
                                                                    :
                                                                    <div style={{ display: "flex" }}>

                                                                        <label style={{
                                                                            width: "20px", height: "29.56px",
                                                                            background: "#dadada", borderRadius: "5px", borderTopRightRadius: "0px",
                                                                            borderBottomRightRadius: "0px", margin: "auto", marginRight: "0px"
                                                                        }}>$</label>
                                                                        <input placeholder="10.00" value={sub.presupuesto_minimo} style={{
                                                                            textAlign: "center",
                                                                            margin: "auto", marginLeft: "0px"
                                                                        }} type="number"
                                                                            onChange={(e) => this.cambiarLimite({
                                                                                val: e.target.value,
                                                                                idmando: item.idmando,
                                                                                limite: sub.idconfiguracion_limite_presupuesto_requisicion,
                                                                                pos: "presupuesto_minimo"
                                                                            })} />
                                                                    </div>



                                                                }
                                                            </td>
                                                            <td className='col-6'>
                                                                <p>Max</p>
                                                                {sub.idconfiguracion_limite_presupuesto_requisicion == 4
                                                                    || sub.idconfiguracion_limite_presupuesto_requisicion == 8
                                                                    || sub.idconfiguracion_limite_presupuesto_requisicion == 10
                                                                    || sub.idconfiguracion_limite_presupuesto_requisicion == 13 
                                                                    || this.state.autorizado==0 ?
                                                                    "$ " + formatoNumero2(sub.presupuesto_maximo)
                                                                    :
                                                                    <div style={{ display: "flex" }}>

                                                                        <label style={{
                                                                            width: "20px", height: "29.56px",
                                                                            background: "#dadada", borderRadius: "5px", borderTopRightRadius: "0px",
                                                                            borderBottomRightRadius: "0px", margin: "auto", marginRight: "0px"
                                                                        }}>$</label>
                                                                        <input placeholder="10.00" value={sub.presupuesto_maximo} style={{
                                                                            textAlign: "center",
                                                                            margin: "auto", marginLeft: "0px"
                                                                        }}
                                                                            type="number"
                                                                            onChange={(e) => this.cambiarLimite({
                                                                                val: e.target.value,
                                                                                idmando: item.idmando,
                                                                                limite: sub.idconfiguracion_limite_presupuesto_requisicion,
                                                                                pos: "presupuesto_maximo"
                                                                            })} />
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </>

                                        ))

                                    }


                                </div>

                            ))}

                        </div>



                    </div>
                </div>

                <br />
                <div className="container-fluid containerTabla">
                    <div className="container-fluid anchoMaximo ">
                        <div className="containerSubTabla">

                            {this.state.autorizado==1 ?
                            <>
                            <div className="divBtnContenedorRequisicionOT" >
                                <Button className="btnRechazar" onClick={() => this.cancelarFormulario()}>Limpiar</Button>
                                <Button className="btnAceptar" onClick={() => this.guardarCambios()}>Guardar</Button>
                            </div>
                            <br />
                            <br />
                            </>
                        :null}
                            
                        </div>

                    </div>
                </div>
                <br />
               

            </>
        )
    }
}

function AccionesVista({ bandera }) {
    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }
}
