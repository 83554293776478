import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoESCuadrillas.css';
import { BiEdit } from "react-icons/bi";
import { FaCalendar } from "react-icons/fa";
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, postListaBitacoraCuadrillas, getListaCultivosDisponibles, getListaZonas, getListaRanchosDisponibles } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalFormularioNuevoESCuadrillas from '../../Complements/Modales/ModalFormularioESCuadrillas/ModalFormularioNuevoESCuadrillas';
import { BsFillCalendarFill } from "react-icons/bs";
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

export default class CatalogoBitacoraCuadrillas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      altura: window.innerHeight,
      anchura: window.innerWidth,

      salir: false,
      loadingspinners: false,
      errordeacceso: false,

      txtselectedzona: '0',
      txtselectedrancho: '0',

      listapaginada: [],
      filtrolistapaginada: [],
      elementosfiltrados: [],
      listazonas: [],
      listaranchos: [],
      listatiposuelo: [],
      listacultivo: [],
      estatus: [],

      tabla: {
        encabezados: [
          "Fecha",
          "Zona",
          "Rancho",
          "Actividades",
          "Cuadrillas",
          "",
          "",
        ],
        dimensiones: [
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "10%",
          "12%"
        ],
        mindimensiones: [
          "130px",
          "130px",
          "100px",
          "100px",
          "150px",
          "120px",
          "150px"
        ],
        elementos: []
      },

      fechabusquedainicio: new Date(),
      fechabusquedafin: new Date(),
      txtfechabusquedainicio: "",
      txtfechabusquedafin: "",

      accionmodal: 1,
      modalnuevocatcuadrilla: false,
      titulomodal: '',
      itemseleccionado: null,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,

      redireccionando: false,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true });
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    //obtiene catalogo de zonas
    var respzonas = await getListaZonas();
    if (this.manejadorLlamadasApis(respzonas)) {
      return;
    }

    //obtiene catalogo de ranchos
    var respranchos = await getListaRanchosDisponibles();
    if (this.manejadorLlamadasApis(respranchos)) {
      return;
    }

    //obtiene catalogo de cultivos
    var respcultivo = await getListaCultivosDisponibles();
    if (this.manejadorLlamadasApis(respcultivo)) {
      return;
    }

    var fechaactualFin = new Date();
    const fechaa = fechaactualFin.getDate();
    fechaactualFin.setDate(fechaa);
    const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

    var fechaactualIni = new Date();
    fechaactualIni.setDate(fechaactualIni.getDate() - 7);
    const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');

    await this.setState({
      estatus: estatus.data,
      listacultivo: respcultivo.data,
      listazonas: respzonas.data,
      listaranchos: respranchos.data,

      txtfechabusquedainicio: defaultValueFechaInicial,
      txtfechabusquedafin: defaultValue,
      fechabusquedainicio: fechaactualIni,
    })

    

    setTimeout(() => {
      window.addEventListener("resize", this.dimensiones);
    this.dimensiones();
   this.consultarCatalogoBitacoraCuadrillas()
   this.setState({ loadingspinners: false })

      }, 300);

    

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ txtselectedzona: '0' })
    this.setState({ txtselectedrancho: '0' })
    this.setState({ errordeacceso: false })
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ txtselectedzona: '0' })
    this.setState({ txtselectedrancho: '0' })
    this.setState({ errordeacceso: false })
    this.setState({ loadingspinners: bandera });
  }

  //Funcion para filtrar por los select
  saveInputLocacion = async (e) => {

    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtrozonas = [];
    var filtroranchos = [];

    if (e.target.id == 'zonas') {
      this.setState({ txtselectedzona: e.target.value });
      this.setState({ txtselectedrancho: 0 })
      if (e.target.value == 0) {
        filtrozonas = data;
      } else {
        filtrozonas = data.filter((item) => item.idcatalogo_zona == e.target.value)
      }
      if (this.state.txtselectedrancho == 0) {
        filtroranchos = filtrozonas;
      } else {
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho == this.state.txtselectedrancho)
      }

    } else if (e.target.id == 'ranchos') {
      this.setState({ txtselectedrancho: e.target.value });
      if (this.state.txtselectedzona == 0) {
        filtrozonas = data;
      } else {
        filtrozonas = data.filter((item) => item.idcatalogo_zona == this.state.txtselectedzona)
      }
      if (e.target.value == 0) {
        filtroranchos = filtrozonas;
      } else {
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho == e.target.value)
      }

    }

    if (filtrozonas.length < 1 || filtroranchos.length < 1) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.filtro(1, [])
    }
    if (e.target.id == 'zonas') {
      this.filtro(1, filtrozonas)
    }
    else if (e.target.id == 'ranchos') {
      this.filtro(1, filtroranchos)
    }
  }

  verModal = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nueva bitácora de cuadrilla', accionmodal: 1, modalnuevocatcuadrilla: true })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar bitácora  de cuadrilla', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)), modalnuevocatcuadrilla: true });
    }

  }

  cerrarModal = (accion) => {

    this.setState({ modalnuevocatcuadrilla: !this.state.modalnuevocatcuadrilla })
    if (accion == 1) {
      toast.success("Bitácora creada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Bitácora editada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }

  }

  filtro = (pagenumber, array) => {

    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  cambiarContenido = (pagenumber) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: [],
      listapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);

  }

  cambiarFecha = (item) => {
    this.setState({ [item.llave]: item.valor })

    let day = `0${item.valor.getDate()}`.slice(-2)
    let month = `0${item.valor.getMonth() + 1}`.slice(-2)
    let year = item.valor.getFullYear()

    this.consultarCatalogoBitacoraCuadrillas(['txt'+item.llave],year + '-' + month + '-' + day);
    this.setState({ txtselectedzona: 0, txtselectedrancho: 0 });
  }

  consultarCatalogoBitacoraCuadrillas = async (llave, valor) => {

    var formatofechaini = "";
    var formatofechafin = "";
    var fechainiaux = 'txtfechabusquedainicio' == llave ? valor : this.state.txtfechabusquedainicio
    var fechafinaux = 'txtfechabusquedafin' == llave ? valor : this.state.txtfechabusquedafin

    if (fechainiaux.length > 0) {
      var [year, month, day] = fechainiaux.split("-");
      formatofechaini = `${year}-${month}-${day}`
    }

    if (fechafinaux.length > 0) {
      var [year, month, day] = fechafinaux.split("-");
      formatofechafin = `${year}-${month}-${day}`
    }

    var requestbody = {
      fechainicio: formatofechaini,
      fechafin: formatofechafin,
    }

    this.setState({ loadingspinners: true })
    var resp = await postListaBitacoraCuadrillas(requestbody);
    if (this.manejadorLlamadasApis(resp)) {
      this.setState({ loadingspinners: false })
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = resp.data;

    await this.setState({
      tabla: tabla
    })
    this.cambiarContenido(1);
    this.setState({ loadingspinners: false })
  }

  redireccionar = (item) => {
    localStorage.setItem("dato", JSON.stringify({ idcuad: item }))
    this.setState({ redireccionando: true })
  }

  render() {
    return (
      <>
        {/*///////// Sección de modales, spinner y redireccionamiento ////////*/}
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionando) ?
          <Acciones bandera={"verDetalles"} />
          : null}

        <ToastContainer />
        {(this.state.loadingspinners == true) ?
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
          : null}

        {(this.state.modalnuevocatcuadrilla) ?
          <ModalFormularioNuevoESCuadrillas
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModal}
            estatus={this.state.estatus}
            show={this.state.modalnuevocatcuadrilla}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            listazonas={this.state.listazonas}
            listaranchos={this.state.listaranchos}
            listacultivos={this.state.listacultivo}
            listatipocultivos={this.state.listatipocultivo}
            listatiposuelos={this.state.listatiposuelo}
            setLoadingSpinner={this.setLoadingSpinner} />
          : null}

        {/*///////// Sección de navbar ////////*/}
        <Navbar />

        {/*///////// Sección de encabezado ////////*/}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">
            <div className='row paddingEncabezados align-items-end'>

              {/*///////// Título ////////*/}
              <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Bitácora de cuadrillas</label>
              </div>

             

              {/* ////////////////////   Filtro de fechas   ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='' >
                  <div className='row contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar, paddingRight: "5px" }}>

                    {/* ////////////////////   Fecha inicio   ////////////////////////////////////// */}
                    <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                      <label className='lblTexto letraTitulos ' style={{ color: this.state.colortipolbl, paddingLeft: "8px" }}>Fecha de:</label>
                    </div>
                    <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                      <DatePicker
                        selected={this.state.fechabusquedainicio}
                        onChange={(date) => this.cambiarFecha({ llave: "fechabusquedainicio", valor: date })}
                        dateFormat="dd/MM/yyyy"
                        locale="es"
                        customInput={
                          <DateButton />
                        }
                      />
                    </div>

                    {/* ////////////////////   Fecha fin   ////////////////////////////////////// */}
                    <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, textAlign: 'center' }}>a:</label>
                    </div>
                    <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                      <DatePicker
                        selected={this.state.fechabusquedafin}
                        onChange={(date) => this.cambiarFecha({ llave: "fechabusquedafin", valor: date })}
                        dateFormat="dd/MM/yyyy"
                        locale="es"
                        customInput={
                          <DateButton />
                        }
                      />
                    </div>

                  </div>
                </div>
              </div>









































              {/* ////////////////////   Filtro zonas ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                  <div className="selectBox ">
                    <select id='zonas' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                      {this.state.txtselectedzona == 0 ?
                        <option selected="true" value='0'>Todas</option>
                        :
                        <option value='0'>Todas</option>
                      }
                      {this.state.listazonas == undefined || this.state.listazonas.length == 0 ?
                        null
                        :
                        this.state.listazonas.map((item, index) => {
                          return (<option key={"z_" + index} value={item.idcatalogo_zona}>{item.zona}</option>);
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro ranchos ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Rancho</label>
                  <div className="selectBox ">
                    <select id='ranchos' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                      {this.state.txtselectedrancho == 0 ?
                        <option selected="true" value='0'>Todos</option>
                        :
                        <option value='0'>Todos</option>
                      }
                      {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ?
                        null
                        :
                        this.state.listaranchos.map((item, index) => {
                          return (item.idcatalogo_zona == this.state.txtselectedzona || this.state.txtselectedzona == 0 ? <option key={"r_" + index} value={item.idcatalogo_rancho}>{item.rancho}</option> : null);
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Botón refrescar lista  ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              {/* ////////////////////   Botón agregar nuevo registro  ////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.verModal.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>
        <br />

        {/* ////////////////////   Sección de tabla   ///////////////////////////////// */}
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximo2 ">
            <div className="containerSubTabla">

              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {this.state.txtselectedzona == 0 && this.state.txtselectedrancho == 0 ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.fecha}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.zona}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.actividades}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.cuadrillas}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button
                            className='btnEditar'
                            onClick={this.verModal.bind(this, { opcion: "2", contenido: item })}
                            style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar }}
                          >
                            <BiEdit className="icoBotones" /> Editar
                          </button>
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button
                            className='btnEditar'
                            onClick={this.redireccionar.bind(this, item.idcatalogo_bitacora_cuadrillas)}
                            style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar }}
                          >
                            Ver detalles
                          </button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.fecha}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.zona}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.actividades}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.cuadrillas}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button
                            className='btnEditar'
                            onClick={this.verModal.bind(this, { opcion: "2", contenido: item })}
                            style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar }}
                          >
                            <BiEdit className="icoBotones" /> Editar
                          </button>
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button
                            className='btnEditar'
                            onClick={this.redireccionar.bind(this, item.idcatalogo_bitacora_cuadrillas)}
                            style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar }}
                          >
                            Ver detalles
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        {/* ////////////////////   Sección de paginación   ///////////////////////////////// */}
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtselectedzona == 0 && this.state.txtselectedrancho == 0 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

{/* ////////////////////  Función de redireccionamiento   ///////////////////////////////// */ }
function Acciones({ bandera }) {
  const history = useHistory();
  const location = useLocation();
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
  else if (bandera == "verDetalles") {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/detallesbitacoracuadrillas"} />)
  }
}

{/* ////////////////////   Botón de filtro por fecha   ///////////////////////////////// */ }
const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
  </button>
));