import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default class ExportarCSVreporteNominas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            arraycsv: [],
            totalitems: 0,
            title: ""
        }

    }

    componentDidMount() {
        
        var arraycsv = this.props.arraycsv;
        this.setState({ totalitems: this.props.totalitems })
        var title2 = arraycsv[0]+ " - "+  arraycsv[3].split(",")[0] + " a "+ arraycsv[3].split(",")[1] + ".csv"
        var array_secundario = [];
        var array_datosr = [];

        for (let j = 0; j < arraycsv.length; j++) {
            array_secundario = [arraycsv[j]]
            array_datosr.push(array_secundario);
            array_secundario = [];
        }
        this.setState({ title: title2 })
        this.setState({ arraycsv: array_datosr })

    }


    render() {
        return (
            <>
                <ToastContainer />
                {this.state.arraycsv.length > 0 ?
                    <CSVLink data={this.state.arraycsv} filename={this.state.title} enclosingCharacter={``} separator={","} style={{ margin: 'auto' }} >
                        <RiFileExcel2Fill className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px", margin: 'auto' }} />
                    </CSVLink>
                    :
                    <RiFileExcel2Fill className="consulta-archivos-excel" style={{ color: "#1f6e43", fontSize: "30px", margin: 'auto' }} />
                }

            </>
        )
    }
}

