import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoTablaCultivo.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalFormularioTablaCultivo from '../../Complements/Modales/ModalFormularioTablaCultivo/ModalFormularioTablaCultivo';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaCatalogoTablaCultivos, getListaTipoSueloDisponibles, getListaZonas,getListaRanchosDisponibles,getListaLotes,getListaEstatusSiembra } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { formatoNumero2 } from '../../Services/Validaciones';


export default class CatalogoTablaCultivo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      loadingspinners: true,
      errordeacceso:false,
      tabla: {
        encabezados: [
          "Nombre tabla",
          "Zona",
          "Rancho",
          "Lote",
          
          "Descripción",
          "Estatus de siembra",
          "Área total (ha)",
          "Área efectiva (ha)",
          "Área no efectiva (ha)",
          "Plantas totales (ud)",
          
          "Desarrollo (ud)",
          "Productivas (ud)",
          "Fallas (ud)",
          "Estatus",
          ""],
        dimensiones: [
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "150px"
        ],
        mindimensiones: [
          "130px",
          "80px",
          "75px",
          "60px",
          "100px",
          "178px",
          "138px",
          "170px",
          "200px",
          "182px",
          "142px",
          "155px",
          "105px",
          "75px",
          "122px",

        ],
        elementos: []
      },
      estatus: [],
      listaelementosfiltrados: [],
      tipoplagaseleccionado: "TodosLosTiposDePlagas",
      zonaseleccionado: "TodaLaZona",
      ranchoseleccionado: "TodosLosRanchos",
      loteseleccionado: "TodasLasLotes",
      listatipoplagas:[],
      listazona:[],
      listarancho:[],
      listafiltrorancho:[],
      listalote:[],
      listatiposuelo: [],
      listafiltrolote:[],
      listaestatussiembra:[],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var resplistazonas = await getListaZonas();
    if ( this.manejadorLlamadasApis (resplistazonas) ) {
      return;
    }

    var resplistaranchos = await getListaRanchosDisponibles();
    if ( this.manejadorLlamadasApis (resplistaranchos) ) {
      return;
    }
    
    var resplistalotes = await getListaLotes();
    if ( this.manejadorLlamadasApis (resplistalotes) ) {
      return;
    }

    var listaestatussiembra = await getListaEstatusSiembra();
    if ( this.manejadorLlamadasApis (listaestatussiembra) ) {
      return;
    }
    
    
    var respcatalogotablacultivos = await getListaCatalogoTablaCultivos();
    if ( this.manejadorLlamadasApis (respcatalogotablacultivos) ) {
      return;
    }

     //obtiene catalogo de tipo suelo
     var resptiposuelo = await getListaTipoSueloDisponibles();
     if ( this.manejadorLlamadasApis (resptiposuelo) ) {
       return;
     }
    
    var estatus = await getEstatus();
    if ( this.manejadorLlamadasApis (estatus) ) {
      return;
    }
    
    var tabla = this.state.tabla;
    tabla.elementos = respcatalogotablacultivos.data;
    await this.setState({ 
        txtbuscador: '',
        tabla: tabla,
        estatus: estatus.data,
        listazona: resplistazonas.data,
        listarancho: resplistaranchos.data,
        listafiltrorancho: resplistaranchos.data,
        listalote: resplistalotes.data,
        listatiposuelo: resptiposuelo.data, 
        listafiltrolote: resplistalotes.data,
        listaestatussiembra: listaestatussiembra.data
    })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }


  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ 
      loadingspinners: true, 
      txtbuscador: '',
      zonaseleccionado: "TodaLaZona",
      ranchoseleccionado: "TodosLosRanchos",
      loteseleccionado: "TodasLasLotes",
      errordeacceso:false
    })
    this.componentDidMount();
  }

  cambiarValor = () => {
    //console.log("AAA");
  }


filtrarZona = async (itemfiltrado) => {
  var data1 = JSON.parse(JSON.stringify(this.state.tabla))
  var data = data1.elementos;
 
  await this.setState({ 
    zonaseleccionado: itemfiltrado 
  })

  var filtrozona = [];
  var filtrorancho = [];
  var filtrolote = [];
  var filtrofinal = [];
  
  filtrozona = data;
  if(itemfiltrado != "TodaLaZona"){

    var resplistaranchos = this.state.listarancho.filter((item) => item.idcatalogo_zona == itemfiltrado)

    await this.setState({ 
      listafiltrorancho: resplistaranchos,
      ranchoseleccionado: "TodosLosRanchos",
      listafiltrolote:[],
      loteseleccionado: "TodasLasLotes"
    })
    filtrozona = data.filter((item) => item.idcatalogo_zona == itemfiltrado)
  }else{
    await this.setState({ 
      listafiltrorancho: this.state.listarancho,
      ranchoseleccionado: "TodosLosRanchos",
      listafiltrolote:this.state.listalote,
      loteseleccionado: "TodasLasLotes"
    })

  }

  if(this.state.ranchoseleccionado != "TodosLosRanchos"){
    filtrorancho = filtrozona.filter((item) => item.idcatalogo_rancho == this.state.ranchoseleccionado)
  }else{filtrorancho = filtrozona;}

  if(this.state.loteseleccionado != "TodasLasLotes"){
    filtrolote = filtrorancho.filter((item) => item.idcatalogo_ranchos_lotes_cultivo == this.state.loteseleccionado)
  }else{filtrolote = filtrorancho;}
  
  if(this.state.txtbuscador.length >= 3){
    filtrofinal = filtrolote.filter((item) => item.tabla.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
  }else{
    filtrofinal = filtrolote;
  }
  
  if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
  }
  this.filtro(1, filtrofinal)
}

filtrarRancho = async (itemfiltrado) => {
  var data1 = JSON.parse(JSON.stringify(this.state.tabla))
  var data = data1.elementos;

  await this.setState({ 
    ranchoseleccionado: itemfiltrado
  })

  var filtrozona = [];
  var filtrorancho = [];
  var filtrolote = [];
  var filtrofinal = [];
  
  filtrorancho = data;
  if(itemfiltrado != "TodosLosRanchos"){

    var resplistalotes = this.state.listalote.filter((item) => item.idcatalogo_rancho == itemfiltrado)
   
    await this.setState({ 
      listafiltrolote: resplistalotes,
      loteseleccionado: "TodasLasLotes"
    })

    filtrorancho = data.filter((item) => item.idcatalogo_rancho == itemfiltrado)
  }else{
    await this.setState({ 
      listafiltrolote: this.state.listalote,
      loteseleccionado: "TodasLasLotes"
    })
  }
  if(this.state.zonaseleccionado != "TodaLaZona"){
    filtrozona = filtrorancho.filter((item) => item.idcatalogo_zona == this.state.zonaseleccionado)
  }else{filtrozona = filtrorancho;}

  if(this.state.loteseleccionado != "TodasLasLotes"){
    filtrolote = filtrozona.filter((item) => item.idcatalogo_ranchos_lotes_cultivo == this.state.loteseleccionado)
  }else{filtrolote = filtrozona;}
  
  if(this.state.txtbuscador.length >= 3){
    filtrofinal = filtrolote.filter((item) => item.tabla.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
  }else{
    filtrofinal = filtrolote;
  }
  
  if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
  }
  this.filtro(1, filtrofinal)

}

filtrarLote = async (itemfiltrado) => {
  var data1 = JSON.parse(JSON.stringify(this.state.tabla))
  var data = data1.elementos;
  await this.setState({ 
    loteseleccionado: itemfiltrado 
  })

  var filtrozona = [];
  var filtrorancho = [];
  var filtrolote = [];
  var filtrofinal = [];
  
  filtrolote = data;
  if(itemfiltrado != "TodasLasLotes"){
    filtrolote = data.filter((item) => item.idcatalogo_ranchos_lotes_cultivo == itemfiltrado)
  }
  if(this.state.zonaseleccionado != "TodaLaZona"){
    filtrozona = filtrolote.filter((item) => item.idcatalogo_zona == this.state.zonaseleccionado)
  }else{filtrozona = filtrolote;}

  if(this.state.ranchoseleccionado != "TodosLosRanchos"){
    filtrorancho = filtrozona.filter((item) => item.idcatalogo_rancho == this.state.ranchoseleccionado)
  }else{filtrorancho = filtrozona;}

  if(this.state.txtbuscador.length >= 3){
    filtrofinal = filtrorancho.filter((item) => item.tabla.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
  }else{
    filtrofinal = filtrorancho;
  }
 
  if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
  }
  this.filtro(1, filtrofinal)
}
  saveInputLocacion = async (e) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = ( tamanocadenast > tamanocadenain ) && ( tamanocadenain < 3 ) && ( tamanocadenast >= 3 )

    this.setState({ txtbuscador: e.target.value });

    var filtrozona = [];
    var filtrorancho = [];
    var filtrolote = [];
    var filtrofinal = [];
    
    

    if (tamanocadenain >= 3 || banderacasosespeciales) {
      filtrozona = data;
      if(this.state.zonaseleccionado != "TodaLaZona"){
        filtrozona = data.filter((item) => item.idcatalogo_zona == this.state.zonaseleccionado)
      }else{filtrozona = data;}
    
      if(this.state.ranchoseleccionado != "TodosLosRanchos"){
        filtrorancho = filtrozona.filter((item) => item.idcatalogo_rancho == this.state.ranchoseleccionado)
      }else{filtrorancho = filtrozona;}
    
      if(this.state.loteseleccionado != "TodasLasLotes"){
        filtrolote = filtrorancho.filter((item) => item.idcatalogo_ranchos_lotes_cultivo == this.state.loteseleccionado)
      }else{filtrolote = filtrorancho;}
      filtrofinal=filtrolote;
      if(e.target.value.toLocaleLowerCase().length >= 3){
        filtrofinal = filtrolote.filter((item) => item.tabla.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
      }
      if (filtrofinal.length <= 0) {
          toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.filtro(1, filtrofinal)
        
    }

  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nueva tabla', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar tabla', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {

    this.setState({ modaldetalles: !this.state.modaldetalles})
    if (accion == 1) {
      toast.success("Tabla creada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Tabla editada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    
  }

  filtro = (pagenumber, array) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        listaelementosfiltrados:array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  cambiarContenido = (pagenumber) => {

    this.setState({
      activepage: pagenumber,
      listapaginada: [],
      filtrolistapaginada:[]
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos ),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.listaelementosfiltrados),
      });
    }, 0);

  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  borrarBusqueda = () => {
    this.setState({ 
        txtbuscador: '',
    })
    this.filtrarZona(this.state.zonaseleccionado)
    this.cambiarContenido(1);
    this.setState({ filtrolistapaginada: [] });
  }


  render() {
    return (

      <>
{this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioTablaCultivo titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus}
            show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado} 
            activarSpinner={this.activarSpinner}
            actualizarTablaAlAgregarOEditar={this.actualizarTablaAlAgregarOEditar} arraytipos={JSON.parse(JSON.stringify(this.state.listatipoplagas))}
            listazona={JSON.parse(JSON.stringify(this.state.listazona))} 
            listarancho={JSON.parse(JSON.stringify(this.state.listarancho))} 
            listatiposuelos={this.state.listatiposuelo}
            listalote={JSON.parse(JSON.stringify(this.state.listalote))} 
            listaestatussiembra={JSON.parse(JSON.stringify(this.state.listaestatussiembra))} 

            />
        ) : null}

        <Navbar />

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className="container-fluid " style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-1 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Tablas</label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                  <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                      <div className="selectBox ">
                          <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                              onChange={(event) => this.filtrarZona(event.target.value)}>
                              {this.state.zonaseleccionado == "TodaLaZona" ?
                               <option selected="true" value='TodaLaZona'>Todos</option> : <option value='TodaLaZona'>Todos</option>}  
                              {this.state.listazona.map((i, index) => (
                                  <option value={i.idcatalogo_zona} key={"tipo_" + index}  >{i.zona} </option>
                              ))}
                          </select>
                      </div>
                  </div>
              </div>
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                  <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Rancho</label>
                      <div className="selectBox ">
                          <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                              onChange={(event) => this.filtrarRancho(event.target.value)}>
                              {this.state.ranchoseleccionado == "TodosLosRanchos" ?
                               <option selected="true" value='TodosLosRanchos'>Todos</option> : <option value='TodosLosRanchos'>Todos</option>}  
                              {this.state.listafiltrorancho.map((i, index) => (
                                  <option value={i.idcatalogo_rancho} key={"tipo_" + index}  >{i.rancho} </option>
                              ))}
                          </select>
                      </div>
                  </div>
              </div>
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                  <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Lote</label>
                      <div className="selectBox ">
                          <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                              onChange={(event) => this.filtrarLote(event.target.value)}>
                              {this.state.loteseleccionado == "TodasLasLotes" ?
                               <option selected="true" value='TodasLasLotes'>Todos</option> : <option value='TodasLasLotes'>Todos</option>}  
                              {this.state.listafiltrolote.map((i, index) => (
                                  <option value={i.idcatalogo_ranchos_lotes_cultivo} key={"tipo_" + index}  >{i.lote} </option>
                              ))}
                          </select>
                      </div>
                  </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar tabla"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>


              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine/>&nbsp;Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximoTabCultivo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 
                  && this.state.zonaseleccionado == 'TodaLaZona' 
                  && this.state.ranchoseleccionado == 'TodosLosRanchos' 
                  && this.state.loteseleccionado == 'TodasLasLotes'?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                         <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tabla}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.zona}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.lote}
                        </td>
                       
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatuscultivo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {formatoNumero2(item.areatotal)}
                        </td> 
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {formatoNumero2(item.areaefectiva)} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right",  color: this.state.colorcontenidotabla }}>
                          {parseFloat(item.areatotal-item.areaefectiva).toFixed(2)}
                        </td>
                        
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right",  color: this.state.colorcontenidotabla }}>
                          {item.plantastotales}
                        </td>
                       
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right",  color: this.state.colorcontenidotabla }}>
                          {item.desarrollo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right",  color: this.state.colorcontenidotabla }}>
                          {item.productivas}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right",  color: this.state.colorcontenidotabla }}>
                          {item.fallas}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                         <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tabla}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.zona}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.lote}
                        </td>
                       
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatuscultivo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {formatoNumero2(item.areatotal)}
                        </td> 
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {formatoNumero2(item.areaefectiva)} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right",  color: this.state.colorcontenidotabla }}>
                          {parseFloat(item.areatotal-item.areaefectiva).toFixed(2)}
                        </td>
                        
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right",  color: this.state.colorcontenidotabla }}>
                          {item.plantastotales}
                        </td>
                       
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right",  color: this.state.colorcontenidotabla }}>
                          {item.desarrollo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right",  color: this.state.colorcontenidotabla }}>
                          {item.productivas}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right",  color: this.state.colorcontenidotabla }}>
                          {item.fallas}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>

                    ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}


        {/* ////////////////////   Inicio Sección Paginacion   ////////////////////////////////////// */}
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}

            totalitemscount={this.state.txtbuscador.length < 3 
              && this.state.zonaseleccionado == 'TodaLaZona' 
              && this.state.ranchoseleccionado == 'TodosLosRanchos' 
              && this.state.loteseleccionado == 'TodasLasLotes' ? this.state.tabla.elementos : this.state.listaelementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
        {/* ////////////////////   Fin Sección Paginacion   ////////////////////////////////////// */}
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}