import React from 'react'
import { FaCalendar } from 'react-icons/fa';
import { Button, Modal, ModalBody, Table } from 'reactstrap';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../Services/PaletaDeColores';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createNuevaNominaSeguridad, getEmpleadosAsistenciaSeguridad, GetEmpleadosSeguridad, guardarEmpleadosAsistenciaSeguridad } from '../../Services/Api';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ModalNominaSeguridadAsistencias.css'
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import ModalEmpleadosSeguridad from './ModalEmpleadosSeguridad';
import { CgClose } from 'react-icons/cg';


export default class ModalNominaSeguridadAsistencias extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            cuerpo: props.cuerpomodal,

            fechacompraini: new Date(),
            fechacomprafin: new Date(),

            tabla: {
                encabezados: [
                    "Empleado"],
                dimensiones: [
                    "20%",
                ],
                mindimensiones: [
                    "200px",

                ],
                elementos: []
            },

            fechainicio: props.fechainicio,
            fechafin: props.fechafin,
            activo: 0,


            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

        }

    }

    componentDidMount = async () => {
        this.props.setLoadingSpinner(true)

        let listaempleados = await GetEmpleadosSeguridad()
        if (this.manejadorLlamadasApis(listaempleados) == true) {
            return true
        }

        let listaasistencia = await getEmpleadosAsistenciaSeguridad({
            "idcatalogo_nominas_seguridad": this.props.idnomina
        })
        if (this.manejadorLlamadasApis(listaasistencia) == true) {
            return true
        }

        let [dia1, mes1, año1] = this.state.fechainicio.split('-');
        let [dia2, mes2, año2] = this.state.fechafin.split('-');
        //let date1 = new Date(año1, mes1 - 1, dia1); 
        //let date2 = new Date(año2, mes2 - 1, dia2);

        let date1 = new Date(dia1, mes1 - 1, año1)
        let date2 = new Date(dia2, mes2 - 1, año2)

        let fechas = [];

        // Agrega las fechas a la lista
        while (date1 <= date2) {
            //fechas.push(`${String(date1.getDate()).padStart(2, '0')}/${String(date1.getMonth() + 1).padStart(2, '0')}/${date1.getFullYear()}`);
            fechas.push(`${date1.getFullYear()}-${String(date1.getMonth() + 1).padStart(2, '0')}-${String(date1.getDate()).padStart(2, '0')}`);
            date1.setDate(date1.getDate() + 1);
        }

        // Muestra la lista de fechas
        let tabla = this.state.tabla

        fechas.forEach(el => {

            if (!tabla.encabezados.find(x => x == el)) {
                tabla.encabezados.push(el)
                tabla.dimensiones.push(80 / fechas.length + "%")
                tabla.mindimensiones.push("100px")
            }

        });

        this.ordenarjson(fechas, listaasistencia.data.asistencias, listaempleados.data)
        this.setState({ tabla: tabla, activo: listaasistencia.data.activo })
        this.props.setLoadingSpinner(false)
    }

    ordenarjson(fechas, asistencias, empleados) {
        let elementos = []
        empleados.forEach((el, idx) => {

            //obtener la lista de dias
            let listadias = []

            this.state.tabla.encabezados.forEach((enc, index) => {
                if (index > 0) {

                    let x = asistencias.find(x => x.idusuario == el.idusuario)
                    let asiste = undefined;
                    if (x != undefined && x != null) {
                        asiste = x.dias.find(xe => xe.fecha == enc && xe.asistencia == 1)
                    }

                    listadias.push({
                        fecha: enc,
                        asistencia: asiste != undefined ? 1 : 0
                    })
                }
            });

            elementos.push({
                idusuario: el.idusuario,
                usuario: el.nombre + " " + el.apellido,
                dias: listadias
            })
        });

        let tabla = this.state.tabla
        tabla.elementos = elementos
        this.setState({ tabla: tabla })

    }

    convertirfechamini(fecha) {
        let [anio, mes, dia] = fecha.split("-")
        return dia + "/" + mes + "/" + anio
    }

    showModal = async (accion) => {

        if (accion != 0) {

            this.props.setLoadingSpinner(true)

            let json = { asistencias: [] }

            let elementos = this.state.tabla.elementos
            elementos.forEach(el => {
                let listadias = []
                el.dias.forEach(d => {
                    listadias.push({
                        "fecha": d.fecha,
                        "asistencia": d.asistencia
                    })
                })

                json.asistencias.push({
                    idusuario: el.idusuario,
                    dias: listadias
                })

            });

            var resp = await guardarEmpleadosAsistenciaSeguridad(json);
            if (this.manejadorLlamadasApis(resp) == true) {
                return true
            }

            toast.success("Asistencias guardadas correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })

            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarmodal(1);
            }, 200);
        } else {
            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarmodal(0);
            }, 200);
        }
    }



    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.setLoadingSpinner(false)
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.props.setLoadingSpinner(false)
            this.setState({ errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.setLoadingSpinner(false)
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.setLoadingSpinner(false)
            return true
        }
        return false
    }


    cambiarAsistencia = (index, fecha) => {
        
        if (parseInt(this.state.activo) == 1) {
            let tabla = this.state.tabla
            tabla.elementos[index].dias.forEach(el => {

                if (el.fecha == fecha)
                    el.asistencia = el.asistencia == 0 ? 1 : 0

            });

            this.setState({ tabla: tabla })
        }
        else{
            toast.info("Nómina finalizada", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }




    }


    editarlistaempleados = (lista) => {

        let tabla = this.state.tabla
        let nuevalista = tabla.elementos
        let listadias = []
        this.state.tabla.encabezados.forEach((x, index) => {
            if (index > 0) {
                listadias.push({
                    fecha: this.convertirfechamini2(x),
                    asistencia: 0,
                    dia: ""
                })
            }
        })

        lista.forEach(el => {
            let x = null

            if (!tabla.elementos.find(x => x.idusuario == el.idusuario)) {
                x = {
                    idusuario: el.idusuario,
                    usuario: el.usuario,
                    dias: listadias
                }
                nuevalista.push(x)
            }
        });

        //console.log("nueva lista", nuevalista)
        tabla.elementos = nuevalista
        this.setState({ tabla: tabla })
    }

    convertirfechamini2 = (fecha) => {
        let [dia, mes, anio] = fecha.split("/")
        return anio + "/" + mes + "/" + dia
    }

    verModalempleados = () => {
        this.setState({ modalempleados: true })
    }

    cerrarModalEmpleados = (lista) => {
        //estruturar la tabla de nuevo
        this.editarlistaempleados(lista)
        this.setState({ modalempleados: false })
    }


    render() {
        return (

            <>
                <ToastContainer />



                {this.state.modalempleados == true ?
                    <ModalEmpleadosSeguridad show={this.state.modalempleados} cerrarModal={this.cerrarModalEmpleados} />
                    : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody className='modalAsistenciaSeguridad' >
                        <div className="divTituloModal">
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >Asistencia Semanal Seguridad</label><br />
 <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />

                        </div>
                        

                        <div className="modalAsistenciaSeguridad">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>


                                <div className='row'>
                                    <div className='col-6'>
                                        <p className='lblSubTitulosModal ' >
                                            De: {this.state.fechainicio} A: {this.state.fechafin}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <br />

                        {/*<Button className="btnAceptarModal" onClick={() => this.verModalempleados()}>Agregar empleados</Button>*/}

                        <div className="container-fluid containerTabla">
                            <div className="container-fluid anchoMaximo ">
                                <div className="containerSubTabla">

                                    <Table style={{ border: "1px solid #a7a7a7" }}>
                                        <thead style={{ verticalAlign: "bottom", height: '65px' }}>
                                            <tr>
                                                {this.state.tabla.encabezados.map((titulo, index) => (
                                                    <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla, textAlign: index > 0 ? 'center' : 'start' }}>
                                                        {titulo}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody style={{ verticalAlign: "bottom" }}>
                                            {this.state.tabla.elementos.map((item, index) => (

                                                <tr style={{ border: "1px solid #a7a7a7", height: "33px" }}>



                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: 'fit-content' }}>
                                                        {item.usuario}
                                                    </td>


                                                    {





                                                        item.dias.map((titulos, index2) => (
                                                            <>
                                                                <td className="letraNormal lblSubtabla1cell" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], maxWidth: this.state.tabla.dimensiones[1] }}>
                                                                    <label style={{ display: "flex", width: "100%" }} onClick={() => this.cambiarAsistencia(index, titulos.fecha)}>
                                                                        <input type="checkbox" style={{ margin: "auto" }} checked={titulos.asistencia == 1 ? true : false} />

                                                                    </label>


                                                                </td>

                                                            </>
                                                        ))
                                                    }




                                                </tr>
                                            ))}
                                        </tbody>


                                    </Table>

                                </div>
                            </div>
                        </div>

                        <br />
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        {parseInt(this.state.activo) == 1 ?
                            <div className="divBtnContenedorModal" >
                                <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                                <Button className="btnAceptarModal" onClick={() => this.showModal(1)} disabled={!this.state.confirmarenvio}>Guardar</Button>
                            </div>
                            : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }
}


const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}>

        <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
));
