import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { estiloColorError, estiloComboBox, estiloFiltroEncabezados, estilosModal } from '../../Services/PaletaDeColores';





export default class Modalconfirmaasignar extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            bandera: props.bandera,
            elegirItems: props.elegirItemsV,
            listaranchos: props.listaranchosV,
            listazonas: props.listazonasV,
            txtselectedzona: 1,
            txtselectedrancho: 0,


            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,


            idusuario: props.idusuario,
            txtsalario: props.salario,
            vermsgerrortxtsalario: false,
            msgerrortxtsalario: "",

            txtprincipal: "",
            vermsgerrortxtprincipal: false,
            msgerrortxtprincipal: ""

        }

    }


    async componentDidMount() {     
        setTimeout(async () => {
            this.Cargarrancho_zona_rancho("zonas",this.props.elegirItemsV.idcatalogo_zona)
            this.Cargarrancho_zona_rancho("ranchos",this.props.elegirItemsV.idcatalogo_rancho)
    
            this.refInput.focus();
        }, 500);



    }

    setLoadingSpinner = (bandera) => {
        this.props.setloadingspinner(bandera);
    }

    showModal = (accion, json) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion, json);
        }, 200);
    }

    Cargarrancho_zona_rancho = async (ban, dato) => {

        var filtrozonas = [];
        var filtroranchos = [];
        var filtrotipocultivo = [];
        var filtrocultivo = [];
        var filtronombre = [];

        if (ban == 'zonas') {
            this.setState({ txtselectedzona: dato });


        } else if (ban == 'ranchos') {
            this.setState({ txtselectedrancho: dato });


        }
    }

    saveInputLocacion = async (e) => {

        var filtrozonas = [];
        var filtroranchos = [];
        var filtrotipocultivo = [];
        var filtrocultivo = [];
        var filtronombre = [];

        if (e.target.id == 'zonas') {
            this.setState({ txtselectedzona: e.target.value });


            if (this.state.txtselectedrancho == 0) {
                filtroranchos = filtrozonas;
            } else {
                filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho == this.state.txtselectedrancho)
            }


        } else if (e.target.id == 'ranchos') {
            this.setState({ txtselectedrancho: e.target.value });

            if (e.target.value == 0) {
                filtroranchos = filtrozonas;
            } else {
                filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho == e.target.value)
            }
            if (this.state.txtselectedtipocultivo == 0) {
                filtrotipocultivo = filtroranchos;
            } else {
                filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo == this.state.txtselectedtipocultivo)
            }


        }
    }


    guardarCambios = async () => {

        if (this.state.txtselectedrancho == 0 || this.state.txtselectedrancho == "0") {
            toast.info("Selecciona un rancho", { autoClose: 3000, position: toast.POSITION.TOP_LEFT });
            return
        }

        var json = {
            "idcatalogo_rancho": this.state.txtselectedrancho
        }

        this.setState({ loadingspinners: true })
        this.showModal(2, json)

    }


    render() {
        return (

            <>

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>




                        {this.state.bandera == "combo" ? <>

                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo, textAlign: "start", width: "100%" }}>Selecciona Rancho: </label><br />
                            <br />
                            <div style={{ backgroundColor: "#f2f2f2", padding: "10px", borderRadius: "7px" }}>
                                <br />
                                <div className='row'>


                                    <div className='col-6'>

                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona:</p>

                                        <div className='divCombo' style={{ width: "100%", color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select id='zonas' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                                                    {this.state.txtselectedzona == 0 ? <option selected="true" value='0'>Todas</option> : <option value='0'>Todas</option>}
                                                    {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((item, index) => {
                                                        return (<option key={"z_" + index} value={item.idcatalogo_zona}>{item.zona}</option>);
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                    </div>


                                    <div className='col-6'>

                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho:</p>

                                        <div className='divCombo' style={{ width: "100%", color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select id='ranchos' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                                                    {this.state.txtselectedrancho == 0 ? <option selected="true" value='0'>Todos</option> : <option value='0'>Todos</option>}
                                                    {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((item, index) => {
                                                        return (item.idcatalogo_zona == this.state.txtselectedzona || this.state.txtselectedzona == 0 ? <option key={"r_" + index} value={item.idcatalogo_rancho}>{item.rancho}</option> : null);
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>


                            <br />


                            <div className="divBtnContenedorModal" >
                                <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} >Continuar</Button>
                            </div>
                        </>
                            :
                            <>
                                <div className="divModalEliminar">
                                    <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo, textAlign: "center", width: "100%" }}>{this.props.mensaje}</label><br />
                                    <label className="modalLblTitulo letraTitulos" style={{ color: "black", textAlign: "center", width: "100%" }}>{this.props.nombre}</label>

                                </div>

                                <br />

                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.showModal('1')} >Continuar</Button>
                                </div>
                            </>
                        }



                    </ModalBody>

                </Modal>
            </>
        )
    }

}
