import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoNomina.css';
import { BiEdit, BiSearchAlt, BiSearchAlt2 } from "react-icons/bi";
import { FaKey, FaCheck, FaCalendar } from "react-icons/fa";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table, Button } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloTabla, estiloBtnVerde, estiloBtnRojo, estiloBtnRestablecer } from '../../Services/PaletaDeColores';
import ModalFormularioUsuarios from '../../Complements/Modales/ModalFormularioUsuarios/ModalFormularioUsuarios';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaTipoUsuarios, getListaZonas, getListaRanchosDisponibles, getListaEncargados, getListaNominas, getCatalogoNominas, createNuevaNomina } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Calendario from '../../Complements/calendario/Calendario';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCalendarFill, BsFillEyeFill } from 'react-icons/bs';
import { format } from 'date-fns';
import ModalAutorizacion from '../../Complements/Modales/ModalAutorizacion/ModalAutorizacion';
import ModalNuevaNomina from './ModalNuevaNomina';



export default class CatalogoNomina extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            salir: false,
            txtbuscador: '',
            altura: window.innerHeight,
            anchura: window.innerWidth,
            loadingspinners: true,
            confirmarenvio: false,
            bandera_periodo: "activo",


            tabla: {
                encabezados: [
                    "Semana",
                    "Fecha Inicio",
                    "Fecha Fin",

                    "Salarios",
                    "Extras",
                    "Total",

                    "Estatus",
                    ""],
                dimensiones: [
                    "8%",
                    "15%",
                    "15%",
                    "15%",

                    "10%",
                    "10%",
                    "10%",
                    "200px"
                ],
                mindimensiones: [
                    "90px",
                    "130px",
                    "130px",
                    "130px",

                    "110px",
                    "100px",
                    "100px",
                    "200px",

                ],
                elementos: []
            },
            estatus: [],
            listaelementosfiltrados: [],
            zonaseleccionado: "TodasLasZonas",
            ranchoseleccionado: "TodosLosRanchos",
            encargadoseleccionado: "TodosLosEncargados",
            listatipousuario: [],

            accionmodal: 1,
            titulomodal: '',
            modaldetalles: false,
            itemseleccionado: null,

            idseleccionado: "",
            verdetallesnomina: false,
            nuevodetallesnomina: false,
            VerModalNuevaNomina: false,

            errordeacceso: false,
            total_salarial: 0,
            total_otras: 0,
            total_salario: 0,

            //paginacion
            activepage: 1,
            itemsperpage: 20,

            listapaginada: [],


            modalautorizacion: false,
            modalautorizaciontitulo: "",

            fechacompraini: new Date(),
            fechacomprafin: new Date(),
            txtfechacompraini: "",
            txtfechacomprafin: "",

            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortitulo: estiloFiltroEncabezados().colortitulos,
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            colorencabezadostabla: estiloTabla().colorencabezadostabla,
            colorcontenidotabla: estiloTabla().colorcontenidotabla,

            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
            colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

            btnfondoeditar: estiloBtnEditar().btnfondoeditar,
            btncoloreditar: estiloBtnEditar().btncoloreditar,
            btnbordereditar: estiloBtnEditar().btnbordereditar,

            colorfondobtnverde: estiloBtnVerde().colorfondobtnverde,
            colorbtnverde: estiloBtnVerde().colorbtnverde,
            colorbordebtnverde: estiloBtnVerde().colorbordebtnverde,

            colorfondobtnrojo: estiloBtnRojo().colorfondobtnrojo,
            colorbtnrojo: estiloBtnRojo().colorbtnrojo,
            colorbordebtnrojo: estiloBtnRojo().colorbordebtnrojo,

            colorfondobtnrestablecer: estiloBtnRestablecer().colorfondobtnrestablecer,
            colorbtnrestablecer: estiloBtnRestablecer().colorbtnrestablecer,
            colorbordebtnrestablecer: estiloBtnRestablecer().colorbordebtnrestablecer,

        }
    }


    async componentDidMount() {

        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
        }

        var estatus = await getEstatus();
        if (this.manejadorLlamadasApis(estatus)) {
            return;
        }


        var fechaactualFin = new Date();
        const fechaa = fechaactualFin.getDate();
        fechaactualFin.setDate(fechaa);
        const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

        var fechaactualIni = new Date();
        fechaactualIni.setDate(fechaactualIni.getDate() - 7);
        const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');

        await this.setState({
            txtbuscador: '',
            txtfechacompraini: defaultValueFechaInicial,
            txtfechacomprafin: defaultValue,
            fechacompraini: fechaactualIni,
        })

        setTimeout(() => {
            this.consultarCatalogoCompra();
            this.dimensiones();
            this.cambiarContenido(1);
            window.addEventListener("resize", this.dimensiones);
            this.setState({ loadingspinners: false })
        }, 300);


    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    actualizarTabla = async () => {
        this.setState({
            loadingspinners: true,
            errordeacceso: false,
        })
        this.componentDidMount();
    }


    cerrarModal = (accion) => {

        this.setState({ modaldetalles: !this.state.modaldetalles })
        if (accion == 1) {
            this.setState({ txtbuscador: '' })
            this.componentDidMount();
            this.filtrarArea("TodasLasZonas", 0)
        }

    }



    cambiarContenido = (pagenumber) => {
        this.setState({
            activepage: pagenumber,
            listapaginada: []
        });

        setTimeout(() => {
            this.setState({
                activepage: pagenumber,
                listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos)
            });
        }, 0);

    }


    activarSpinner = (accion) => {
        this.setState({ loadingspinners: accion })
    }




    cerrarModalAtutorizacion = (accion) => {
        // //console.log(accion)
        this.setState({ modalautorizacion: !this.state.modalautorizacion })
        if (accion == 1) {
            this.componentDidMount();
        }
    }

    llenarnominamodal = async (dato, json) => {

        this.setState({ VerModalNuevaNomina: !this.state.VerModalNuevaNomina, ingreso:0 })
        if (dato == 1) {
            this.componentDidMount(1, json)
        }
        else {
            this.setState({ loadingspinners: false })
        }

    }


    llenarnomina = async (bandera) => {
        //bandera == id = editar, o ,0 = nuevo

        this.setState({ loadingspinners: true })
        const result = format(this.state.fechacompraini, 'yyyy-MM-dd');
        const result2 = format(this.state.fechacomprafin, 'yyyy-MM-dd');

        if (bandera == 0) {
            localStorage.setItem("idcatalogo_nominas", "0")


            var json = {
                "fechainicio": result,
                "fechafin": result2,
                "fechainiciojson": this.state.fechacompraini,
                "fechafinjson": this.state.fechacomprafin,
                "idestatus": "5"
            }


            var resplista = await createNuevaNomina(json);
            if (this.manejadorLlamadasApis(resplista)) {
                return;
            }

            //console.log(resplista.code)
            if (resplista.code == 0 || resplista.code == "0") {
                //console.log("aqui")
                this.setState({ loadingspinners: false })
                toast.info(resplista.data[0], { autoClose: 3000, position: toast.POSITION.TOP_LEFT })
                return;

            } else {
                this.setState({ loadingspinners: false })
                //console.log("aqui AAAA")

                this.componentDidMount(1, json);


            }




            //this.setState({ loadingspinners: true });
            //this.setState({ nuevodetallesnomina: true });


        }
        else {


            const result = format(this.state.fechacompraini, 'yyyy-MM-dd');
            const result2 = format(this.state.fechacomprafin, 'yyyy-MM-dd');


            var datosjson = {
                idcatalogo_nominas: bandera.idcatalogo_nominas,
                fechainicio: bandera.fechainicio,
                fechafin: bandera.fechafin,
                idestatus: bandera.idestatus,
                semanaclave:bandera.clave
            }
            localStorage.setItem("datosnomina", JSON.stringify(datosjson))


            // localStorage.setItem("idcatalogo_nominas", bandera)
            this.setState({ loadingspinners: true });
            this.setState({ nuevodetallesnomina: true });

        }


    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
        this.consultarCatalogoCompra(['txt' + item.llave], year + '-' + month + '-' + day)
    }


    consultarCatalogoCompra = async (llave, valor) => {
        var formatofechaini = "";
        var formatofechafin = "";
        var fechainiaux = 'txtfechacompraini' == llave ? valor : this.state.txtfechacompraini
        var fechafinaux = 'txtfechacomprafin' == llave ? valor : this.state.txtfechacomprafin

        if (fechainiaux.length > 0) {
            var [year, month, day] = fechainiaux.split("-");
            formatofechaini = `${day}/${month}/${year}`
        }

        if (fechafinaux.length > 0) {
            var [year, month, day] = fechafinaux.split("-");
            formatofechafin = `${day}/${month}/${year}`
        }

        var requestbody = {
            fechainicio: formatofechaini,
            fechafin: formatofechafin,
        }

        var fechasvalidas = await this.validarFechasDeCompra(formatofechaini, formatofechafin)
        if (fechasvalidas == 1) {
            this.setState({ loadingspinners: true })
            var catalogocompras = await getCatalogoNominas(requestbody);
            if (this.manejadorLlamadasApis(catalogocompras)) {
                this.setState({ loadingspinners: false })
                return;
            }

            var tabla = this.state.tabla;
            tabla.elementos = catalogocompras.data;

            await this.setState({
                txtbuscador: '',
                tabla: tabla
            })
            this.cambiarContenido(1);
            this.setState({ loadingspinners: false })
        }
    }

    validarFechasDeCompra = async (formatofechaini, formatofechafin) => {
        var fechainicio = new Date();
        var fechafin = new Date();
        var fechainiciovalido = false;
        var fechafinvalido = false;
        if (formatofechaini.length > 0) {
            fechainiciovalido = true;
            var fechacompraini = formatofechaini.split("/");
            fechainicio.setFullYear(fechacompraini[2], fechacompraini[1], fechacompraini[0]);
        }

        if (formatofechafin.length > 0) {
            fechafinvalido = true;
            var fechacomprafin = formatofechafin.split("/");
            fechafin.setFullYear(fechacomprafin[2], fechacomprafin[1], fechacomprafin[0]);
        }

        if (fechainiciovalido && fechafinvalido) {
            if (fechainicio <= fechafin) {
                return 1;
            } else {
                toast.info("Fecha inicio no puede ser mayor que fecha fin", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                var tabla = this.state.tabla;
                tabla.elementos = [];

                await this.setState({
                    txtbuscador: '',
                    tabla: tabla
                })
                this.cambairContenido(1);
                return 2;
            }
        }
        return 0;
    }


    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    format_moneda_sin_logo = (number) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
    }

    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }




    editarTituloNomina = (item) =>{
        this.setState({item:item, ingreso:1, VerModalNuevaNomina:true})


    }

    render() {
        return (

            <>
                {this.state.salir ?
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <Acciones bandera={'salir'} />
                    </>
                    : null}
                <ToastContainer />


                {(this.state.VerModalNuevaNomina == true) ? (
                    <ModalNuevaNomina tipo="full" show={this.state.VerModalNuevaNomina} cerrarmodal={this.llenarnominamodal} item={this.state.item} ingreso={this.state.ingreso}/>
                ) : null}


                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}


                {(this.state.verdetallesnomina == true) ? (
                    <Acciones bandera={"vernomina"} id={this.state.idseleccionado} />
                ) : null}

                {(this.state.nuevodetallesnomina == true) ? (
                    <Acciones bandera={"nuevanomina"} />
                ) : null}


                {(this.state.modalautorizacion == true) ? (
                    <ModalAutorizacion item={this.state.itemseleccionado} show={this.state.modalautorizacion} titulomodal={this.state.modalautorizaciontitulo}
                        cerrarModalAtutorizacion={this.cerrarModalAtutorizacion} setloadingspinner={this.setLoadingSpinner} accion={this.state.accionmodal} />
                ) : null}

                <Navbar />

                {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
                <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                    <div className="container-fluid anchoMaximo">

                        <div className='row paddingEncabezados align-items-end '>
                            <div className='col containerSubTabla paddingBottomEncabezados'>
                                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo, paddingLeft: '0px' }}>Nóminas</label>
                            </div>



                            {/* filtro de fechas */}

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <div className='contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}>

                                    <div className='subcontenedorIconoCalendarioCompras'>
                                        <BsFillCalendarFill className='iconoCalendario' style={{ color: this.state.colortipolbl, stroke: this.state.colortipolbl }} />
                                    </div>
                                    <div className='row'>
                                        <div className=' col-sm-4 col-md-4'>
                                            <label className='lblTexto letraTitulos labelFecha' style={{ color: this.state.colortipolbl, }}>Fecha de:</label>
                                        </div>
                                        <div className='row col-sm-8 col-md-8' style={{ marginLeft: '10px' }}>
                                            <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                                <DatePicker
                                                    selected={this.state.fechacompraini}
                                                    onChange={(date) => this.cambiarFecha({ llave: "fechacompraini", valor: date })}
                                                    dateFormat="dd/MM/yyyy"
                                                    locale="es"
                                                    customInput={
                                                        <DateButton />
                                                    }
                                                />
                                            </div>
                                            <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>a:</label>
                                            </div>

                                            <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                                <DatePicker
                                                    selected={this.state.fechacomprafin}
                                                    onChange={(date) => this.cambiarFecha({ llave: "fechacomprafin", valor: date })}
                                                    dateFormat="dd/MM/yyyy"
                                                    locale="es"
                                                    customInput={
                                                        <DateButton />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>














                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <button className='btnActualizar letraNormal'
                                    style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                    onClick={this.actualizarTabla}><RiRefreshLine />&nbsp;Refrescar lista</button>
                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <button className='btnNuevo letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={this.llenarnominamodal.bind(this, 0)}>Crear Nomina</button>
                            </div>


                        </div>





                    </div>
                </div>
                {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

                {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

                <br />

                <div className="container-fluid containerTabla">
                    <div className="container-fluid anchoMaximo ">
                        <div className="containerSubTabla">
                            <Table>
                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                {titulo}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody style={{ verticalAlign: "bottom" }}>


                                    {this.state.listapaginada.map((item, index) => (
                                        <tr key={"tabla" + index}>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {item.clave}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {item.fechainicio}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {item.fechafin}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {this.format_moneda(item.salarios)}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {this.format_moneda(item.extras)}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {this.format_moneda(item.total)}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {item.idestatus == 5 ? <b>{item.estatus}</b> : <>{item.estatus}</>}
                                            </td>
                                            <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }} >

                                            <div style={{display:"flex", width:"100%"}}>
  <button 
                                                        onClick={()=>this.editarTituloNomina(item)} 
                                                        className='btnEditar' style={{
                                                            width: "100px",
                                                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                                                        }}><BiEdit className="icoBotones" /> Editar</button>

                                                        {item.ideditar}


                                                        <button onClick={this.llenarnomina.bind(this, item)} className='btnEditar' style={{
                                                            width: "100px",
                                                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                                                        }}><BiEdit className="icoBotones" /> Detalles</button>
                                            </div>

                                                      

                                             

                                            </td>

                                        </tr>
                                    ))}


                                </tbody>
                            </Table>
                        </div>

                    </div>
                </div>

                <div className="panelPaginationSection">
                    <Paginacion
                        activepage={this.state.activepage}
                        itemscountperpage={this.state.itemsperpage}
                        totalitemscount={this.state.txtbuscador.length < 3 ? this.state.tabla.elementos : this.state.elementosfiltrados}
                        pagerangedisplayed={this.state.rangepages}
                        cambiarcontenido={(e) => this.cambiarContenido(e)}
                    />
                </div>

            </>
        )
    }
}
function Acciones({ bandera, id }) {

    const history = useHistory();
    const location = useLocation();
    history.push(location.pathname);

    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }

    else if (bandera == 'vernomina') {
        localStorage.setItem("datonomina", id);
        return (<Redirect from='/' to={"/catalogonominaver"} />)
    }

    else if (bandera == 'nuevanomina') {
        localStorage.removeItem("datonomina");
        return (<Redirect from='/' to={"/catalogonominaver"} />)
    }

}




const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}>

        <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
));

