import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './DetallesCorridaLimon.css';
import { Table, Button, InputGroup, InputGroupText} from 'reactstrap';
import {estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloBtnNuevo,estiloBtnEditar, estiloColorError} from '../../Services/PaletaDeColores';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal'
import { getDetallesCorridaLimon, postGuardarCorridaLimonTraslado, postGuardarCorridaLimonDetalles } from '../../Services/Api'
import { ToastContainer, toast } from 'react-toastify';
import { validarNumerosDecimales2,validarLetrasNumerosLongitudV2 } from '../../Services/Validaciones';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from 'react-icons/fa';
import ModalFormularioCorridasLimonTraslados from '../../Complements/Modales/ModalFormularioCorridasLimonTraslados/ModalFormularioCorridasLimonTraslados';
import ModalConfirarAccion from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalConfirmarAccion';
import ModalAutorizarVenta from '../../Complements/Modales/ModalFormularioCorridasLimonTraslados/ModalAutorizarVenta';

export default class DetallesCorridaLimon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,
      regresarcatalogo:false,

      accionmodal: 1,
      titulomodal: '',
      itemeliminar: undefined,


      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      idcatalogo_corridas_limon:0,
      informaciondetalles: {
        detalles: {
          rejasprimera: 0.0,
          rejasprimeraintermedia:0.0,
          rejassegunda:0.0,
          rejastercera:0.0,
          rejastorreon:0.0,
          rejasdesecho:0.0,
          kilogramosprimera: 0.00,
          kilogramosprimeraintermedia: 0.00,
          kilogramossegunda: 0.00,
          kilogramostercera: 0.00,
          kilogramostorreon: 0.00,
          kilogramosterceradesecho: 0.00,
          preciokilogramosprimera: 0.00,
          preciokilogramosprimeraintermedia: 0.00,
          preciokilogramossegunda: 0.00,
          preciokilogramostercera: 0.00,
          preciokilogramostorreon: 0.00,
          preciokilogramosdesecho: 0.00,
          gastos: 0.00,
          factura: '',
        },
        fletes: []
      },
      tablafletes: {
        encabezados: [
          "Folio",
          "Rancho",
          "Lote",
          "Cultivo",
          "Rejas",
          "Peso Neto Kg",
          ""
        ],
        dimensiones: [
          "15%",
          "20%",
          "20%",
          "10%",
          "5%",
          "10%",
          "10%"
        ],
        mindimensiones: [
          "150px",
          "200px",
          "200px",
          "130px",
          "50px",
          "50px",
          "100px"
        ],
        maxdimensiones: [
          "300px",
          "150px",
          "150px",
          "150px",
          "150px",
          "150px",
          "150px"
        ]
      },
      rejastotales:0,
      pesototal:0.0,
      pesopromedio:0.0,
     
      rejascorridas:0,
      pesocorrido:0,

      totalrejascorridas:0,
      totalpesocorrido:0.0,
      pesomerma:0.0,
      rejasmerma:0,
      diferencia:0.0,
      rendimiento:0.0,
      totallimones:0.0,
      total:0.0,

      vermsgerrorkilogramosprimera:false,
      vermsgerrorkilogramosprimeraintermedia:false,
      vermsgerrorkilogramossegunda:false,
      vermsgerrorkilogramostercera:false,
      vermsgerrorkilogramotorreon:false,
      vermsgerrorkilogramodesecho:false,

      vermsgerrorpreciokilogramosprimera:false,
      vermsgerrorpreciokilogramosprimeraintermedia:false,
      vermsgerrorpreciokilogramossegunda:false,
      vermsgerrorpreciokilogramostercera:false,
      vermsgerrorpreciokilogramotorreon:false,
      vermsgerrorpreciokilogramodesecho:false,
      vermsgerrorgastos:false,
      vermsgerrorfactura:false,

      msgerrorkilogramosprimera:'',
      msgerrorkilogramosprimeraintermedia:'',
      msgerrorkilogramossegunda:'',
      msgerrorkilogramostercera:'',
      msgerrorpreciokilogramosprimera:'',
      msgerrorpreciokilogramosprimeraintermedia:'',
      msgerrorpreciokilogramossegunda:'',
      msgerrorpreciokilogramostercera:'',
      msgerrorpreciokilogramotorreon:'',
      msgerrorpreciokilogramodesecho:'',
      msgerrorgastos:'',
      msgerrorfactura:'',

      redireccionando: false,
      redireccionandobandera: "",
      modaltraslados: false,
      modalautorizacion:false,
      modaladdplancuadrilla: false,
      modalconfirmaraccion: false,
      itemseleccionado:undefined,
      idcatalogo:0,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      colormsgerror: estiloColorError().msgerror,

      estatus:1,
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var corridalimonaux = await localStorage.getItem('dato')
    var corridalimon = JSON.parse(corridalimonaux)
    var idcatalogo_corridas_limon = corridalimon.id
    if (idcatalogo_corridas_limon != null) {
      var respdetallescorridalimon = await getDetallesCorridaLimon({"idcatalogo_corridas_limon": idcatalogo_corridas_limon});

      if (this.manejadorLlamadasApis(respdetallescorridalimon)) {
        return;
      }

      if (respdetallescorridalimon.code == 200) {
        await toast.error(respdetallescorridalimon.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 3000);
        return;
      }

      var informaciondetalles = this.state.informaciondetalles
      informaciondetalles.detalles = respdetallescorridalimon.data ? respdetallescorridalimon.data : {};
      informaciondetalles.fletes = respdetallescorridalimon.data.traslados ? respdetallescorridalimon.data.traslados : {};

      this.calcularRejasPesoPromedio(informaciondetalles.detalles)
      this.setState({
        informaciondetalles: informaciondetalles,
        idcatalogo_corridas_limon:idcatalogo_corridas_limon
      });
    } else {
      this.redireccionando();
    }
    console.log(this.state.informaciondetalles)
    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
        if (!this.state.errordeacceso) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        this.setState({ loadingspinners: false, errordeacceso: true })
        return true
    }
    else if (resp.code == 400) {
        toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.setState({ loadingspinners: false })
        return true
    }
    else if (resp.code == 401) {
        this.setState({ salir: true })
        return true
    }
    if (resp.code == 1000) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.setState({ loadingspinners: false })
        return true
    }
    return false
  }

  escribir = (prop) => (event) => {
    var estado = this.state.informaciondetalles.detalles
    const peso_promedio= this.state.pesopromedio
    estado[prop] = event.target.value;

    if(prop == "kilogramosprimera"){
      estado.rejasprimera = estado.kilogramosprimera / peso_promedio;
    }
    else if(prop == "kilogramosprimeraintermedia"){
      estado.rejasprimeraintermedia = estado.kilogramosprimeraintermedia / peso_promedio;
    }
    else if(prop == "kilogramossegunda"){
      estado.rejassegunda = estado.kilogramossegunda / peso_promedio;
    }
    else if(prop == "kilogramostercera"){
      estado.rejastercera = estado.kilogramostercera / peso_promedio;
    }
    else if(prop == 'kilogramostorreon'){
      estado.rejastorreon = estado.kilogramostorreon / peso_promedio
    }
    else if(prop == 'kilogramosterceradesecho'){
      estado.rejasdesecho = estado.kilogramosterceradesecho / peso_promedio
    }

    var peso_total_corrido = Number(estado.kilogramosprimera) + Number(estado.kilogramosprimeraintermedia) + Number(estado.kilogramossegunda) + Number(estado.kilogramostercera) + Number(estado.kilogramostorreon) + Number(estado.kilogramosterceradesecho);
    var rejas_corridas = Number(estado.rejasprimera) + Number(estado.rejasprimeraintermedia) + Number(estado.rejassegunda) + Number(estado.rejastercera) + Number(estado.rejastorreon) + Number(estado.rejasdesecho)
    var peso_merma = this.state.pesototal - peso_total_corrido;
    var rejas_merma = peso_merma / peso_promedio;
    var diferencia = this.state.pesototal - peso_total_corrido;
    var precio_primera = estado.kilogramosprimera * estado.preciokilogramosprimera;
    var precio_primera_int = estado.kilogramosprimeraintermedia * estado.preciokilogramosprimeraintermedia;
    var precio_segunda = estado.kilogramossegunda * estado.preciokilogramossegunda;
    var precio_tercera = estado.kilogramostercera * estado.preciokilogramostercera;
    var precio_torreon = estado.kilogramostorreon * estado.preciokilogramostorreon;
    var precio_desecho = estado.kilogramosterceradesecho * estado.preciokilogramosdesecho;
    var rendimiento = (estado.kilogramosprimera / this.state.pesototal);
    var total_limones = precio_primera + precio_primera_int + precio_segunda + precio_tercera + precio_torreon + precio_desecho;
    var total = total_limones - estado.gastos;

    var rejas_corridas_total = rejas_corridas + rejas_merma;
    var peso_corrido_total = (peso_merma + peso_total_corrido)
    this.setState({
      estado,
      totalpesocorrido:peso_total_corrido,
      totalrejascorridas:rejas_corridas,
      pesomerma:peso_merma,
      rejasmerma:rejas_merma,
      diferencia:diferencia,
      totallimones:total_limones,
      total:total,
      rendimiento:rendimiento,
      rejascorridas:rejas_corridas_total,
      pesocorrido:peso_corrido_total
    });
  }


  calcularRejasPesoPromedio = (detalles) =>{
    var rejas_total = 0;
    var peso_total = 0;

    detalles.traslados.map((i) => {
      rejas_total += i.rejas;
      peso_total += Number(i.pesoneto);
    });

    var peso_promedio = peso_total / rejas_total;
    var rejas_primera = detalles.kilogramosprimera / peso_promedio;
    var rejas_primera_inter = detalles.kilogramosprimeraintermedia / peso_promedio;
    var rejas_segunda = detalles.kilogramossegunda / peso_promedio;
    var rejas_tercera = detalles.kilogramostercera / peso_promedio;
    var rejas_torreon = detalles.kilogramostorreon / peso_promedio;
    var rejas_desecho = detalles.kilogramosterceradesecho / peso_promedio;
    var rejas_corridas = rejas_primera + rejas_primera_inter + rejas_segunda + rejas_tercera + rejas_torreon + rejas_desecho;
    var peso_total_corrido = Number(detalles.kilogramosprimera) + Number(detalles.kilogramosprimeraintermedia) + Number(detalles.kilogramossegunda) + Number(detalles.kilogramostercera) + Number(detalles.kilogramostorreon) + Number(detalles.kilogramosterceradesecho);
    var peso_merma = peso_total - peso_total_corrido;
    var rejas_merma = peso_merma / peso_promedio;
    var diferencia = peso_total - peso_total_corrido;
    var rendimiento = (Number(detalles.kilogramosprimera) / peso_total);
    var precio_primera = detalles.kilogramosprimera * detalles.preciokilogramosprimera;
    var precio_primera_int = detalles.kilogramosprimeraintermedia * detalles.preciokilogramosprimeraintermedia;
    var precio_segunda = detalles.kilogramossegunda * detalles.preciokilogramossegunda;
    var precio_tercera = detalles.kilogramostercera * detalles.preciokilogramostercera;
    var precio_torreon = detalles.kilogramostorreon * detalles.preciokilogramostorreon;
    var precio_desecho = detalles.kilogramosterceradesecho * detalles.preciokilogramosdesecho
    var total_limones = precio_primera + precio_primera_int + precio_segunda + precio_tercera + precio_torreon + precio_desecho ;
    var total = total_limones - detalles.gastos;
    console.log(total)
    var rejas_corridas_total = (rejas_corridas + rejas_merma);
    var peso_corrido_total = (peso_merma + peso_total_corrido)
    
    this.setState({
      rejastotales:rejas_total,
      pesototal:peso_total,
      pesopromedio:peso_promedio,
      rejasprimera:rejas_primera,
      rejasprimeraintermedia: rejas_primera_inter,
      rejassegunda: rejas_segunda,
      rejastercera: rejas_tercera,
      rejastorreon: rejas_torreon,
      rejasdesecho: rejas_desecho,
      totalrejascorridas:rejas_corridas,
      totalpesocorrido:peso_total_corrido,
      pesomerma:peso_merma,
      rejasmerma:rejas_merma,
      diferencia:diferencia,
      rendimiento:rendimiento,
      totallimones:total_limones,
      total:total,
      rejascorridas:rejas_corridas_total,
      pesocorrido:peso_corrido_total
    })
  }

  format_entero = (number) => {
    return new Intl.NumberFormat('en-US').format(Math.floor(number));
  }

  format_numero_decimales = (number) => {
    return new Intl.NumberFormat('en-US',{ minimumFractionDigits: 2 }).format(number);
  }

  rejas = (rejas) =>{
    if(rejas > this.state.rejastotales){
      return <span style={{color: 'red' }}> {this.format_entero(rejas)}</span>
    }
    else if(rejas < this.state.rejastotales){
      return <span style={{color: 'red' }}> {this.format_entero(rejas)}</span>
    }
    else{
      return <span> {this.format_entero(rejas)}</span>
    }
  }

  pesototal = (peso) =>{
    if(peso > this.state.pesototal){
      return <span style={{color: 'red' }}> {this.format_kilogramos(peso)}</span>
    }
    else if(peso < this.state.pesototal){
      return <span style={{color: 'red' }}> {this.format_kilogramos(peso)}</span>
    }
    else{
      return <span> {this.format_kilogramos(peso)}</span>
    }
  }

  format_numero = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  }

  format_moneda = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 4 }).format(number);
  }

  format_kilogramos = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'unit', unit: 'kilogram',minimumFractionDigits: 2 }).format(number);
  }

  format_porcentaje = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2 }).format(number);
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setLoadingSpinner = (band) => {
    this.setState({ loadingspinners: band })
  }

  verModalConfirmarVenta = async (accion) => {
    this.setState({
      modalautorizacion: !this.state.modalautorizacion,
      titulomodal:"Autorizar venta",
      accionmodal: accion
    })
  }

  cerrarModalAtutorizacion = (accion) => {
    this.setState({ modalautorizacion: !this.state.modalautorizacion })
    if (accion == 1) {
      toast.success("Corrida finalizada.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  verModal = (ban) => {
    if (ban.opcion == 1) { //Nuevo traslado
      this.setState({ titulomodal: 'Nuevo traslado', accionmodal: 1, modaltraslados: true })
    }else if (ban.opcion == 2) { //confirmar accion eliminar traslado
      this.setState({ titulomodal: 'Confirmar acción', accionmodal: "traslado", modalconfirmaraccion: true, itemeliminar: ban.item });
    }
  }

  cerrarModalTraslados = (accion) => {
    this.setState({ modaltraslados: !this.state.modaltraslados })
    if(accion == 1){
      toast.success("Traslado agregado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  eliminarElemento = async (elemento) => {
    var resp = undefined;
    this.setState({ modalconfirmaraccion: !this.state.modalconfirmaraccion })
    if(elemento.accion==1){
      this.setState({ loadingspinners: true })
     
      if(elemento.plan=="traslado"){
        let json = {
          "idcatalogo_corridas_limon_traslados": elemento.item.idcatalogo_corridas_limon_traslados,
          "idcatalogo_corridas_limon": this.state.idcatalogo_corridas_limon,
          "idcatalogo_bitacora_traslados_detalles":elemento.item.idcatalogo_bitacora_traslados_detalles,
          "eliminar": 1
        }
        var resp = await postGuardarCorridaLimonTraslado(json);
      }

      if(resp.code == 200){
        this.setState({ loadingspinners: false })
        toast.success("Se borró el elemento exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.componentDidMount();
      }
      else if(resp.code == 300){
        this.setState({ loadingspinners: false })
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 400){
        this.setState({ loadingspinners: false })
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if (resp.code == 1000) {
        this.setState({ loadingspinners: false })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
    }
  }

  guardardatos = async () => {
    //Kilogramos
    this.setState({ vermsgerrorkilogramosprimera: false });
    this.setState({ vermsgerrorkilogramosprimeraintermedia: false });
    this.setState({ vermsgerrorkilogramossegunda: false });
    this.setState({ vermsgerrorkilogramostercera: false });
    this.setState({ vermsgerrorkilogramotorreon:false});
    this.setState({ vermsgerrorkilogramodesecho: false});
    //Precio
    this.setState({ vermsgerrorpreciokilogramosprimera: false });
    this.setState({ vermsgerrorpreciokilogramosprimeraintermedia: false });
    this.setState({ vermsgerrorpreciokilogramossegunda: false });
    this.setState({ vermsgerrorpreciokilogramostercera: false });
    this.setState({ vermsgerrorpreciokilogramotorreon: false});
    this.setState({ vermsgerrorpreciokilogramodesecho: false});
    this.setState({ vermsgerrorgastos: false });
    this.setState({ vermsgerrorfactura: false });

    let validacionescorrectas = true
    let numeroinvalido = false;
    let longitudinvalida = false;
    let campovacio = false;
    let longitudvalida = undefined;
    let textvalid = undefined;

    longitudvalida = {longitudminima: 1, longitudmaxima: 12};

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.kilogramosprimera.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
        if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
          longitudinvalida = true;
        }
        else if(textvalid.mensaje == "Escribe un número válido"){
          numeroinvalido = true;
        }
        else if(textvalid.mensaje == "Este campo es obligatorio"){
          campovacio = true;
        }
        this.setState({ vermsgerrorkilogramosprimera: true })
        validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.kilogramosprimeraintermedia.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorkilogramosprimeraintermedia: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.kilogramossegunda.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorkilogramossegunda: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.kilogramostercera.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorkilogramostercera: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.kilogramostorreon.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorkilogramotorreon: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.kilogramosterceradesecho.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorkilogramodesecho: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.preciokilogramosprimera.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorpreciokilogramosprimera: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.preciokilogramosprimeraintermedia.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorpreciokilogramosprimeraintermedia: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.preciokilogramossegunda.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorpreciokilogramossegunda: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.preciokilogramostercera.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorpreciokilogramostercera: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.preciokilogramostorreon.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorpreciokilogramotorreon: true })
      validacionescorrectas = false;
    }

    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.preciokilogramosdesecho.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorpreciokilogramodesecho: true })
      validacionescorrectas = false;
    }


    textvalid = validarNumerosDecimales2(this.state.informaciondetalles.detalles.gastos.trim(), longitudvalida);
    if (textvalid.codigo == "invalid") {
      if(textvalid.mensaje == "Este campo no puede superar más de 12 caracteres"){
        longitudinvalida = true;
      }
      else if(textvalid.mensaje == "Escribe un número válido"){
        numeroinvalido = true;
      }
      else if(textvalid.mensaje == "Este campo es obligatorio"){
        campovacio = true;
      }
      this.setState({ vermsgerrorgastos: true })
      validacionescorrectas = false;
    }

    longitudvalida = {longitudminima: 1, longitudmaxima: 100};
    if(this.state.informaciondetalles.detalles.factura == null){
      campovacio = true;
      this.setState({ vermsgerrorfactura: true })
      validacionescorrectas = false;
    }else{
      var longitudinvalidafactura = false;
      var facturavalida = false;
      textvalid = validarLetrasNumerosLongitudV2(this.state.informaciondetalles.detalles.factura, longitudvalida);
      if (textvalid.codigo == "invalid") {
        if(textvalid.mensaje == "Este campo no puede superar más de 100 caracteres"){
          longitudinvalidafactura = true;
        }
        else if(textvalid.mensaje == "Solo se permiten letras y números"){
          facturavalida = true;
        }
        else if(textvalid.mensaje == "Este campo es obligatorio"){
          campovacio = true;
        }
        this.setState({ vermsgerrorfactura: true })
        validacionescorrectas = false;
      }
    }

    if(campovacio){
      toast.warning("No se permiten campos vacíos.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(numeroinvalido){
      toast.warning("Escribe un número válido.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(longitudinvalida){
      toast.warning("No se permiten datos mayores a 12 caracteres.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(longitudinvalidafactura){
      toast.warning("La factura no puede tener más de 100 caracteres.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    if(facturavalida){
      toast.warning("Sólo se permiten letras y números en factura.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    var resp = undefined;
    if (validacionescorrectas) {
      this.setLoadingSpinner(true)
      let json ={
        "idcatalogo_corridas_limon": this.state.idcatalogo_corridas_limon,
        "rejasprimera": this.state.informaciondetalles.detalles.rejasprimera,
        "rejasprimeraintermedia": this.state.informaciondetalles.detalles.rejasprimeraintermedia,
        "rejassegunda": this.state.informaciondetalles.detalles.rejassegunda,
        "rejastercera": this.state.informaciondetalles.detalles.rejastercera,
        "rejastorreon": this.state.informaciondetalles.detalles.rejastorreon,
        "rejasdesecho": this.state.informaciondetalles.detalles.rejasdesecho,
        "kilogramosprimera": this.state.informaciondetalles.detalles.kilogramosprimera,
        "kilogramosprimeraintermedia": this.state.informaciondetalles.detalles.kilogramosprimeraintermedia,
        "kilogramossegunda": this.state.informaciondetalles.detalles.kilogramossegunda,
        "kilogramostercera": this.state.informaciondetalles.detalles.kilogramostercera,
        "kilogramostorreon": this.state.informaciondetalles.detalles.kilogramostorreon,
        "kilogramosterceradesecho": this.state.informaciondetalles.detalles.kilogramosterceradesecho,
        "preciokilogramosprimera":this.state.informaciondetalles.detalles.preciokilogramosprimera,
        "preciokilogramosprimeraintermedia": this.state.informaciondetalles.detalles.preciokilogramosprimeraintermedia,
        "preciokilogramossegunda": this.state.informaciondetalles.detalles.preciokilogramossegunda,
        "preciokilogramostercera": this.state.informaciondetalles.detalles.preciokilogramostercera,
        "preciokilogramostorreon":this.state.informaciondetalles.detalles.preciokilogramostorreon,
        "preciokilogramosdesecho":this.state.informaciondetalles.detalles.preciokilogramosdesecho,
        "gastos": this.state.informaciondetalles.detalles.gastos,
        "factura": this.state.informaciondetalles.detalles.factura
      }

      resp = await postGuardarCorridaLimonDetalles(json);

      if(resp.code == 200){
        this.setLoadingSpinner(false)
        toast.success("Datos guardados correctamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.componentDidMount();
      }
      else if(resp.code == 300){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if(resp.code == 400){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if (resp.code == 1000) {
          this.setLoadingSpinner(false);
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
      }
    }
  }

  regresar = () =>{
    this.setState({loadingspinners:true, regresarcatalogo:true})
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}
        {this.state.regresarcatalogo == true ? 
          <AccionesVista bandera={'regresar'} />
        :null}
        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesVista bandera={'goreferencias'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalautorizacion == true) ?
          <ModalAutorizarVenta
            idcatalogo_corridas_limon={this.state.idcatalogo_corridas_limon}
            show={this.state.modalautorizacion}
            titulomodal={this.state.titulomodal}
            cerrarModalAtutorizacion={this.cerrarModalAtutorizacion}
            setloadingspinner={this.setLoadingSpinner}
            accion={this.state.accionmodal}
          />
        :null}
        {(this.state.modaltraslados == true) ? (
          <ModalFormularioCorridasLimonTraslados
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalTraslados}
            show={this.state.modaltraslados}
            accion={this.state.accionmodal}
            fecha={this.state.informaciondetalles.detalles.fecha}
            idcatalogo_corridas_limon={this.state.idcatalogo_corridas_limon}
            setLoadingSpinner={this.setLoadingSpinner}/>
        ) : null}

        {(this.state.modalconfirmaraccion == true) ? (
          <ModalConfirarAccion 
            titulomodal={this.state.titulomodal} 
            cerrarModalConfirmar={this.eliminarElemento}
            show={this.state.modalconfirmaraccion} 
            cuerpo={this.state.accionmodal}
            item={this.state.itemeliminar}
            />
        ) : null} 

        <Navbar />

        {/* ////////////////////   Inicio Sección datos informativos   ////////////////////////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezadoAutoCot'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Detalles de corrida de limón</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetalles.detalles.fecha}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Cliente: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetalles.detalles.cliete}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetalles.detalles.estatus}</label>
              </div>
              {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                  <button
                    className='lbljoract letraTitulos'
                    style={{ color: this.state.colorbtnnuevo }}
                    onClick={this.verModalConfirmarVenta.bind(this, { opcion: "1" })}
                  >
                      Vender
                  </button>
                </div>
              :null}
            </div>
            <br />
            <br />

          </div>
        </div>

        <br />
        <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>

          {/* ////////////////////   Tabla traslados  ////////////////////////////////////// */}
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1240px', maxWidth: '1240px' }}>
                <div className='row align-items-end' style={{ paddingBottom: '20px'}}>
                  <div className='col-10 minPaddingTopBottomAutoCot divMinWidthAutoCot'>
                    <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Fletes</b></label>
                  </div>
                  {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                    <div className='col-2 paddingBottomEncabezados  ' >
                      <button className='btnNuevo letraNormal'
                        style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                        onClick={this.verModal.bind(this, { opcion: "1" })}>+ Agregar</button>
                    </div>
                  :null}
                </div>
                <div className='divMinWidthAutoCot'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                      <tr>
                        {this.state.tablafletes.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablafletes.mindimensiones[index],
                              maxWidth: this.state.tablafletes.maxdimensiones[index],
                              width: this.state.tablafletes.dimensiones[index],
                              color: this.state.colorencabezadostabla
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.informaciondetalles.fletes.map((item, index) => (
                        <tr key={"tabla" + index}
                          style={{
                            borderBottom: (index == this.state.informaciondetalles.fletes.length - 1) ? 'solid 1px #91b9f9' : ''
                          }}>
                         
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.folio}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.rancho}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.lote}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.cultivo}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                            {this.format_numero(item.rejas)}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                            {this.format_kilogramos(item.pesoneto)}
                          </td>
                          <td key={"col-"+index} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                              <div className="modalDetallesCheck" >
                                {/* <button className='btnEliminarFila' onClick={this.eliminarElemento.bind(this, {item, plan:"Tabla"})}><FaTrashAlt className="icoBotones" /></button> */}
                                <button className='btnEliminarFila' onClick={this.verModal.bind(this, { opcion: "2", item })}><FaTrashAlt className="icoBotones" /></button>
                              </div>
                            :null}
                          </td>
                        </tr>
                      ))}
                      {this.state.informaciondetalles.fletes.length > 0 ? 
                        <tr>
                          <td colspan="3">
                          </td>
                          <th className="lblEncabezadosTabla letraTitulos">
                            Total
                          </th>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla,textAlign: 'right' }}>
                            {this.format_numero(this.state.rejastotales)}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla,textAlign: 'right' }}>
                            {this.format_kilogramos(this.state.pesototal)}
                          </td>
                          <td>
                          </td>
                        </tr>
                      :null}
                    </tbody>
                  </Table>
                 
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* ////////////////////   Fin Tabla traslados ////////////////////////////////////// */}

          {/* ////////////////////   Sección de corrida  ////////////////////////////////////// */}
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1240px', maxWidth: '1240px' }}>

                <div className='row align-items-end' style={{ paddingBottom: '20px'}}>
                  <div className='col-12 minPaddingTopBottomAutoCot divMinWidthAutoCot'>
                    <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Corrida</b></label>
                  </div>
                </div>
                <div className='divMinWidthAutoCot'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                      <tr>
                        <th>
                          Rejas
                        </th>
                        <th>
                          Kilogramos
                        </th>
                        <th>
                          Precio unidad
                        </th>
                      </tr>
                    </thead>

                    <tbody className='tablaCorrida' style={{ verticalAlign: "bottom" }}>
                      <tr>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          1a
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          1a
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          1a
                        </td>
                      </tr>
                      <tr>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.format_entero(this.state.informaciondetalles.detalles.rejasprimera)} rejas
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <input
                                className={this.state.vermsgerrorkilogramosprimera ? 'txtInputKgConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.kilogramosprimera}
                                onChange={this.escribir("kilogramosprimera")}/>
                                <InputGroupText className={this.state.vermsgerrorkilogramosprimera ? 'txtSimboloKgcConError': 'txtInputSimbolo'}>
                                  kg
                                </InputGroupText>
                            </InputGroup>
                            :
                              this.format_kilogramos(this.state.informaciondetalles.detalles.kilogramosprimera)
                            }

                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <InputGroupText className={this.state.vermsgerrorpreciokilogramosprimera ? 'txtSimboloPrecioConError': 'txtInputSimbolo'}>
                                $
                              </InputGroupText>
                              <input
                                className={this.state.vermsgerrorpreciokilogramosprimera ? 'txtInputPrecioConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.preciokilogramosprimera}
                                onChange={this.escribir("preciokilogramosprimera")}>
                              </input>
                            </InputGroup>
                          :
                            this.format_moneda(this.state.informaciondetalles.detalles.preciokilogramosprimera)
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          1.5a
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          1.5a
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          1.5a
                        </td>
                      </tr>
                      <tr>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.format_entero(this.state.informaciondetalles.detalles.rejasprimeraintermedia)} rejas
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <input
                                className={this.state.vermsgerrorkilogramosprimeraintermedia ? 'txtInputKgConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.kilogramosprimeraintermedia}
                                onChange={this.escribir("kilogramosprimeraintermedia")}>
                              </input>
                              <InputGroupText className={this.state.vermsgerrorkilogramosprimeraintermedia ?  'txtSimboloKgcConError': 'txtInputSimbolo'}>
                                kg
                              </InputGroupText>
                            </InputGroup>
                          :
                            this.format_kilogramos(this.state.informaciondetalles.detalles.kilogramosprimeraintermedia)
                          }

                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <InputGroupText className={this.state.vermsgerrorpreciokilogramosprimeraintermedia ? 'txtSimboloPrecioConError': 'txtInputSimbolo'}>
                                $
                              </InputGroupText>
                              <input
                                className={this.state.vermsgerrorpreciokilogramosprimeraintermedia ? 'txtInputPrecioConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.preciokilogramosprimeraintermedia}
                                onChange={this.escribir("preciokilogramosprimeraintermedia")}>
                              </input>
                            </InputGroup>
                          :
                            this.format_moneda(this.state.informaciondetalles.detalles.preciokilogramosprimeraintermedia)
                          }

                        </td>
                      </tr>
                      <tr>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          2a
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          2a
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          2a
                        </td>
                      </tr>
                      <tr>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.format_entero(this.state.informaciondetalles.detalles.rejassegunda)} rejas
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <input
                                className={this.state.vermsgerrorkilogramossegunda ? 'txtInputKgConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.kilogramossegunda}
                                onChange={this.escribir("kilogramossegunda")}>
                              </input>
                              <InputGroupText className={this.state.vermsgerrorkilogramossegunda ? 'txtSimboloKgcConError': 'txtInputSimbolo'}>
                                kg
                              </InputGroupText>
                            </InputGroup>
                          :
                            this.format_kilogramos(this.state.informaciondetalles.detalles.kilogramossegunda)
                          }

                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <InputGroupText className={this.state.vermsgerrorpreciokilogramossegunda ? 'txtSimboloPrecioConError': 'txtInputSimbolo'}>
                                $
                              </InputGroupText>
                              <input
                                className={this.state.vermsgerrorpreciokilogramossegunda ? 'txtInputPrecioConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.preciokilogramossegunda}
                                onChange={this.escribir("preciokilogramossegunda")}>
                              </input>
                            </InputGroup>
                          :
                            this.format_kilogramos(this.state.informaciondetalles.detalles.preciokilogramossegunda)
                          }

                        </td>
                      </tr>
                      <tr>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          3a
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          3a
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          3a
                        </td>
                      </tr>
                      <tr>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.format_entero(this.state.informaciondetalles.detalles.rejastercera)} rejas
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <input
                                className={this.state.vermsgerrorkilogramostercera ? 'txtInputKgConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.kilogramostercera}
                                onChange={this.escribir("kilogramostercera")}>
                              </input>
                              <InputGroupText className={this.state.vermsgerrorkilogramostercera ? 'txtSimboloKgcConError': 'txtInputSimbolo'}>
                                kg
                              </InputGroupText>
                            </InputGroup>
                          :
                            this.format_kilogramos(this.state.informaciondetalles.detalles.kilogramostercera)
                          }

                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <InputGroupText className={this.state.vermsgerrorpreciokilogramostercera ? 'txtSimboloPrecioConError': 'txtInputSimbolo'}>
                                $
                              </InputGroupText>
                              <input
                                className={this.state.vermsgerrorpreciokilogramostercera ? 'txtInputPrecioConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.preciokilogramostercera}
                                onChange={this.escribir("preciokilogramostercera")}>
                              </input>
                            </InputGroup>
                          :
                            this.format_moneda(this.state.informaciondetalles.detalles.preciokilogramostercera)
                          }
                        </td>
                      </tr>
                      {/* Torreón */}
                      <tr>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Torreón
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Torreón
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Torreón
                        </td>
                      </tr>
                      <tr>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.format_entero(this.state.informaciondetalles.detalles.rejastorreon)} rejas
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <input
                                className={this.state.vermsgerrorkilogramotorreon ? 'txtInputKgConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.kilogramostorreon}
                                onChange={this.escribir("kilogramostorreon")}>
                              </input>
                              <InputGroupText className={this.state.vermsgerrorkilogramotorreon ? 'txtSimboloKgcConError': 'txtInputSimbolo'}>
                                kg
                              </InputGroupText>
                            </InputGroup>
                          :
                            this.format_kilogramos(this.state.informaciondetalles.detalles.kilogramostorreon)
                          }

                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <InputGroupText className={this.state.vermsgerrorpreciokilogramotorreon ? 'txtSimboloPrecioConError': 'txtInputSimbolo'}>
                                $
                              </InputGroupText>
                              <input
                                className={this.state.vermsgerrorpreciokilogramotorreon ? 'txtInputPrecioConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.preciokilogramostorreon}
                                onChange={this.escribir("preciokilogramostorreon")}>
                              </input>
                            </InputGroup>
                          :
                            this.format_moneda(this.state.informaciondetalles.detalles.preciokilogramostorreon)
                          }
                        </td>
                      </tr>
                      {/* Desecho */}
                      <tr>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Desecho
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Desecho
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Desecho
                        </td>
                      </tr>
                      <tr>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.format_entero(this.state.informaciondetalles.detalles.rejasdesecho)} rejas
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <input
                                className={this.state.vermsgerrorkilogramodesecho ? 'txtInputKgConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.kilogramosterceradesecho}
                                onChange={this.escribir("kilogramosterceradesecho")}>
                              </input>
                              <InputGroupText className={this.state.vermsgerrorkilogramodesecho ? 'txtSimboloKgcConError': 'txtInputSimbolo'}>
                                kg
                              </InputGroupText>
                            </InputGroup>
                          :
                            this.format_kilogramos(this.state.informaciondetalles.detalles.kilogramosterceradesecho)
                          }

                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <InputGroup>
                              <InputGroupText className={this.state.vermsgerrorpreciokilogramodesecho? 'txtSimboloPrecioConError': 'txtInputSimbolo'}>
                                $
                              </InputGroupText>
                              <input
                                className={this.state.vermsgerrorpreciokilogramodesecho ? 'txtInputPrecioConError': 'txtInputNumero'}
                                type="number"
                                value={this.state.informaciondetalles.detalles.preciokilogramosdesecho}
                                onChange={this.escribir("preciokilogramosdesecho")}>
                              </input>
                            </InputGroup>
                          :
                            this.format_moneda(this.state.informaciondetalles.detalles.preciokilogramosdesecho)
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }} colspan="3">
                          Merma
                        </td>
                      </tr>
                      <tr>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }} colspan="3">
                          {this.format_entero(this.state.rejasmerma)} rejas
                        </td>
                      </tr>
                      <tr>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          Gastos
                        </td>
                        <td className="lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }} colspan="2">
                          Factura
                        </td>
                      </tr>
                      <tr>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <input
                              className={this.state.vermsgerrorgastos ? 'txtInputNumeroConError': 'txtInputNumero'}
                              type="number"
                              value={this.state.informaciondetalles.detalles.gastos}
                              onChange={this.escribir("gastos")}>
                            </input>
                          :
                            this.format_moneda(this.state.informaciondetalles.detalles.gastos)
                          }

                        </td>
                        <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }} colspan="2">
                          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
                            <input
                              className={this.state.vermsgerrorfactura ? 'txtInputNumeroConError': 'txtInputNumero'}
                              type="text"
                              value={this.state.informaciondetalles.detalles.factura}
                              onChange={this.escribir("factura")}>
                            </input>
                          :
                            <>
                              {this.state.informaciondetalles.detalles.factura}
                            </>
                          }

                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* ////////////////////   Fin sección de corrida ////////////////////////////////////// */}

          {/* ////////////////////   Sección de totales  ////////////////////////////////////// */}
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1240px', maxWidth: '1240px'}}>

                <div className='row align-items-end' style={{ paddingBottom: '20px'}}>
                  <div className='col-12 minPaddingTopBottomAutoCot divMinWidthAutoCot'>
                    <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Totales</b></label>
                  </div>
                </div>
                
                <div className='divMinWidthAutoCot'>
                  <div className='contenedorTotales'>
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Rejas totales
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.rejas(this.state.rejascorridas)}
                      </div>
                    </div>
                    <br />
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Peso total
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.pesototal(this.state.pesocorrido)}
                      </div>
                    </div>
                    <br />
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Peso reja promedio
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.format_kilogramos(this.state.pesopromedio)}
                      </div>
                    </div>
                    <br />
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Rejas corridas
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.format_entero(this.state.totalrejascorridas)}
                      </div>
                    </div>
                    <br />
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Peso total corrido
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.format_kilogramos(this.state.totalpesocorrido)}
                      </div>
                    </div>
                    <br />
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Merma
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.format_kilogramos(this.state.pesomerma)}
                      </div>
                    </div>
                    <br />
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Diferencia
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.format_kilogramos(0.00)}
                      </div>
                    </div>
                    <br />
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Rendimiento
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.format_porcentaje(this.state.rendimiento)}
                      </div>
                    </div>
                    <br />
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Subtotal
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.format_moneda(this.state.totallimones)}
                      </div>
                    </div>
                    <br />
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Gastos
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.format_moneda(this.state.informaciondetalles.detalles.gastos)}
                      </div>
                    </div>
                    <br />
                    <div className='seccionTotales'>
                      <div className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', width: '200px' }}>
                        Total
                      </div>
                      <div className="letraNormal lblSubtabla"  style={{ fontWeight: 'bold', width: '100px', textAlign: 'right' }}>
                        {this.format_moneda(this.state.total)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          {/* ////////////////////   Sección de botones ////////////////////////////////////// */}
          {this.state.informaciondetalles.detalles.idestatus!= 4 ?
            <>
              <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
              </div>
              <br />
              <div className="divBtnContenedor" >
                  <Button className="btnCancelModal" onClick={() => this.regresar()}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardardatos()} disabled={!this.state.confirmarenvio} >Guardar</Button>
              </div>
            </>
          :null}
        </div>
        <br />
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoordencosecha"} />)
  }
  else if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
  else if (bandera == 'regresar') {
    return (<Redirect from='/' to={"/catalogocorridalimon"} />)
  }
}
