import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalFormularioAlmacenCombustible.css'
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarNumerosDecimalesAlmacenCombustible, validarLetrasNumerosLongitudV2, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import { updateMovimientoAlmacenCombustible } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { BsQuestionSquare } from 'react-icons/bs';
import ReactTooltip from "react-tooltip";
import 'react-toastify/dist/ReactToastify.css';
import { Table } from 'reactstrap';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioNuevoMovimiento extends React.Component {

  constructor(props) {
    super(props)
console.log(props)
    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      listatipomovimientos: props.listatipomovimientos,
      listamovimientos: props.listamovimientos,
      idcatalogoalmacenescombustible: props.informacion.idcatalogoalmacenescombustible,


      idtipomovimiento: (props.accion == 2) ? (props.item.idtipo_equipo) : todoslosfiltrosdisponibles,
      vermsgerroridtipomovimiento: false,
      msgerroridtipomovimiento: "",

      idmovimiento: (props.accion == 2) ? (props.item.idtipo_equipo) : todoslosfiltrosdisponibles,
      vermsgerroridmovimiento: false,
      msgerroridmovimiento: "",

      listacombustiblesmovimientos: props.informacion.combustibles ? this.generarLista(props.informacion.combustibles) : [],
      vermsgerrorlista: false,
      msgerrorlista: '',

      txtdescripcion: (props.accion == 2) ? (props.item.descripcion) : '',
      vermsgerrortxtdescripcion: false,
      msgerrortxtdescripcion: "",

      tabla: {
        encabezados: [
          "Combustible",
          "Cantidad",
          "Precio",
          "Unidad de medida"
        ],
        dimensiones: [
          "30%",
          "25%",
          "25%",
          "20%"
        ],
        mindimensiones: [
          "200px",
          "200px",
          "200px",
          "140px"
        ],
        titulo: 'Documentos'
      },

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo
    }

  }

  generarLista(combustibles){
    let lista=[];
    combustibles.forEach(item => {
      lista.push({
        ...item, precio:0, cantidad:0
      })
    });

    console.log("los combustibles", lista)

    return combustibles
   
  }

  setLoadingSpinner = (bandera) => {
    this.props.setLoadingSpinner(bandera);
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  escribirEnTabla = (indice,pos) => (event) => {

    console.log("indice",indice, "pos", pos, event.target.value)
    if (this.state.listacombustiblesmovimientos[indice]) {
      var listacombustiblesmovimientos = this.state.listacombustiblesmovimientos
      listacombustiblesmovimientos[indice][pos] = event.target.value
      this.setState({ listacombustiblesmovimientos: listacombustiblesmovimientos })
    }
  }

  cambiarTipo = (item) => {
    if (item.tipo == 'idtipomovimiento') {
      this.setState({ 'idmovimiento': todoslosfiltrosdisponibles })
    }
    this.setState({ [item.tipo]: item.valor })
  }

  guardarCambios = async () => {

    this.setState({ vermsgerroridtipomovimiento: false })
    this.setState({ vermsgerroridmovimiento: false })
    this.setState({ vermsgerrorlista: false })
    this.setState({ vermsgerrortxtdescripcion: false })
    let validacionescorrectas = true

    if (this.state.idtipomovimiento == todoslosfiltrosdisponibles) {
      this.setState({ msgerroridtipomovimiento: "Este campo es obligatorio" })
      this.setState({ vermsgerroridtipomovimiento: true })
      validacionescorrectas = false;
    } else if (this.state.idtipomovimiento != 1) {
      this.setState({ msgerroridtipomovimiento: 'Solo se permite: "Ingreso"' })
      this.setState({ vermsgerroridtipomovimiento: true })
      validacionescorrectas = false;
    }

    if (this.state.idmovimiento == todoslosfiltrosdisponibles) {
      this.setState({ msgerroridmovimiento: "Este campo es obligatorio" })
      this.setState({ vermsgerroridmovimiento: true })
      validacionescorrectas = false;
    } else if (this.state.idmovimiento != 1) {
      this.setState({ msgerroridmovimiento: 'Solo se permite: "Ingreso manual"' })
      this.setState({ vermsgerroridmovimiento: true })
      validacionescorrectas = false;
    }

    let longitudvalida = { longitudminimaentera: 0, longitudmaximaentera: 7, longitudminimadecimal: 1, longitudmaximadecimal: 2 }
    this.state.listacombustiblesmovimientos.map(item => {
      item.fondo = 'white'
      if (item.cantidad && item.cantidad.trim().length > 0) {
        let txtcantidad = validarNumerosDecimalesAlmacenCombustible(item.cantidad.trim(), longitudvalida)
        if (txtcantidad.codigo == "invalid") {
          this.setState({ msgerrortxtminimo: txtcantidad.mensaje })
          validacionescorrectas = false;
          item.fondo = '#fa9393'
          toast.error('[' + item.combustible + '] ' + txtcantidad.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
      }
      return item
    })
    var listacombustiblesenviar = this.state.listacombustiblesmovimientos.filter(item => item.cantidad && item.cantidad.trim().length > 0 && Number(item.cantidad)>0 && item.precio && item.precio.trin().length>0 && Number(item.precio)>0)
    if (listacombustiblesenviar.length == 0) {
      this.setState({ msgerrorlista: 'Es necesario modificar la cantidad y el precio de al menos un combustible' })
      this.setState({ vermsgerrorlista: true })
      validacionescorrectas = false
    }

    if (this.state.txtdescripcion.trim().length > 0) {
      longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
      let txtdescripcion = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida)
      if (txtdescripcion.codigo == "invalid") {
        this.setState({ msgerrortxtdescripcion: txtdescripcion.mensaje })
        this.setState({ vermsgerrortxtdescripcion: true })
        validacionescorrectas = false;
      }
    }


    if (validacionescorrectas) {
      this.setLoadingSpinner(true)
      listacombustiblesenviar = listacombustiblesenviar.map(item => {
        return {
          "idcombustible_catalogoalmacenescombustible": item.idcombustible,
          //"idcombustible": item.idcombustible,
          "cantidad": Number(item.cantidad),
          "precio": this.state.idtipomovimiento == 1 ? Number(item.precio):0
        }
      })
      let json = {
        "idcatalogoalmacenescombustible": this.state.idcatalogoalmacenescombustible,
        "idmovimiento": Number(this.state.idmovimiento),
        "comentario": this.state.txtdescripcion.trim(),
        "combustibles": listacombustiblesenviar
      }
      var resp = await updateMovimientoAlmacenCombustible(json);

      if (resp == -1 || resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setLoadingSpinner(false)
        return true
      }
      else if (resp.code == 200 || resp.code == 300) {
        this.setLoadingSpinner(false)
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return true
      }
      else {
        toast.success("Movimiento realizado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(1)
      }
    }

  }

  render() {
    return (

      <>
        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="lg"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: '0' })} />
            <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                <label className="modalLblTitulo letraTitulos">¿Qué movimiento quieres hacer?</label>
                <div className='row align-items-end ' style={{ paddingTop: '10px' }}>
                  <div className='col-6' style={{ paddingBottom: (!this.state.vermsgerroridtipomovimiento && this.state.vermsgerroridmovimiento) ? '40px' : '' }}>
                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo de movimiento</p>
                    {this.state.accion == 2 || this.state.accion == 1 ? (
                      <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>
                        <div className="selectBox ">
                          <select className="cbxTexto letraNormal" onChange={(event) => this.cambiarTipo({ tipo: "idtipomovimiento", valor: event.target.value })}>
                            <option value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idtipomovimiento} >Seleccionar </option>
                            {this.state.listatipomovimientos.map((i, index) => (
                              <option value={i.idtipo} key={"tipo_" + index} selected={i.idtipo == this.state.idtipomovimiento} >{i.tipo} </option>
                            ))
                            }
                          </select>
                        </div>
                      </div>
                    ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                    {this.state.vermsgerroridtipomovimiento ? <p className="msgError letraNormal"
                      style={{ color: this.state.colormsgerror }}>{this.state.msgerroridtipomovimiento}</p> : null}
                  </div>

                  <div className='col-6'>
                    <p className='lblSubTitulosModal letraTitulos labelSelector' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Movimiento</p>
                    {this.state.accion == 2 || this.state.accion == 1 ? (
                      <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>
                        <div className="selectBox ">
                          <select className="cbxTexto letraNormal" onChange={(event) => this.cambiarTipo({ tipo: "idmovimiento", valor: event.target.value })}>
                            <option value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idmovimiento} >
                              {(todoslosfiltrosdisponibles == this.state.idtipomovimiento) ? 'Seleccionar tipo movimiento' : 'Seleccionar'}
                            </option>
                            {this.state.listamovimientos.filter((item) => item.idtipo == this.state.idtipomovimiento).map((i, index) => (
                              <option value={i.id_movimientos_combustible} key={"tipo_" + index} selected={i.id_movimientos_combustible == this.state.idmovimiento} >{i.nombre} </option>
                            ))
                            }
                          </select>
                        </div>
                      </div>
                    ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                    {this.state.vermsgerroridmovimiento ? <p className="msgError letraNormal"
                      style={{ color: this.state.colormsgerror }}>{this.state.msgerroridmovimiento}</p> : null}
                  </div>
                </div>

              </div>

              <br />

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                <label className="modalLblTitulo letraTitulos">Combustibles</label>

                <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>
                  <div className="container-fluid anchoMaximo" style={{ margin: '0px', padding: '0px' }}>
                    <div className="containerSubTablaaa" style={{ minWidth: '500px', padding: "4px" }}>
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{
                                  minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                                  textAlign: (index > 0) ? 'center' : ''
                                }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                          {this.state.listacombustiblesmovimientos.map((item, index) => (
                            <tr key={"tabla" + index} style={{ background: item.fondo }}>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorcontenidotabla }}>
                                {item.tipo}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorcontenidotabla }}>
                                <input className='inTextoModal letraNormal'
                                  style={{
                                    color: this.state.estilosmodal.colorlbltextomodal,
                                    backgroundColor: this.state.estilosmodal.fondocontenedormodal,
                                    border: this.state.estilosmodal.colorborderbottom,//" solid 1px #8f8f90"
                                    //borderColor: (item.tieneerror) ? '#DD2211' : '#00_00_00',
                                    //border: (item.tieneerror) ? 'solid 2px #FF1212' : 'solid 1px #8f8f90',
                                    textAlign: 'center'
                                  }}
                                  type={'text'} value={item.cantidad}
                                  placeholder='0'
                                  onChange={this.escribirEnTabla(index,"cantidad")}></input>
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorcontenidotabla }}>
                                {this.state.idtipomovimiento==1?
                                  <input className='inTextoModal letraNormal'
                                  style={{
                                    color: this.state.estilosmodal.colorlbltextomodal,
                                    backgroundColor: this.state.estilosmodal.fondocontenedormodal,
                                    border: this.state.estilosmodal.colorborderbottom,//" solid 1px #8f8f90"                                   
                                    textAlign: 'center'
                                  }}
                                  type={'text'} value={item.precio}
                                  placeholder='0'                                  
                                  onChange={this.escribirEnTabla(index, "precio")}></input>
                                  :<label style={{textAlign:"center", width:"100%"}}>Default</label>}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                {'l'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                {this.state.vermsgerrorlista ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrorlista}</p> : null}


                <label className="modalLblTitulo letraTitulos" style={{ marginTop: '15px', marginBottom: '15px' }}>Comentario</label>
                <textarea className='areaTextoModal letraNormal ' rows="3" id='comentarios'
                  value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></textarea>

                {this.state.vermsgerrortxtdescripcion ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdescripcion}</p> : null}

              </div>


              {this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null}
            </div>

            {this.state.accion == 2 || this.state.accion == 1 ? (
              <>
                <br />
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedorModal" >
                  <Button className="btnCancelModal" onClick={() => this.showModal({ accion: '0' })}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>
            ) : null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}

