import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioESCuadrillas.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarSoloLetrasYEspacios, validarNumeros, validarLongitud } from '../../../Services/Validaciones';
import { getListaTipoActividades, getListaActividades, postDetallesBitacoraCuadrillaUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import InputHora from '../../InputHora/InputHora'
import "react-datepicker/dist/react-datepicker.css";
import ModalSeleccionActividades from '../ModalOrdenTrabajo/ModalSeleccionActividades';

export default class ModalFormularioESCuadrilla extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            loadingspinners: false,
            modalactividades: false,

            txtrancho:parseInt(props.idrancho),
            listalotes:props.listalotes,
            listaactividades:props.listaactividades,
            listatipoactividades:[],
            listacuadrillas:props.listacuadrillas,
            itemseleccionado: props.item,

            idcatalogo_bitacora_cuadrillas_detalles: (props.accion != 1) ? (props.item.idcatalogo_bitacora_cuadrillas_detalles) + "" : '0',
            idcatalogo_cuadrillas: (props.accion != 1) ? (props.item.idcatalogo_cuadrillas) : '0',
            txthoraentrada: (props.accion != 1) ? (props.item.horaentrada) : '00:00',
            txtlote:(props.accion != 1) ? (props.item.idcatalogo_ranchos_lotes_cultivo) : 0,
            txtresponsable: (props.accion != 1) ? (props.item.idcatalogo_cuadrillas) + "" : '',
            txtnumpersonas: (props.accion != 1) ? (props.item.personas) + "": '',
            idcultivo: (props.accion != 1) ? (props.item.idcatalogo_cultivo) + "": '',
            idtipocultivo:0,
            txtcultivo: (props.accion != 1) ? (props.item.cultivo) + "": '',
            txtactividad: (props.accion != 1) ?
            {
                actividad: (props.item.actividad),
                idactividad: (props.item.idcatalogo_actividad),
            }  : '',
            txtvehiculo: (props.accion != 1) ? (props.item.vehiculo) : '',
            txtconductor: (props.accion != 1) ? (props.item.conductor) : '',
            txtplacas: (props.accion != 1) ? (props.item.placas) : '',
            txthorasalida: (props.accion != 1) ? (props.item.horasalida) : '00:00',

            vermsgerrortxthoraentrada: false,
            vermsgerrortxtresponsable: false,
            vermsgerrortxtnumpersonas: false,
            vermsgerrortxtlote: false,
            vermsgerrortxtactividad: false,
            vermsgerrortxtvehiculo: false,
            vermsgerrortxtconductor: false,
            vermsgerrortxtplacas: false,
            vermsgerrortxthorasalida: false,

            msgerrortxthoraentrada: "",
            msgerrortxtresponsable: "",
            msgerrortxtnumpersonas: "",
            msgerrortxtlote: "",
            msgerrortxtactividad: "",
            msgerrortxtvehiculo: "",
            msgerrortxtconductor: "",
            msgerrortxtplacas: "",
            msgerrortxthorasalida: "",

            arrayestatus: props.estatus,
            idestatus: 1,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            listazonas: props.listazonas,
            listaranchos: props.listaranchos,
            listacultivos: props.listacultivos,
            listatipocultivos: props.listatipocultivos,
        }
    }

    async componentDidMount() {
        this.cambiarValor((this.props.accion == 2) ? (this.props.item.idestatus) : this.state.idestatus)

        // Datos para modal "Seleccionar actividades"
        var resplistaactividades = await getListaActividades()
        if (this.manejadorLlamadasApis(resplistaactividades)) {
            return;
        }

        var resplistatipoactividades = await getListaTipoActividades()
        if (this.manejadorLlamadasApis(resplistatipoactividades)) {
            return;
        }

        this.setState({
            listatipoactividades:resplistatipoactividades.data,
            listaactividades: resplistaactividades.data
        })
        this.seleccionarCultivo(this.state.idcultivo)
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    /*escribirHoraEntrada = (hora) =>{
        this.setState({ txthoraentrada: hora});
    }

    escribirHoraSalida = (hora) =>{
        this.setState({ txthorasalida: hora});
    }*/

    seleccionarLote = (event) =>{
        const id  = event.target.value;
        if(this.state.txtlote != id){
            this.setState({txtresponsable:0})
        }
        this.setState({txtlote:id})
        this.state.listalotes.map((lote) => {
            if(id == lote.idcatalogo_ranchos_lotes_cultivo){
                this.seleccionarCultivo(lote.idcatalogo_cultivo)
            }
        });
    }

    seleccionarCultivo = (id) =>{
        this.state.listacultivos.map((cultivo) => {
            if(id ==  cultivo.idcatalogo_cultivo){
                this.setState({idcultivo:cultivo.idcatalogo_cultivo, txtcultivo:cultivo.cultivo, idtipocultivo:cultivo.idtipo_cultivo})
            }
        });
    }

    verModalActividades = (ban) => {
        if (ban.opcion == 1) { //Nuevo
            this.setState({ titulomodal: 'Selecciona una actividad', accionmodal: 1, modalactividades: true })
        }
    }

    cerrarModalActividades = (ban) => {
        this.setState({ modalactividades: !this.state.modalactividades })
        if (ban != 0) {
            var actividad = Object.values(ban)[0]

            let actividadseleccionada = actividad != undefined ? actividad : ''
            this.setState({ txtactividad: actividadseleccionada })
        }
    }

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    actualizarHoraEntrada = (obj) =>{
        let fechaactual= this.state.txthoraentrada.split(":")   

        if(obj.state=="horainicial"){
            fechaactual[0]=obj.valor
        }
        if(obj.state=="minutoinicial"){
            fechaactual[1]=obj.valor
        }

        this.setState({txthoraentrada:fechaactual[0]+":"+fechaactual[1]})

    }

    actualizarHoraSalida = (obj) =>{
        let fechaactual= this.state.txthorasalida.split(":")   

        if(obj.state=="horafinal"){
            fechaactual[0]=obj.valor
        }
        if(obj.state=="minutofinal"){
            fechaactual[1]=obj.valor
        }

        this.setState({txthorasalida:fechaactual[0]+":"+fechaactual[1]})

    }

    guardarCambios = async () => {

        this.setState({ vermsgerrortxthoraentrada: false })
        this.setState({ vermsgerrortxtresponsable: false })
        this.setState({ vermsgerrortxtnumpersonas: false })
        this.setState({ vermsgerrortxtcultivo: false })
        this.setState({ vermsgerrortxtactividad: false })
        this.setState({ vermsgerrortxtvehiculo: false })
        this.setState({ vermsgerrortxtconductor: false })
        this.setState({ vermsgerrortxtplacas: false })
        this.setState({ vermsgerrortxtlote: false })
        this.setState({ vermsgerrortxthorasalida: false })

        let validacionescorrectas = true
        let longitudvalida = ""
        let textvalid = ""

        if (this.state.txthoraentrada == "") {
            this.setState({ msgerrortxthoraentrada: "Selecione la hora de entrada" })
            this.setState({ vermsgerrortxthoraentrada: true })
            validacionescorrectas = false;
        }

        if (this.state.txtlote < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortxtlote: "Seleccione un lote" })
            this.setState({ vermsgerrortxtlote: true })
        }

        if (this.state.txtresponsable == "") {
            this.setState({ msgerrortxtresponsable: "Selecione una cuadrilla" })
            this.setState({ vermsgerrortxtresponsable: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 4};
        textvalid = validarNumeros(this.state.txtnumpersonas.trim(), longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrortxtnumpersonas: textvalid.mensaje })
            this.setState({ vermsgerrortxtnumpersonas: true })
            validacionescorrectas = false;
        }

        if (this.state.txtactividad < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortxtactividad: "Seleccione una actividad" })
            this.setState({ vermsgerrortxtactividad: true })
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 100};
        textvalid = validarLongitud(this.state.txtvehiculo.trim(), longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrortxtvehiculo: textvalid.mensaje })
            this.setState({ vermsgerrortxtvehiculo: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 100};
        textvalid = validarSoloLetrasYEspacios(this.state.txtconductor.trim(), longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrortxtconductor: textvalid.mensaje })
            this.setState({ vermsgerrortxtconductor: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 20};
        textvalid = validarLongitud(this.state.txtplacas.trim(), longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrortxtplacas: textvalid.mensaje })
            this.setState({ vermsgerrortxtplacas: true })
            validacionescorrectas = false;
        }

        if (this.state.txthorasalida == "") {
            this.setState({ msgerrortxthorasalida: "Selecione la hora de salida" })
            this.setState({ vermsgerrortxthorasalida: true })
            validacionescorrectas = false;
        }

        if (validacionescorrectas) {
            this.setState({ loadingspinners: false })
            let json = {
                "idcatalogo_bitacora_cuadrillas": this.props.idcuadrilla + "",
                "idcatalogo_bitacora_cuadrillas_detalles": this.state.idcatalogo_bitacora_cuadrillas_detalles,
                "idcatalogo_actividad": this.state.txtactividad.idactividad + "",
                "idcatalogo_cuadrillas": this.state.txtresponsable,
                "idcatalogo_ranchos_lotes_cultivo": this.state.txtlote,
                "personas": this.state.txtnumpersonas,
                "conductor": this.state.txtconductor,
                "vehiculo": this.state.txtvehiculo,
                "placas": this.state.txtplacas,
                "horaentrada": this.state.txthoraentrada,
                "horasalida": this.state.txthorasalida,
                "eliminar": this.state.accion == 3 ? "1" : "0"
            }

            var resp = await postDetallesBitacoraCuadrillaUpdate(json);
            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio,loadingspinners: false  })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio,loadingspinners: false  })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio,loadingspinners: false  })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio,loadingspinners: false  })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
            else{
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio,loadingspinners: false  })
                toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }

    }

    render() {
        return (
            <>
                {/*///////// Sección de modales, spinner y redireccionamiento ////////*/}
                <ToastContainer />
                {(this.state.loadingspinners == true) ?
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                :null}

                {this.state.modalactividades ?
                    <ModalSeleccionActividades
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModalActividades}
                        listaactividades={this.state.listaactividades}
                        listatipoactividades={this.state.listatipoactividades}
                        show={this.state.modalactividades}
                        accion={true}
                    />
                :null}

                <Modal
                    isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
                        <br />
                        <br />

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/* ////////////////////   Sección de hora de entrada   ////////////////////////////////////// * /}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Hora de entrada</p>
                                <InputHora
                                    hora={this.state.txthoraentrada}
                                    escribir={this.escribirHoraEntrada}
                                    accion={this.props.accion}
                                />

                                {this.state.vermsgerrortxthoraentrada ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxthoraentrada}</p>
                                :null*/}


                                {/* ////////////////////   Sección de hora entrada   /////////////////////////////////// */}
                                <div style={{display:"flex", marginBottom:"20px"}}>
                                    <div className='col-6 subEncabezado d-flex flex-column justify-content-center ' style={{width:"47%", marginLeft:"0px", marginRight:"auto"}}>
                                        <label className=" lblSubTitulosModal letraTitulos" style={{color: this.state.estilosmodal.colorlblsubtitulosmodal}}>Hora entrada: </label>
                                        <select
                                            className=" "
                                            style={{border:"1px solid black", paddingLeft:"0px", paddingRight:"20px", paddingTop:"10px", paddingBottom:"10px", outline:"none"}}
                                            value={this.state.horainicial}
                                            onChange={(e) => this.actualizarHoraEntrada({ accionorigen: 2, state: "horainicial", valor: e.target.value })}
                                        >
                                            {/* Genera las opciones de hora inicial del 00 al 23 */}
                                            {Array.from({ length: 24 }, (_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')} selected={this.state.txthoraentrada.split(":")[0]==i.toString().padStart(2, '0') ? true:false} >
                                                    {i.toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-6  subEncabezado d-flex flex-column justify-content-end 'style={{width:"47%", marginLeft:"auto", marginRight:"0px"}} >
                                        <label className=" lblSubTitulosModal letraTitulos" style={{color: this.state.estilosmodal.colorlblsubtitulosmodal}} >Minuto entrada:</label>
                                        <select
                                            className=" "
                                            style={{border:"1px solid black", paddingLeft:"0px", paddingRight:"20px", paddingTop:"10px", paddingBottom:"10px", outline:"none"}}
                                            value={this.state.minutoinicial}
                                            onChange={(e) => this.actualizarHoraEntrada({ accionorigen: 2, state: "minutoinicial", valor: e.target.value })}
                                        >
                                            {/* Genera las opciones de minutos del 00 al 59 */}
                                            {Array.from({ length: 60 }, (_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')} selected={this.state.txthoraentrada.split(":")[1]==i.toString().padStart(2, '0') ? true:false}>
                                                    {i.toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {this.state.vermsgerrortxthoraentrada ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxthoraentrada}</p>
                                :null}

















































                                {/* ////////////////////   Sección de lote   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Lote </p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.seleccionarLote}>
                                                <option disabled="disabled" selected={this.state.txtlote==0 ? true : false}>Seleccionar</option>
                                                {this.state.listalotes == undefined || this.state.listalotes.length == 0 ?
                                                    null
                                                :
                                                    this.state.listalotes.map((i) => (
                                                        i.idcatalogo_rancho === this.state.txtrancho ?
                                                            <option
                                                                value={i.idcatalogo_ranchos_lotes_cultivo}
                                                                key={"tipo_" + i.idcatalogo_ranchos_lotes_cultivo}
                                                                selected={i.idcatalogo_ranchos_lotes_cultivo==this.state.txtlote ? true : false}
                                                            >
                                                                {i.lote}
                                                            </option>
                                                        :
                                                            null
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrortxtlote ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtlote}</p>
                                :null}

                                {/* ////////////////////   Sección de cultivo   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Cultivo</p>

                                {this.state.txtlote !== 0 ?
                                    <input
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'}
                                        value={this.state.txtcultivo}
                                        readOnly>
                                    </input>
                                :
                                    <input
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'}
                                        placeholder="Elige un lote"
                                        readOnly>
                                    </input>
                                }

                                {/* ////////////////////   Sección de responsable de cuadrilla   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Responsable de cuadrilla</p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtresponsable")}>
                                                <option disabled="disabled" selected={this.state.txtresponsable==0 ? true : false}>Seleccionar</option>
                                                {this.state.listacuadrillas == undefined || this.state.listacuadrillas.length == 0 ?
                                                    null
                                                :
                                                    this.state.listacuadrillas.map((i) => (
                                                        i.cultivos == undefined || i.cultivos.length == 0 ?
                                                            null
                                                        :
                                                            i.cultivos.map((cultivo) => (
                                                                this.state.idtipocultivo == cultivo.idtipo_cultivo ?
                                                                    <option
                                                                        value={i.idcatalogo_cuadrillas}
                                                                        key={"tipo_" + i.idcatalogo_cuadrillas}
                                                                        selected={this.state.txtresponsable == i.idcatalogo_cuadrillas ? true : false}
                                                                    >
                                                                            {i.nombre}
                                                                    </option>
                                                                :null
                                                            ))
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrortxtresponsable ?
                                    <p className="msgError letraNormal"  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtresponsable}</p>
                                :null}

                                {/* ////////////////////   Sección de número de personas   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}># Personas</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtnumpersonas}
                                    onChange={this.escribir("txtnumpersonas")}>
                                </input>

                                {this.state.vermsgerrortxtnumpersonas ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnumpersonas}</p>
                                :null}

                                {/* ////////////////////   Sección de actividad   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Actividad</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtactividad.actividad}
                                    placeholder='Selecciona una actividad'
                                    onClick={this.verModalActividades.bind(this, { opcion: "1" })}>
                                </input>

                                {this.state.vermsgerrortxtactividad ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtactividad}</p>
                                :null}

                                {/* ////////////////////   Sección de vehículo   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Vehículo</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtvehiculo}
                                    onChange={this.escribir("txtvehiculo")}>
                                </input>

                                {this.state.vermsgerrortxtvehiculo ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtvehiculo}</p>
                                :null}

                                {/* ////////////////////   Sección de conductor   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Conductor</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtconductor}
                                    onChange={this.escribir("txtconductor")}>
                                </input>

                                {this.state.vermsgerrortxtconductor ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtconductor}</p>
                                :null}

                                {/* ////////////////////   Sección de placas   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Placas</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtplacas}
                                    onChange={this.escribir("txtplacas")}>
                                </input>

                                {this.state.vermsgerrortxtplacas ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtplacas}</p>
                                :null}

                                {/* ////////////////////   Sección de hora de salida   ////////////////////////////////////// * /}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Hora de salida</p>
                                <InputHora
                                    hora={this.state.txthorasalida}
                                    escribir={this.escribirHoraSalida}
                                    accion={this.props.accion}
                                />

                                {this.state.vermsgerrortxthorasalida ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxthorasalida}</p>
                                :null*/}


                                {/* ////////////////////   Sección de hora salida   /////////////////////////////////// */}
                                <div style={{display:"flex", marginBottom:"20px"}}>
                                    <div className='col-6 subEncabezado d-flex flex-column justify-content-center ' style={{width:"47%", marginLeft:"0px", marginRight:"auto"}}>
                                        <label className=" lblSubTitulosModal letraTitulos" style={{color: this.state.estilosmodal.colorlblsubtitulosmodal}}>Hora salida: </label>
                                        <select
                                            className=" "
                                            style={{border:"1px solid black", paddingLeft:"0px", paddingRight:"20px", paddingTop:"10px", paddingBottom:"10px", outline:"none"}}
                                            value={this.state.horafinal}
                                            onChange={(e) => this.actualizarHoraSalida({ accionorigen: 2, state: "horafinal", valor: e.target.value })}
                                        >
                                            {/* Genera las opciones de hora inicial del 00 al 23 */}
                                            {Array.from({ length: 24 }, (_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')} selected={this.state.txthorasalida.split(":")[0]==i.toString().padStart(2, '0') ? true:false} >
                                                    {i.toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-6  subEncabezado d-flex flex-column justify-content-end 'style={{width:"47%", marginLeft:"auto", marginRight:"0px"}} >
                                        <label className=" lblSubTitulosModal letraTitulos" style={{color: this.state.estilosmodal.colorlblsubtitulosmodal}} >Minuto salida:</label>
                                        <select
                                            className=" "
                                            style={{border:"1px solid black", paddingLeft:"0px", paddingRight:"20px", paddingTop:"10px", paddingBottom:"10px", outline:"none"}}
                                            value={this.state.minutofinal}
                                            onChange={(e) => this.actualizarHoraSalida({ accionorigen: 2, state: "minutofinal", valor: e.target.value })}
                                        >
                                            {/* Genera las opciones de minutos del 00 al 59 */}
                                            {Array.from({ length: 60 }, (_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')} selected={this.state.txthorasalida.split(":")[1]==i.toString().padStart(2, '0') ? true:false}>
                                                    {i.toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {this.state.vermsgerrortxthoraentrada ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxthoraentrada}</p>
                                :null}











































                            </div>
                        </div>

                        {/* ////////////////////   Sección de botones   ////////////////////////////////////// */}
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}