import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoNomina.css';
import { BiEdit, BiSearchAlt, BiSearchAlt2 } from "react-icons/bi";
import { FaKey, FaCheck, FaCalendar } from "react-icons/fa";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table, Button } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloTabla, estiloBtnVerde, estiloBtnRojo, estiloBtnRestablecer } from '../../Services/PaletaDeColores';
import ModalFormularioUsuarios from '../../Complements/Modales/ModalFormularioUsuarios/ModalFormularioUsuarios';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus,  getCatalogoNominaSeguridad, createNuevaNominaSeguridad } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Calendario from '../../Complements/calendario/Calendario';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCalendarFill, BsFillEyeFill } from 'react-icons/bs';
import { format } from 'date-fns';
import ModalAutorizacion from '../../Complements/Modales/ModalAutorizacion/ModalAutorizacion';
import ModalNuevaNominaSeguridad from './ModalNuevaNominaSeguridad';



export default class CatalogoNominaSeguridad extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            salir: false,
            txtbuscador: '',
            altura: window.innerHeight,
            anchura: window.innerWidth,
            loadingspinners: true,
            confirmarenvio: false,
            bandera_periodo: "activo",


            tabla: {
                encabezados: [
                    "ID",
                    "Fecha Inicio",
                    "Fecha Fin",

                    "Salarios",
                    "Extras",
                    "Total",

                    "Estatus",
                    ""],
                dimensiones: [
                    "8%",
                    "15%",
                    "15%",
                    "15%",

                    "10%",
                    "10%",
                    "10%",
                    "7%"
                ],
                mindimensiones: [
                    "90px",
                    "130px",
                    "130px",
                    "130px",

                    "110px",
                    "100px",
                    "100px",
                    "120px",

                ],
                elementos: []
            },
            estatus: [],
            listaelementosfiltrados: [],
            zonaseleccionado: "TodasLasZonas",
            ranchoseleccionado: "TodosLosRanchos",
            encargadoseleccionado: "TodosLosEncargados",
            listatipousuario: [],

            accionmodal: 1,
            titulomodal: '',
            modaldetalles: false,
            itemseleccionado: null,

            idseleccionado: "",
            verdetallesnomina: false,
            nuevodetallesnomina: false,
            VerModalNuevaNomina: false,

            errordeacceso: false,
            total_salarial: 0,
            total_otras: 0,
            total_salario: 0,

            //paginacion
            activepage: 1,
            itemsperpage: 50,

            listapaginada: [],


            modalautorizacion: false,
            modalautorizaciontitulo: "",

            fechabusquedainicio: new Date(),
            fechabusquedafin: new Date(),

            txtfechabusquedainicio: "",
            txtfechabusquedafin: "",

            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortitulo: estiloFiltroEncabezados().colortitulos,
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            colorencabezadostabla: estiloTabla().colorencabezadostabla,
            colorcontenidotabla: estiloTabla().colorcontenidotabla,

            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
            colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

            btnfondoeditar: estiloBtnEditar().btnfondoeditar,
            btncoloreditar: estiloBtnEditar().btncoloreditar,
            btnbordereditar: estiloBtnEditar().btnbordereditar,

            colorfondobtnverde: estiloBtnVerde().colorfondobtnverde,
            colorbtnverde: estiloBtnVerde().colorbtnverde,
            colorbordebtnverde: estiloBtnVerde().colorbordebtnverde,

            colorfondobtnrojo: estiloBtnRojo().colorfondobtnrojo,
            colorbtnrojo: estiloBtnRojo().colorbtnrojo,
            colorbordebtnrojo: estiloBtnRojo().colorbordebtnrojo,

            colorfondobtnrestablecer: estiloBtnRestablecer().colorfondobtnrestablecer,
            colorbtnrestablecer: estiloBtnRestablecer().colorbtnrestablecer,
            colorbordebtnrestablecer: estiloBtnRestablecer().colorbordebtnrestablecer,

        }
    }


    async componentDidMount(ban, json) {

        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
        }

        var estatus = await getEstatus();
        if (this.manejadorLlamadasApis(estatus)) {
            return;
        }

        var fechaactualFin = new Date();
        const fechaa = fechaactualFin.getDate();
        fechaactualFin.setDate(fechaa);
        const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

        var fechaactualIni = new Date();
        fechaactualIni.setDate(fechaactualIni.getDate() - 7);
        const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');

        await this.setState({
            txtfechabusquedainicio: defaultValueFechaInicial,
            txtfechabusquedafin: defaultValue,
            fechabusquedainicio: fechaactualIni,
            fechabusquedafin:fechaactualFin
        })

        setTimeout(() => {
            this.consultarCatalogoNominaSeguridad()
        }, 300);

        await this.setState({
            txtbuscador: '',
            
            estatus: estatus.data,
            confirmarenvio: false,

        })

        this.dimensiones();
        this.cambiarContenido(1);
        setTimeout(() => {
            this.sumardatos()
        }, 600);


        window.addEventListener("resize", this.dimensiones);
        this.setState({ loadingspinners: false })

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    consultarCatalogoNominaSeguridad = async (llave, valor) => {
       
        var formatofechaini = "";
        var formatofechafin = "";
        var fechainiaux = 'txtfechabusquedainicio' == llave ? valor : this.state.txtfechabusquedainicio
        var fechafinaux = 'txtfechabusquedafin' == llave ? valor : this.state.txtfechabusquedafin

        if (fechainiaux.length > 0) {
        var [year, month, day] = fechainiaux.split("-");
        formatofechaini = `${day}/${month}/${year}`
        }

        if (fechafinaux.length > 0) {
        var [year, month, day] = fechafinaux.split("-");
        formatofechafin = `${day}/${month}/${year}`
        }

        var requestbody = {
            fechainicio: formatofechaini,
            fechafin: formatofechafin,
        }
        
        var fechasvalidas = await this.validarFechas(formatofechaini, formatofechafin)
        
        if (fechasvalidas == 1) {
            this.setState({ loadingspinners: true })
            var respcotizacionnaranja = await getCatalogoNominaSeguridad(requestbody);
            if (this.manejadorLlamadasApis(respcotizacionnaranja)) {
                return;
            }

            var tabla = this.state.tabla;
            tabla.elementos = respcotizacionnaranja.data;
            this.setState({
                tabla: tabla
            })
            //Fin
            this.cambiarContenido(1);
            this.setState({ loadingspinners: false })
        }

    }

    validarFechas = async (formatofechaini, formatofechafin) => {
        var fechainicio = new Date();
        var fechafin = new Date();
        var fechainiciovalido = false;
        var fechafinvalido = false;
        if (formatofechaini.length > 0) {
            fechainiciovalido = true;
            var fechabusquedainicio = formatofechaini.split("/");
            fechainicio.setFullYear(fechabusquedainicio[2], fechabusquedainicio[1], fechabusquedainicio[0]);
        }

        if (formatofechafin.length > 0) {
            fechafinvalido = true;
            var fechabusquedafin = formatofechafin.split("/");
            fechafin.setFullYear(fechabusquedafin[2], fechabusquedafin[1], fechabusquedafin[0]);
        }

        if (fechainiciovalido && fechafinvalido) {
            if (fechainicio <= fechafin) {
                return 1;
            }
            else {
                toast.info("Fecha inicio no puede ser mayor que fecha fin", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                var tabla = this.state.tabla;
                tabla.elementos = [];

                await this.setState({
                    tabla: tabla
                })
                this.cambiarContenido(1);
                return 2;
            }
        }
        return 0;
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    actualizarTabla = async () => {
        this.setState({
            loadingspinners: true,
            errordeacceso: false,
        })
        this.componentDidMount();
    }

    cambiarValor = () => {
        //console.log("AAA");
    }


    filtrarzona = async (item) => {
        
        this.setState({ zonaseleccionado: item })

    }
    filtrarrancho = async (item) => {
        
        this.setState({ ranchoseleccionado: item })
    }
    filtrarencargado = async (item) => {
        
        this.setState({ encargadoseleccionado: item })
    }

    cerrarModal = (accion) => {

        this.setState({ modaldetalles: !this.state.modaldetalles })
        if (accion == 1) {
            this.setState({ txtbuscador: '' })
            this.componentDidMount();
            this.filtrarArea("TodasLasZonas", 0)
        }

    }

    filtro = (pagenumber, array) => {
        this.setState({
            activepage: pagenumber,
        });

        setTimeout(() => {
            this.setState({
                listaelementosfiltrados: array,
                activepage: pagenumber
            });
        }, 0);

    }

    cambiarContenido = (pagenumber) => {
        this.setState({
            activepage: pagenumber,
            listapaginada: []
        });

        setTimeout(() => {
            this.setState({
                activepage: pagenumber,
                listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos)
            });
        }, 0);

    }


    activarSpinner = (accion) => {
        this.setState({ loadingspinners: accion })
    }


    sumardatos = () => {

        var datos = this.state.listapaginada;
        var total_salarial = 0;
        var total_otras = 0;
        var total_salario = 0;


        for (let i = 0; i < datos.length; i++) {
            datos[i].salario = (parseFloat(datos[i].percepcionsalarial) + parseFloat(datos[i].bonos) + parseFloat(datos[i].gastos) + parseFloat(datos[i].viaticos));
            total_salarial += (parseFloat(datos[i].percepcionsalarial));
            total_otras += (parseFloat(datos[i].bonos) + parseFloat(datos[i].gastos) + parseFloat(datos[i].viaticos));
        }

        total_salario = (total_salarial + total_otras);
        this.setState({ total_salarial: total_salarial });
        this.setState({ total_otras: total_otras });
        this.setState({ total_salario: total_salario });


        this.setState({ listapaginada: datos });

    }

    verModalEliminarItem = async (ban) => {
        this.setState({
            accionmodal: 30,
            modalautorizaciontitulo: 'Guardar Nomina',
            modalautorizacion: !this.state.modalautorizacion,
        })
    }

    cerrarModalAtutorizacion = (accion) => {
        
        this.setState({ modalautorizacion: !this.state.modalautorizacion })
        if (accion == 1) {
            this.componentDidMount();
        }
    }

    escribir = (prop) => (event) => {

        let info = prop.split('_');

        var datos = this.state.listapaginada;
        if (info[1] == "salarial") {
            datos[info[0]].percepcionsalarial = event.target.value;
        }
        else if (info[1] == "bonos") {
            datos[info[0]].bonos = event.target.value;
        }
        else if (info[1] == "gastos") {
            datos[info[0]].gastos = event.target.value;
        }
        else if (info[1] == "viaticos") {
            datos[info[0]].viaticos = event.target.value;
        }


        this.setState({ listapaginada: datos });
        this.sumardatos();
    }

    llenarnominamodal = async (dato, json) => {

        this.setState({ VerModalNuevaNomina: !this.state.VerModalNuevaNomina })
        if (dato == 1) {
            this.componentDidMount(1, json)
        }
        else {
            this.setState({ loadingspinners: false })
        }

    }


    llenarnomina = async (bandera) => {
        //bandera == id = editar, o ,0 = nuevo

        this.setState({ loadingspinners: true })
        const result = format(this.state.fechabusquedainicio, 'yyyy-MM-dd');
        const result2 = format(this.state.fechabusquedafin, 'yyyy-MM-dd');

        if (bandera == 0) {
            localStorage.setItem("idcatalogo_nominas_seguridad", "0")


            var json = {
                "fechainicio": result,
                "fechafin": result2,
                "fechainiciojson": this.state.fechabusquedainicio,
                "fechafinjson": this.state.fechabusquedafin,
                "idestatus": "5"
            }


            var resplista = await createNuevaNominaSeguridad(json);
            
            if (this.manejadorLlamadasApis(resplista)) {
                return;
            }

            
            if (resplista.code == 0 || resplista.code == "0") {
                
                this.setState({ loadingspinners: false })
                toast.info(resplista.data[0], { autoClose: 3000, position: toast.POSITION.TOP_LEFT })
                return;

            } else {
                this.setState({ loadingspinners: false })
                

                this.componentDidMount(1, json);


            }


        }
        else {


            const result = format(this.state.fechabusquedainicio, 'yyyy-MM-dd');
            const result2 = format(this.state.fechabusquedafin, 'yyyy-MM-dd');


            var datosjson = {
                idcatalogo_nominas_seguridad: bandera.idcatalogo_nominas_seguridad,
                fechainicio: bandera.fechainicio,
                fechafin: bandera.fechafin,
                idestatus: bandera.idestatus
            }
            localStorage.setItem("datosnomina", JSON.stringify(datosjson))


            // localStorage.setItem("idcatalogo_nominas_seguridad", bandera)
            this.setState({ loadingspinners: true });
            this.setState({ nuevodetallesnomina: true });

        }


    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

        this.consultarCatalogoNominaSeguridad(['txt' + item.llave], year + '-' + month + '-' + day)
    }



    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    format_moneda_sin_logo = (number) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
    }

    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }

    verdetalles = (id) => {
        this.setState({ loadingspinners: true });
        this.setState({ idseleccionado: id });
        this.setState({ verdetallesnomina: true });
    }

    render() {
        return (

            <>
                {this.state.salir ?
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <Acciones bandera={'salir'} />
                    </>
                    : null}
                <ToastContainer />


                {(this.state.VerModalNuevaNomina == true) ? (
                    <ModalNuevaNominaSeguridad tipo="full" show={this.state.VerModalNuevaNomina} cerrarmodal={this.llenarnominamodal} />
                ) : null}


                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}


                {(this.state.verdetallesnomina == true) ? (
                    <Acciones bandera={"vernomina"} id={this.state.idseleccionado} />
                ) : null}

                {(this.state.nuevodetallesnomina == true) ? (
                    <Acciones bandera={"nuevanomina"} />
                ) : null}


                {(this.state.modalautorizacion == true) ? (
                    <ModalAutorizacion item={this.state.itemseleccionado} show={this.state.modalautorizacion} titulomodal={this.state.modalautorizaciontitulo}
                        cerrarModalAtutorizacion={this.cerrarModalAtutorizacion} setloadingspinner={this.setLoadingSpinner} accion={this.state.accionmodal} />
                ) : null}

                <Navbar />

                {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
                <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                    <div className="container-fluid anchoMaximo">

                        <div className='row paddingEncabezados align-items-end '>
                            <div className='col containerSubTabla paddingBottomEncabezados'>
                                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo, paddingLeft: '0px' }}>Nóminas Seguridad</label>
                            </div>














                            
{/* filtro de fechas */}

<div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}>

                  <div className='subcontenedorIconoCalendarioCompras'>
                    <BsFillCalendarFill className='iconoCalendario' style={{ color: this.state.colortipolbl, stroke: this.state.colortipolbl }} />
                  </div>
                  <div className='row'>
                    <div className=' col-sm-4 col-md-4'>
                      <label className='lblTexto letraTitulos labelFecha' style={{ color: this.state.colortipolbl, }}>Fecha de:</label>
                    </div>
                    <div className='row col-sm-8 col-md-8' style={{ marginLeft: '10px' }}>
                      <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                        <DatePicker
                          selected={this.state.fechabusquedainicio}
                          onChange={(date) => this.cambiarFecha({ llave: "fechabusquedainicio", valor: date })}
                          dateFormat="dd/MM/yyyy"
                          locale="es"
                          customInput={
                            <DateButton />
                          }
                        />
                      </div>
                      <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>a:</label>
                      </div>

                      <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                        <DatePicker
                          selected={this.state.fechabusquedafin}
                          onChange={(date) => this.cambiarFecha({ llave: "fechabusquedafin", valor: date })}
                          dateFormat="dd/MM/yyyy"
                          locale="es"
                          customInput={
                            <DateButton />
                          }
                        />
                      </div>
                    </div>
                  </div>


                </div>

              </div>









                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <button className='btnActualizar letraNormal'
                                    style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                    onClick={this.actualizarTabla}><RiRefreshLine />&nbsp;Refrescar lista</button>
                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <button className='btnNuevo letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={this.llenarnominamodal.bind(this, 0)}>Crear Nomina</button>
                            </div>


                        </div>





                    </div>
                </div>
                {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

                {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

                <br />

                <div className="container-fluid containerTabla">
                    <div className="container-fluid anchoMaximo ">
                        <div className="containerSubTabla">
                            <Table>
                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                {titulo}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody style={{ verticalAlign: "bottom" }}>


                                    {this.state.listapaginada.map((item, index) => (
                                        <tr key={"tabla" + index}>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {item.idcatalogo_nominas_seguridad}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {item.fechainicio}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {item.fechafin}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {this.format_moneda(item.salarios)}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {this.format_moneda(item.extras)}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {this.format_moneda(item.total)}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                {item.idestatus == 5 ? <b>{item.estatus}</b> : <>{item.estatus}</>}
                                            </td>
                                            <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }} >

                                                {item.ideditar == 0 ?
                                                    <label onClick={this.llenarnomina.bind(this, item)} style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl, borderBottom: 'solid 1px black' }} >
                                                        <BsFillEyeFill className="icoBotones" />
                                                        <b className='btnVerTabla'>Ver</b>
                                                    </label>
                                                    :

                                                    <button onClick={this.llenarnomina.bind(this, item)} className='btnEditar' style={{
                                                        width: "100px",
                                                        backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                                                    }}><BiEdit className="icoBotones" /> Detalles</button>
                                                }

                                            </td>

                                        </tr>
                                    ))}


                                </tbody>
                            </Table>
                        </div>

                    </div>
                </div>

                <div className="panelPaginationSection">
                    <Paginacion
                        activepage={this.state.activepage}
                        itemscountperpage={this.state.itemsperpage}
                        totalitemscount={this.state.txtbuscador.length < 3 ? this.state.tabla.elementos : this.state.elementosfiltrados}
                        pagerangedisplayed={this.state.rangepages}
                        cambiarcontenido={(e) => this.cambiarContenido(e)}
                    />
                </div>

            </>
        )
    }
}
function Acciones({ bandera, id }) {

    const history = useHistory();
    const location = useLocation();
    history.push(location.pathname);

    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }

    else if (bandera == 'vernomina') {
        localStorage.setItem("datonomina", id);
        return (<Redirect from='/' to={"/catalogonominaseguridadver"} />)
    }

    else if (bandera == 'nuevanomina') {
        localStorage.removeItem("datonomina");
        return (<Redirect from='/' to={"/catalogonominaseguridadver"} />)
    }

}




const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}>

        <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
));

