import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import "./ModalNotasNominasSeguridad.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import { validarNumerosDecimales, validarSoloNumeros } from '../../../Services/Validaciones';
import { getEmpleadosAsistenciaSeguridad, postAgregarNotaSeguridad } from '../../../Services/Api';
import { CgClose } from 'react-icons/cg';



export default class ModalSalarios extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

            idusuario: props.idusuario,
            txtsalario: props.salario,

            txttardeada: props.tardeada == null || props.tardeada == undefined ? "0.00" : props.tardeada,
            vermsgerrortxtsalario: false,
            msgerrortxtsalario: "",

            txtprincipal: "",
            vermsgerrortxtprincipal: false,
            msgerrortxtprincipal: "",
            notas: props.bandera == "notas" ? props.arrayModal.notasencargado : [],

            listseguridad:props.listseguridad,
            idcatalogo_nominas_seguridad_detalles:0,
            idusuario:0,
            vermsgerrorusuario:false,
            msgerrorusuario:'',

            txtnota:'',
            vermsgerrortxtnota:false,
            msgerrortxtnota:'',

            activo:1,
            idcatalogo_nominas_seguridad:0
        }

    }


    async componentDidMount() {
        this.props.setLoadingSpinner(true)
        let listaasistencia = await getEmpleadosAsistenciaSeguridad({
            "idcatalogo_nominas_seguridad": this.props.idnomina
        })
        
        this.setState({activo: listaasistencia.data.activo, idcatalogo_nominas_seguridad:this.props.idnomina})
        setTimeout(async () => {
            this.refInput.focus();
        }, 500);
        this.props.setLoadingSpinner(false)

    }

    setLoadingSpinner = (bandera) => {
        this.props.setloadingspinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        if(this.state.activo != 0){
            if(prop == 'idcatalogo_nominas_seguridad_detalles'){
                const user = this.state.listseguridad.find(user => user.idcatalogo_nominas_seguridad_detalles == event.target.value );
                this.setState({idusuario:user.idusuario})
            }
            this.setState({ [prop]: event.target.value });
        }else{
            toast.info("Nómina finalizada", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
       
    }


    guardardatos = async () => {
        this.setState({ vermsgerrorusuario: false })
        this.setState({ vermsgerrortxtnota: false })
        let validacionescorrectas = true

        if (this.state.idcatalogo_nominas_seguridad_detalles == 0) {
            this.setState({ msgerrorusuario: "Selecciona un usuario." })
            this.setState({ vermsgerrorusuario: true })
            validacionescorrectas = false
        }

        if (this.state.txtnota == '') {
            this.setState({ msgerrortxtnota: "Este campo es obligatorio." })
            this.setState({ vermsgerrortxtnota: true })
            validacionescorrectas = false
        }

        if (validacionescorrectas) {
            this.setState({ loadingspinners: true })
            let json = {
                "idcatalogo_nominas_seguridad_detalles": this.state.idcatalogo_nominas_seguridad_detalles,
                "idusuario": this.state.idusuario,
                "idcatalogo_nominas_seguridad": this.state.idcatalogo_nominas_seguridad,
                "nota": this.state.txtnota
            
            }
            var resp = await postAgregarNotaSeguridad(json);

            if (resp == -1 || resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setState({ loadingspinners: false })
                return true
            }
            else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
                this.setState({ loadingspinners: false })
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return true 
            }
            else {
                toast.success("Nota guardada correctamente ", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.showModal({ accion: '1'})
            }
        }


    }


    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div className="divTituloModal">
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
                            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        </div>


                            <div className="divModalEliminar">
                                <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>
                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Usuario seguridad</p>
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("idcatalogo_nominas_seguridad_detalles")}>
                                                    <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listseguridad == undefined || this.state.listseguridad.length == 0 ? null : this.state.listseguridad.map((i) => (
                                                        <option 
                                                            value={i.idcatalogo_nominas_seguridad_detalles} 
                                                            key={"tipo_" + i.idcatalogo_nominas_seguridad_detalles}
                                                            selected={i.idcatalogo_nominas_seguridad_detalles==this.state.idcatalogo_nominas_seguridad_detalles ? true : false}>
                                                            {i.nombre} {i.apellido}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.vermsgerrorusuario ? 
                                        <p 
                                            className="msgError letraNormal"
                                            style={{ color: this.state.colormsgerror }}
                                        >
                                            {this.state.msgerrorusuario}
                                        </p> 
                                    :null}
                            
                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nota</p>
                                    <input
                                    className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtnota} onChange={this.escribir("txtnota")}></input>

                                    {this.state.vermsgerrortxtnota ? 
                                        <p 
                                            className="msgError letraNormal"
                                            style={{ color: this.state.colormsgerror }}
                                        >
                                            {this.state.msgerrortxtnota}
                                        </p> 
                                    :null}
                                </div>
                            </div>


                        {parseInt(this.state.activo) == 1 ?
                            <>
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardardatos()}  disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }
}
