import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { estiloComboBox, estilosModal, coloresTxtBuscador } from '../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import { GetEmpleadosSeguridad } from '../../Services/Api';

export default class ModalEmpleadosSeguridad extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: 0,//props.accion,
            titulo: "Empleados Seguridad",

            txtbuscador: "",

            tabla: {
                encabezados: [
                    "Proveedor",
                    "Tipo"
                ],
                dimensiones: [
                    "15%",
                    "15%"
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                ],
                elementos: [],
            },


            listaproveedoresfiltrados: [],
            proveedorseleccionado: undefined,
            idproveedorseleccionado: 0,

            filtrotipoproveedor: 0,
            lista:[],

            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,

        }

    }

    async componentDidMount() {

        let listaempleados = await GetEmpleadosSeguridad()
        console.log("lista de empleados", listaempleados)

        let listaordenada = this.ordenarempleados(listaempleados.data)

        this.setState({lista:listaordenada})
    }

    ordenarempleados(lista) {
        console.log("ordenar lista de empleados ", lista)

        let usuarios = []
        lista.forEach(el => {
            usuarios.push({
                idusuario: el.idusuario,
                usuario: el.nombre + " " + el.apellido,
                select: 0,
            })
        });

        return usuarios
    }


    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 500);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {
        // Mandar datos del item de producto seleccionado
        let lista=[]
        this.state.lista.forEach(el => {
            if(el.select==1)
                lista.push(el)
        });
        this.showModal(lista)

    }

    filtrarTipoProveedor = async (e) => {

        var data1 = JSON.parse(JSON.stringify(this.state.listaproveedores))
        var data = data1;

        var filtronombre = [];
        var filtrostipo = [];

        if (e.target.id == 'Search') { // input 
            this.setState({ txtbuscador: e.target.value });

            if (e.target.value.trim().length < 3) {
                filtronombre = data;
            }
            if (this.state.filtrotipoproveedor == 0) {
                filtrostipo = data;
            } else {
                filtrostipo = data.filter((item) => item.idtipo_proveedor == this.state.filtrotipoproveedor)
            }
            filtronombre = filtrostipo.filter((item) => item.nombre.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

        } else if (e.target.id == 'selecttipo') { // selector
            this.setState({ filtrotipoproveedor: e.target.value });

            if (e.target.value == 0) {
                filtrostipo = data;
            } else {
                filtrostipo = data.filter((item) => item.idtipo_proveedor == e.target.value)
            }

            if (this.state.txtbuscador.trim().length > 2) {
                filtronombre = filtrostipo.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))

            } else {
                filtronombre = filtrostipo;
            }

        } else if (e.target.id == 'CSearch') { // limpiar input
            this.setState({ txtbuscador: "", filtrotipoproveedor: 0 });
            filtronombre = data;
        }

        if (filtronombre.length < 1) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

        await this.setState({ listaproveedoresfiltrados: filtronombre })
    }

    seleccionarProveedor = async (item) => {

        let lista= this.state.lista
        lista.forEach(el => {

            if(el.idusuario== item.idusuario){
                el.select=item.select==0?1:0
            }
            
        });
        await this.setState({
            proveedorseleccionado: item,
            idproveedorseleccionado: item.idcatalogo_proveedores,
            confirmarenvio: true,
        })
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>



                                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                    <label className='lblTexto letraTitulos'>Selecciona los empleados: </label>
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTablaAu">
                                            <Table hover>


                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.lista.length > 0 ? this.state.lista.map((item, index) => (
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarProveedor(item)} className={item.select==1 ? "trFondoActivoGreen" : ""}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.usuario}
                                                                </td>

                                                            </tr>
                                                        )) : null
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>CANCELAR</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >SELECCIONAR</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

