import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoReportes.css';

import { format } from 'date-fns';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {
    getListaZonas,
    getListasReportes,
    getListaRanchosDisponibles,
    generarExcelReportes,
    Getinfocsv
} from '../../Services/Api';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExportarCSV from '../../Complements/exportarcsv/exportarcsv';
import ExportarCSVreporteNominas from '../../Complements/exportarcsv/exportarCSVreporteNominas';


export default class ReportesNominas extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            salir: false,
            txtbuscador: '',
            altura: window.innerHeight,
            anchura: window.innerWidth,
            filtrolistapaginada: [],
            elementosfiltrados: [],
            loadingspinners: true,


            listareportes: [],
            listazonas: [],
            listaranchos: [],

            txtselectedzona: '0',
            txtselectedrancho: '0',
            txtselectedreporte: '0',


            tablaGenerales: {
                encabezados: [
                    "Total general",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////
            tablaProveedor: {
                encabezados: [
                    "Total proveedor",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"

                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////
            tablaTiposGenerales: {
                encabezados: [
                    "Total generales",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////
            tablaTiposAgroquimicos: {
                encabezados: [
                    "Total agroquímicos",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////
            tablaTiposCombustibles: {
                encabezados: [
                    "Total combustibles",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////
            tablaTiposDirectas: {
                encabezados: [
                    "Total C. directas",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////


            estatus: [],
            accionmodal: 1,
            titulomodal: '',
            modaldetalles: false,
            itemseleccionado: null,
            errordeacceso: false,
            jsoncsv: [],
            verCSV: false,

            fechacompraini: new Date(),
            fechacomprafin: new Date(),
            txtfechacompraini: "",
            txtfechacomprafin: "",
            gtotalCompras: "0.00",
            gtotal: "0.00",
            gadeudo: "0.00",

            idprovedorselect: -1,
            nameprovedorselect: "",
            arrayprovedor: [],
            //paginacion
            activepage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],

            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortitulo: estiloFiltroEncabezados().colortitulos,
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            colorencabezadostabla: estiloTabla().colorencabezadostabla,
            colorcontenidotabla: estiloTabla().colorcontenidotabla,
            colorverlbl: estiloBtnVer().colorverlbl,
            borderver: estiloBtnVer().borderver,

            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
            colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

            btnfondoeditar: estiloBtnEditar().btnfondoeditar,
            btncoloreditar: estiloBtnEditar().btncoloreditar,
            btnbordereditar: estiloBtnEditar().btnbordereditar,

            btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
            btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
            btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

        }
    }

    async componentDidMount() {

        this.setState({ txtbuscador: "" })
        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
            return;
        }

        if (!this.state.loadingspinners) {
            this.setState({ loadingspinners: true });
        }


        this.actualizar();
        this.dimensiones();
        window.addEventListener("resize", this.dimensiones);

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

            this.setState({
                loadingspinners: false,
                errordeacceso: true
            })
            return true
        }
        return false
    }

    actualizar = async () => {


        this.setState({ loadingspinners: true })
        this.setState({ verCSV: false })


        //obtiene catalogo de ranchos
        var resplistasreportes = await getListasReportes();
        if (this.manejadorLlamadasApis(resplistasreportes)) {
            return;
        }


        //obtiene catalogo de zonas
        var respzonas = await getListaZonas();
        if (this.manejadorLlamadasApis(respzonas)) {
            return;
        }

        //obtiene catalogo de ranchos
        var respranchos = await getListaRanchosDisponibles();
        if (this.manejadorLlamadasApis(respranchos)) {
            return;
        }

        var fechaactualFin = new Date();
        const fechaa = fechaactualFin.getDate();
        fechaactualFin.setDate(fechaa);
        const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

        var fechaactualIni = new Date();
        fechaactualIni.setDate(fechaactualIni.getDate() - 7);
        const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');


        this.setState({

            listareportes: resplistasreportes.data,
            listazonas: respzonas.data,
            listaranchos: respranchos.data,
            txtfechacompraini: defaultValueFechaInicial,
            txtfechacomprafin: defaultValue,
            fechacompraini: fechaactualIni,

        })


        this.setState({ loadingspinners: false })

    }


    crearCSVss = async () => {

        if (this.state.txtselectedreporte == "0") {
            toast.info("Selecciona un reporte", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return true
        }

        
        this.setState({ verCSV: false, loadingspinners:true })
        var fechaStrat = "";
        var fechaEnd = "";
        fechaStrat = format(this.state.fechacompraini, 'dd/MM/yyyy');
        fechaEnd = format(this.state.fechacomprafin, 'dd/MM/yyyy');
        var parse_fechaStrat = fechaStrat.split("/");
        var parse_fechafin = fechaEnd.split("/");


        const fechaStartfin = new Date(parse_fechaStrat[2], parse_fechaStrat[1] - 1, parse_fechaStrat[0]);
        const fechaEndfin = new Date(parse_fechafin[2], parse_fechafin[1] - 1, parse_fechafin[0]);


        var json = {
            "idreporte": this.state.txtselectedreporte,
            "fechainicio": parse_fechaStrat[2] + "-" + (parse_fechaStrat[1]) + "-" + parse_fechaStrat[0],
            "fechafin": parse_fechafin[2] + "-" + (parse_fechafin[1]) + "-" + parse_fechafin[0],
            "idrancho": this.state.txtselectedrancho
        }



        var resp = await generarExcelReportes(json);
        if (this.manejadorLlamadasApis(resp)) {
            return;
        }

        this.setState({ jsoncsv: resp.data })

        setTimeout(() => {
            this.setState({ verCSV: true, loadingspinners:false })
        }, 500);



    }



    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }



    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }




    //Funcion para filtrar por los select y texto en buscador
    saveInputLocacion = async (e) => {
        if (e.target.id == 'zonas') {
            this.setState({
                txtselectedzona: e.target.value,
                txtselectedrancho: 0
            });


        } else if (e.target.id == 'ranchos') {
            this.setState({
                txtselectedrancho: e.target.value,
            });

        }

    }























    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })


        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

    }


    render() {
        return (

            <>
                {this.state.salir ?
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <AccionesVista bandera={'salir'} />
                    </>
                    : null}

                <ToastContainer />

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                {(this.state.modaldetalles == true) ? (
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <AccionesVista bandera={'detalles'} />
                    </>
                ) : null}

                <Navbar />
                <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                    <div className="container-fluid anchoMaximo">

                        <div className='row paddingEncabezados align-items-end '>
                            <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Reportes de Nóminas</label>
                            </div>










                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                {(this.state.verCSV) ? (
                                    <ExportarCSVreporteNominas arraycsv={this.state.jsoncsv} totalitems={this.state.jsoncsv.length}
                                    />
                                ) : null
                                }
                            </div>

                        </div>
                    </div>
                </div>



                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <div className="container-fluid containerTabla">
                    <div className="container-fluid anchoMaximo " style={{ width: "98%" }}>
                        <br />
                        <br />
                        <br />
























                        <div style={{ alignItems: "center", maxWidth: "400px", margin: "auto" }}>



                            {/* /////////////////// seleccionar reporte //////////////////////////////////*/}



                            <div className='col-auto paddingBottomEncabezados'>
                                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo, width: "100%" }}>
                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Reporte</label>
                                    <div className="selectBox ">
                                        <select id='reportes' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={(e) => this.setState({ txtselectedreporte: e.target.value })}>
                                            {this.state.txtselectedreporte == 0 ? <option selected="true" value='0' disabled="disabled">Seleccionar</option> : <option value='0' disabled="disabled">Seleccionar</option>}
                                            {this.state.listareportes == undefined || this.state.listareportes.length == 0 ? null : this.state.listareportes.map((item, index) => {
                                                return (<option key={"z_" + index} value={item.idreporte}>{item.nombre}</option>);
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>


                            {/* /////////////////// seleccionar fecha //////////////////////////////////*/}

                            <div className='col-auto paddingBottomEncabezados'>

                                <div className='' >
                                    <div className='row contenedorFechaCompras'
                                        style={{
                                            color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar,
                                            border: this.state.bordertxtbuscar, width: "100%", margin: "0px"
                                        }}>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                                            <label className='lblTexto letraTitulos ' style={{ color: this.state.colortipolbl, paddingLeft: "8px" }}>Fecha de:</label>
                                        </div>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>

                                            <DatePicker
                                                selected={this.state.fechacompraini}
                                                onChange={(date) => this.cambiarFecha({ llave: "fechacompraini", valor: date })}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={
                                                    <DateButton />
                                                }
                                            />
                                        </div>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, textAlign: 'center' }}>a:</label>
                                        </div>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                            <DatePicker
                                                selected={this.state.fechacomprafin}
                                                onChange={(date) => this.cambiarFecha({ llave: "fechacomprafin", valor: date })}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={
                                                    <DateButton />
                                                }
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>



                            {/* /////////////////// seleccionar zona y rancho //////////////////////////////////*/}



                            {/* ////////////////////   Filtro zonas ////////////////////////////////////// */}
                            <div className='col-auto paddingBottomEncabezados'>
                                <div className='divCombo' style={{ width: "100%", color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                                    <div className="selectBox ">
                                        <select id='zonas' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl, zIndex: "0" }} onChange={this.saveInputLocacion}>
                                            {this.state.txtselectedzona == 0 ? <option selected="true" value='0' disabled="disabled">Seleccionar</option> : <option value='0' disabled="disabled">Seleccionar</option>}
                                            {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((item, index) => {
                                                return (<option key={"z_" + index} value={item.idcatalogo_zona}>{item.zona}</option>);
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* ////////////////////   Filtro ranchos ////////////////////////////////////// */}
                            <div className='col-auto paddingBottomEncabezados'>
                                <div className='divCombo' style={{ width: "100%", color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Rancho</label>
                                    <div className="selectBox ">
                                        <select id='ranchos' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                                            {this.state.txtselectedrancho == 0 ? <option selected="true" value='0'>Todos</option> : <option value='0'>Todos</option>}
                                            {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((item, index) => {
                                                return (item.idcatalogo_zona == this.state.txtselectedzona || this.state.txtselectedzona == 0 ? <option key={"r_" + index} value={item.idcatalogo_rancho}>{item.rancho}</option> : null);
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <br />
                            <div className='col-auto paddingBottomEncabezados' style={{ textAlign: "center" }}>
                                <button className='btnActualizar letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={() => this.crearCSVss()}>
                                    Generar CSV

                                </button>
                            </div>






                        </div>































                    </div>
                </div>

            </>
        )
    }
}

function AccionesVista({ bandera }) {
    const history = useHistory();
    const location = useLocation();
    history.push(location.pathname);

    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }

    else if (bandera == 'detalles') {
        return (<Redirect from='/' to={"/catalogoreportesdetalles"} />)
    }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}>

        <label style={{ minWidth: '80px' }}>{value}</label>
    </button>
));


