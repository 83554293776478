import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioDetalleOrdenCosecha.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarNumeros, validarNumerosDecimales2 } from '../../../Services/Validaciones';
import { getDetallesOrdenCosechaGuardarCuadrilla } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioNuevoPlanCuadrilla extends React.Component {
 
    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,

            accion: props.accion,
            titulo: props.titulomodal,
            cuadrilla: (props.accion == 5) ? (props.itemseleccionado.idcatalogo_cuadrillas) : 0,
            txtcortadores: (props.accion == 5) ? (props.itemseleccionado.cortadores) : '',
            txtcostoflete: (props.accion == 5) ? (props.itemseleccionado.costoflete) : '',
            txtcostocosecha:(props.accion == 5) ? (props.itemseleccionado.costococecha) : '',
            txttipopago:(props.accion == 5) ? (props.itemseleccionado.idcatalogo_tipo_pago_corte) : 0,
            txtcostounidad:(props.accion == 5) ? (props.itemseleccionado.costounidad) : '',
            txtcostodia:(props.accion == 5) ? (props.itemseleccionado.costodia) : '',

            listacuadrillas: this.props.listacuadrillas != undefined ? this.props.listacuadrillas : [],
            idordecosecha: this.props.idordecosecha != undefined ? this.props.idordecosecha : 0,
            idcatalogo:(props.accion == 5) ? (props.itemseleccionado.idcatalogo_ordenes_cosecha_cuadrillas) : 0,

            vermsgerrorcuadrilla: false,
            vermsgerrortxtcortadores: false,
            vermsgerrortxtcostoflete:false,
            vermsgerrortxtcostocosecha:false,
            vermsgerrortxttipopago:false,
            vermsgerrortxtcostounidad:false,
            vermsgerrortxtcostodia:false,

            msgerrorcuadrilla: "",
            msgerrortxtcortadores: "",
            msgerrortxtcostoflete: "",
            msgerrortxtcostocosecha: "",
            msgerrortxttipopago: "",
            msgerrortxtcostounidad: "",
            msgerrortxtcostodia: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            listatipopago:this.props.listatipopago
        }

    }
    async componentDidMount() {
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {
        let longitudenterovalido = { longitudminima: 1, longitudmaxima: 9 };
        let longituddecimalvalido = { longitudminima: 1, longitudmaxima: 12 };
        let validacionescorrectas = true;

        this.setState({ vermsgerrorcuadrilla: false })
        this.setState({ vermsgerrortxtcortadores: false })
        this.setState({ vermsgerrortxtcostoflete: false })
        this.setState({ vermsgerrortxtcostocosecha: false })
        this.setState({ vermsgerrortxttipopago: false })
        this.setState({ vermsgerrortxtcostounidad: false })
        this.setState({ vermsgerrortxtcostodia: false })

        if (this.state.cuadrilla < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorcuadrilla: "Seleccione un cultivo" })
            this.setState({ vermsgerrorcuadrilla: true })
        }

        let vtxtcortadores = validarNumeros(this.state.txtcortadores, longitudenterovalido);
        if (vtxtcortadores.codigo == "invalid") {
            this.setState({ msgerrortxtcortadores: vtxtcortadores.mensaje })
            this.setState({ vermsgerrortxtcortadores: true })
            validacionescorrectas = false;
        }

        let vcostoflete = validarNumerosDecimales2(this.state.txtcostoflete, longituddecimalvalido);
        if (vcostoflete.codigo == "invalid") {
            this.setState({ msgerrortxtcostoflete: vcostoflete.mensaje })
            this.setState({ vermsgerrortxtcostoflete: true })
            validacionescorrectas = false;
        }

        let vcostocosecha = validarNumerosDecimales2(this.state.txtcostocosecha, longituddecimalvalido);
        if (vcostocosecha.codigo == "invalid") {
            this.setState({ msgerrortxtcostocosecha: vcostocosecha.mensaje })
            this.setState({ vermsgerrortxtcostocosecha: true })
            validacionescorrectas = false;
        }

        if (this.state.txttipopago < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortxttipopago: "Seleccione un tipo de pago" })
            this.setState({ vermsgerrortxttipopago: true })
        }

        let vcostounidad = validarNumerosDecimales2(this.state.txtcostounidad, longituddecimalvalido);
        if (vcostounidad.codigo == "invalid" && this.state.txttipopago == 1) {
            this.setState({ msgerrortxtcostounidad: vcostounidad.mensaje })
            this.setState({ vermsgerrortxtcostounidad: true })
            this.setState({ txtcostodia: 0})
            validacionescorrectas = false;
        }

        let vcostodia = validarNumerosDecimales2(this.state.txtcostodia, longituddecimalvalido);
        if (vcostodia.codigo == "invalid" && this.state.txttipopago == 2) {
            this.setState({ msgerrortxtcostodia: vcostodia.mensaje })
            this.setState({ vermsgerrortxtcostodia: true })
            this.setState({ txtcostounidad: 0})
            validacionescorrectas = false;
        }

        if (validacionescorrectas) {
            this.setState({ loadingspinners: true })
            
            let json =  {
                "idcatalogo_ordenes_cosecha_cuadrillas": this.state.idcatalogo,
                "idcatalogo_ordenes_cosecha": this.state.idordecosecha,
                "idcatalogo_cuadrillas": this.state.cuadrilla,
                "cortadores": this.state.txtcortadores,
                "costoflete": this.state.txtcostoflete,
                "costococecha": this.state.txtcostocosecha,
                "idcatalogo_tipo_pago_corte": this.state.txttipopago,
                "costounidad": this.state.txttipopago == 1 ? this.state.txtcostounidad : 0,
                "costodia": this.state.txttipopago == 2 ? this.state.txtcostodia : 0,
                "eliminar":0
            }

            /*toast.info("API en desarrollo", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            this.setState({ loadingspinners: false })
            this.showModal('1')*/

            var resp = await getDetallesOrdenCosechaGuardarCuadrilla(json);

            if(resp.code == 0){
                if(this.props.accion == 1){
                    this.showModal('1')
                }
                else if(this.props.accion == 5){
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners:false })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners:false })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners:false })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners:false });
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/* ////////////////////   Inicio seleccion cuadrillas  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Cuadrilla</p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("cuadrilla")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.state.listacuadrillas == undefined || this.state.listacuadrillas.length == 0 ? null : this.state.listacuadrillas.map((i) => (
                                                    <option 
                                                        value={i.idcatalogo_cuadrillas} 
                                                        key={"tipo_" + i.idcatalogo_cuadrillas}
                                                        selected={i.idcatalogo_cuadrillas==this.state.cuadrilla ? true : false}>
                                                        {i.nombre}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrorcuadrilla ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcuadrilla}</p> : null}


                                {/* ////////////////////   Inicio Sección campo Cortadores  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Numero de cortadores</p>
                                <input 
                                    className='lblTextoModal letraNormal' 
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'} 
                                    value={this.state.txtcortadores} 
                                    onChange={this.escribir("txtcortadores")}>
                                </input>

                                {this.state.vermsgerrortxtcortadores? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcortadores}</p> : null}

                                {/* ////////////////////   Inicio Sección campo costo flete  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Viáticos</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'} 
                                    value={this.state.txtcostoflete}
                                    onChange={this.escribir("txtcostoflete")}>
                                </input>
                                {this.state.vermsgerrortxtcostoflete ? 
                                    <p 
                                        className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}
                                    >
                                        {this.state.msgerrortxtcostoflete}
                                    </p> 
                                :null}
                                {/* ////////////////////   Inicio Sección campo costo cosecha  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Apoyo cosecha</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'} 
                                    value={this.state.txtcostocosecha}
                                    onChange={this.escribir("txtcostocosecha")}>
                                </input>
                                {this.state.vermsgerrortxtcostocosecha ? 
                                    <p 
                                        className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}
                                    >
                                        {this.state.msgerrortxtcostocosecha}
                                    </p> 
                                :null}
                                {/* ////////////////////   Inicio Sección campo tipo pago  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo pago</p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txttipopago")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.state.listatipopago == undefined || this.state.listatipopago.length == 0 ? null : this.state.listatipopago.map((i) => (
                                                    <option 
                                                        value={i.idcatalogo_tipo_pago_corte} 
                                                        key={"tipo_" + i.idcatalogo_tipo_pago_corte}
                                                        selected={i.idcatalogo_tipo_pago_corte==this.state.txttipopago ? true : false}>
                                                        {i.tipo}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {this.state.vermsgerrortxttipopago ? 
                                    <p 
                                        className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}
                                    >
                                        {this.state.msgerrortxttipopago}
                                    </p> 
                                :null}
                                {/* ////////////////////   Inicio Sección campo Precio unidad  ////////////////////////////////////// */}
                                {parseInt(this.state.txttipopago)==1?
                                    <><p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Precio unidad</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'} 
                                    value={this.state.txtcostounidad}
                                    onChange={this.escribir("txtcostounidad")}>
                                </input>
                                {this.state.vermsgerrortxtcostounidad ? 
                                    <p 
                                        className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}
                                    >
                                        {this.state.msgerrortxtcostounidad}
                                    </p> 
                                :null}</>:null}
                                {/* ////////////////////   Inicio Sección campo Pago por día  ////////////////////////////////////// */}
                                {parseInt(this.state.txttipopago)==2?
                                <>
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Pago por día </p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'} 
                                    value={this.state.txtcostodia}
                                    onChange={this.escribir("txtcostodia")}>
                                </input>
                                {this.state.vermsgerrortxtcostodia ? 
                                    <p 
                                        className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}
                                    >
                                        {this.state.msgerrortxtcostodia}
                                    </p> 
                                :null}</>:null}
                            </div>
                        </div>
                        <br />
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                        </div>
                    </ModalBody>

                </Modal>
            </>
        )
    }

}

