import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import "./ModalNotasNominasSeguridad.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import { getListaAsignarSalario, postEliminarNotaSeguridad } from '../../../Services/Api';
import { CgClose } from 'react-icons/cg';



export default class ModalSalarios extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

            idusuario: props.idusuario,
            txtsalario: props.salario,

            txttardeada: props.tardeada == null || props.tardeada == undefined ? "0.00" : props.tardeada,
            vermsgerrortxtsalario: false,
            msgerrortxtsalario: "",

            txtprincipal: "",
            vermsgerrortxtprincipal: false,
            msgerrortxtprincipal: "",
            notas: props.bandera == "notas" ? props.arrayModal.notasnomina : [],

            idcatalogo_nominas_seguridad_detalles_notas:0

        }

    }


    async componentDidMount() {
        setTimeout(async () => {
            this.refInput.focus();
        }, 500);
    }

    setLoadingSpinner = (bandera) => {
        this.props.setloadingspinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    seleccionarNota = (index) => {
        var array = this.state.notas;
        for (let i = 0; i < array.length; i++) {
            array[i].activo = false;
        }
        array[index].activo = true;

        this.setState({ idcatalogo_nominas_seguridad_detalles_notas: array[index].idcatalogo_nominas_seguridad_detalles_notas })
        this.setState({ notas: array })

    }

    eliminarNota = async () => {
        this.setState({ loadingspinners: true })
        let validacionescorrectas = true;

        if (this.state.idcatalogo_nominas_seguridad_detalles_notas == 0) {
            this.setState({ loadingspinners: false })
            toast.info("Selecciona una nota.", { autoClose: 3000, position: toast.POSITION.TOP_LEFT })
            validacionescorrectas = false
        }
        if (validacionescorrectas) {
            this.setState({ loadingspinners: true })
            let json = {
                "idcatalogo_nominas_seguridad_detalles_notas": this.state.idcatalogo_nominas_seguridad_detalles_notas
            }
            var resp = await postEliminarNotaSeguridad(json);

            if (resp == -1 || resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setState({ loadingspinners: false })
                return true
            }
            else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
                this.setState({ loadingspinners: false })
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return true 
            }
            else {
                toast.success("Nota eliminada correctamente ", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.showModal({ accion: '1'})
            }
        }


    }



    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div className="divTituloModal">
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo} </label>
                            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        </div>

                        <div className="divModalEliminar">
                           
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Selecciona la nota que deseas eliminar:</p>
                                {this.state.notas == undefined || this.state.notas.length == 0 ? 
                                    <div className='row'>
                                        <div className='col'>
                                            No hay notas
                                        </div>
                                    </div>
                                :this.state.notas.map((item, index) => (
                                    <li key={index} class="list-group-item" style={{ backgroundColor: item.activo == true ? "#ece8e0" : this.state.estilosmodal.fondocontenedormodal }} onClick={this.seleccionarNota.bind(this, index)}>
                                        <label className='lblSubTitulosModal' style={{ color: "black", fontSize: "medium", fontWeight: "normal" }}>{item.nota}</label>
                                        <b style={{fontWeight:"bold", fontSize:"12px"}}> - {item.fechahora}</b>
                                    </li>
                                    
                                ))}

                            </div>
                        </div>

                        <br />
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.eliminarNota()}  disabled={!this.state.confirmarenvio} >Eliminar</Button>
                        </div>


                    </ModalBody>

                </Modal>
            </>
        )
    }
}
