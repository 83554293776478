import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioBitacoraTraslado.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarNumerosDecimales,validarLongitud, validarSoloLetrasYEspacios } from '../../../Services/Validaciones';
import { postDetallesBitacoraTrasladoGuardar } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import InputHora from '../../InputHora/InputHora';
import "react-datepicker/dist/react-datepicker.css";

export default class ModalFormularioDetallesBitacoraTraslado extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            loadingspinners: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            listalotes: this.props.listalotes != undefined ? this.props.listalotes : [],
            listaclientes:this.props.listaclientes != undefined ? this.props.listaclientes:[],

            idcatalogo_bitacora_traslados_detalles: (props.accion != 1) ? (props.item.idcatalogo_bitacora_traslados_detalles) + "" : '0',
            idcatalogo_bitacora_traslados: props.idcatalogotraslado,
            txthoraentrada: (props.accion != 1) ? (props.item.horaentrada) : '00:00',
            txtrancho: props.idrancho,
            txtrejasentrada: (props.accion != 1) ? (props.item.rejasentradavacias) + "": '',
            txtrejassalida: (props.accion != 1) ? (props.item.rejassalidallenas) + "": '',
            txtlote: (props.accion != 1) ? (props.item.idcatalogo_ranchos_lotes_cultivo) + "": '',
            infolote: (this.props.accion==2)?(props.listalotes.find(x => x.idcatalogo_ranchos_lotes_cultivo==this.props.item.idcatalogo_ranchos_lotes_cultivo)):undefined,
            txtfoliotraslado: (props.accion != 1) ? (props.item.folio) + "": '',
            txtvehiculo: (props.accion != 1) ? (props.item.vehiculo) : '',
            txtconductor: (props.accion != 1) ? (props.item.conductor) : '',
            txtplacas: (props.accion != 1) ? (props.item.placas) : '',
            txthorasalida: (props.accion != 1) ? (props.item.horasalida) : '00:00',
            txtdestino: (props.accion != 1) ? (props.item.destino) : '',
            txtcliente:(props.accion != 1) ? (props.item.idcatalogo_clientes):'',

            vermsgerrortxthoraentrada: false,
            vermsgerrortxtrancho: false,
            vermsgerrortxtrejasentrada: false,
            vermsgerrortxtrejassalida: false,
            vermsgerrortxtlote: false,
            vermsgerrortxtfoliotraslado: false,
            vermsgerrortxtvehiculo: false,
            vermsgerrortxtconductor: false,
            vermsgerrortxtplacas: false,
            vermsgerrortxthorasalida: false,
            vermsgerrortxtdestino: false,
            vermsgerrortxtcliente:false,

            msgerrortxthoraentrada: "",
            msgerrortxtrancho: "",
            msgerrortxtrejasentrada: "",
            msgerrortxtrejassalida: "",
            msgerrortxtlote: "",
            msgerrortxtfoliotraslado: "",
            msgerrortxtvehiculo: "",
            msgerrortxtconductor: "",
            msgerrortxtplacas: "",
            msgerrortxthorasalida: "",
            msgerrortxtdestino: "",
            msgerrortxtcliente:"",

            tabla: {
                encabezados: [
                    "Tipo",
                    "Producto",
                    "Cantidad",
                    "Unidad de medida",
                    "Destino",
                    "",
                    ""
                ],
                dimensiones: [
                    "12%",
                    "15%",
                    "12%",
                    "16%",
                    "15%",
                    "15%",
                    "10%"
                ],
                mindimensiones: [
                    "130px",
                    "180px",
                    "120px",
                    "200px",
                    "180px",
                    "120px",
                    "100px"
                ],
                elementos: []
            },

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colorbordertipo: estiloComboBox().colorbordercombo,
        }
    }

    async componentDidMount() {
        console.log(this.state.txthoraentrada)
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
        if (prop == "txtlote") {
            let infolote = this.state.listalotes.find(x => x.idcatalogo_ranchos_lotes_cultivo == event.target.value)
            this.setState({ infolote: infolote, txtrejasentrada:'', txtrejassalida:'' })
        }
    };

    escribirHoraEntrada = (hora) =>{
        this.setState({ txthoraentrada: hora});
    }

    escribirHoraSalida = (hora) =>{
        this.setState({ txthorasalida: hora});
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrortxthoraentrada: false })
        this.setState({ vermsgerrortxtrancho: false })
        this.setState({ vermsgerrortxtrejasentrada: false })
        this.setState({ vermsgerrortxtrejassalida: false })
        this.setState({ vermsgerrortxtlote: false })
        this.setState({ vermsgerrortxtfoliotraslado: false })
        this.setState({ vermsgerrortxtvehiculo: false })
        this.setState({ vermsgerrortxtconductor: false })
        this.setState({ vermsgerrortxtplacas: false })
        this.setState({ vermsgerrortxthorasalida: false })
        this.setState({ vermsgerrortxtdestino: false })
        this.setState({vermsgerrortxtcliente:false})

        let validacionescorrectas = true
        let longitudvalida = ""
        let textvalid = ""

        if (this.state.txthoraentrada == "00:00") {
            this.setState({ msgerrortxthoraentrada: "Selecione la hora de entrada" })
            this.setState({ vermsgerrortxthoraentrada: true })
            validacionescorrectas = false;
        }

        if (this.state.infolote != undefined && this.state.infolote.idtipo_corte == 1) {
            longitudvalida = {longitudminima: 1, longitudmaxima: 10};
            textvalid = validarNumerosDecimales(this.state.txtrejasentrada.trim(), longitudvalida);
            if (textvalid.codigo == "invalid") {
                this.setState({ msgerrortxtrejasentrada: textvalid.mensaje })
                this.setState({ vermsgerrortxtrejasentrada: true })
                validacionescorrectas = false;
            }

            textvalid = validarNumerosDecimales(this.state.txtrejassalida.trim(), longitudvalida);
            if (textvalid.codigo == "invalid") {
                this.setState({ msgerrortxtrejassalida: textvalid.mensaje })
                this.setState({ vermsgerrortxtrejassalida: true })
                validacionescorrectas = false;
            }
        }

        if (this.state.txtlote < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortxtlote: "Seleccione un lote" })
            this.setState({ vermsgerrortxtlote: true })
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 20};
        textvalid = validarLongitud(this.state.txtfoliotraslado.trim(), longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrortxtfoliotraslado: textvalid.mensaje })
            this.setState({ vermsgerrortxtfoliotraslado: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 200};
        textvalid = validarLongitud(this.state.txtvehiculo.trim(), longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrortxtvehiculo: textvalid.mensaje })
            this.setState({ vermsgerrortxtvehiculo: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 100};
        textvalid = validarSoloLetrasYEspacios(this.state.txtconductor.trim(), longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrortxtconductor: textvalid.mensaje })
            this.setState({ vermsgerrortxtconductor: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 20};
        textvalid = validarLongitud(this.state.txtplacas.trim(), longitudvalida);
        if (textvalid.codigo == "invalid") {
            this.setState({ msgerrortxtplacas: textvalid.mensaje })
            this.setState({ vermsgerrortxtplacas: true })
            validacionescorrectas = false;
        }

        if (this.state.txthorasalida == "00:00") {
            this.setState({ msgerrortxthorasalida: "Selecione la hora de salida" })
            this.setState({ vermsgerrortxthorasalida: true })
            validacionescorrectas = false;
        }

        if (this.state.txtcliente < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortxtcliente: "Seleccione un cliente" })
            this.setState({ vermsgerrortxtcliente: true })
        }

        if (validacionescorrectas) {
            this.setState({ loadingspinners: true })
            let json = {
                "idcatalogo_bitacora_traslados_detalles": this.state.idcatalogo_bitacora_traslados_detalles,
                "idcatalogo_bitacora_traslados": this.state.idcatalogo_bitacora_traslados,
                "idcatalogo_ranchos_lotes_cultivo":this.state.txtlote,
                "folio": this.state.txtfoliotraslado,
                "fecha": this.state.fecha,
                "horaentrada": this.state.txthoraentrada,
                "horasalida": this.state.txthorasalida,
                "rejasentradavacias": this.state.txtrejasentrada  == "" ? (0) : parseInt(this.state.txtrejasentrada),
                "rejassalidallenas": this.state.txtrejassalida  == "" ? (0) : parseInt(this.state.txtrejassalida),
                "conductor": this.state.txtconductor,
                "vehiculo": this.state.txtvehiculo,
                "placas": this.state.txtplacas,
                "idcatalogo_clientes":this.state.txtcliente,
                "eliminar": 0
            }

            var resp = await postDetallesBitacoraTrasladoGuardar(json);

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio,loadingspinners: false })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio,loadingspinners: false })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio,loadingspinners: false })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio,loadingspinners: false })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else if (resp == -1) {
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio,loadingspinners: false })
                toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }
    }

    actualizarHoraEntrada = (obj) =>{
        let fechaactual= this.state.txthoraentrada.split(":")   

        if(obj.state=="horainicial"){
            fechaactual[0]=obj.valor
        }
        if(obj.state=="minutoinicial"){
            fechaactual[1]=obj.valor
        }

        this.setState({txthoraentrada:fechaactual[0]+":"+fechaactual[1]})

    }

    actualizarHoraSalida = (obj) =>{
        let fechaactual= this.state.txthorasalida.split(":")   

        if(obj.state=="horainicial"){
            fechaactual[0]=obj.valor
        }
        if(obj.state=="minutoinicial"){
            fechaactual[1]=obj.valor
        }

        this.setState({txthorasalida:fechaactual[0]+":"+fechaactual[1]})

    }
    render() {
        return (
            <>
                {/*///////// Sección de spinner ////////*/}
                <ToastContainer />
                {(this.state.loadingspinners == true) ?
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                :null}

                <Modal
                    isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
                        <br />
                        <br />

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/* ////////////////////   Sección de hora entrada   /////////////////////////////////// */}
                                <div style={{display:"flex", marginBottom:"20px"}}>
                                    <div className='col-6 subEncabezado d-flex flex-column justify-content-center ' style={{width:"47%", marginLeft:"0px", marginRight:"auto"}}>
                                        <label className=" lblSubTitulosModal letraTitulos" style={{color: this.state.estilosmodal.colorlblsubtitulosmodal}}>Hora entrada: </label>
                                        <select
                                            className=" "
                                            style={{border:"1px solid black", paddingLeft:"0px", paddingRight:"20px", paddingTop:"10px", paddingBottom:"10px", outline:"none"}}
                                            value={this.state.horainicial}
                                            onChange={(e) => this.actualizarHoraEntrada({ accionorigen: 2, state: "horainicial", valor: e.target.value })}
                                        >
                                            {/* Genera las opciones de hora inicial del 00 al 23 */}
                                            {Array.from({ length: 24 }, (_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')} selected={this.state.txthoraentrada.split(":")[0]==i.toString().padStart(2, '0') ? true:false} >
                                                    {i.toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-6  subEncabezado d-flex flex-column justify-content-end 'style={{width:"47%", marginLeft:"auto", marginRight:"0px"}} >
                                        <label className=" lblSubTitulosModal letraTitulos" style={{color: this.state.estilosmodal.colorlblsubtitulosmodal}} >Minuto entrada:</label>
                                        <select
                                            className=" "
                                            style={{border:"1px solid black", paddingLeft:"0px", paddingRight:"20px", paddingTop:"10px", paddingBottom:"10px", outline:"none"}}
                                            value={this.state.minutoinicial}
                                            onChange={(e) => this.actualizarHoraEntrada({ accionorigen: 2, state: "minutoinicial", valor: e.target.value })}
                                        >
                                            {/* Genera las opciones de minutos del 00 al 59 */}
                                            {Array.from({ length: 60 }, (_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')} selected={this.state.txthoraentrada.split(":")[1]==i.toString().padStart(2, '0') ? true:false}>
                                                    {i.toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {this.state.vermsgerrortxthoraentrada ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxthoraentrada}</p>
                                :null}

                                {/* ////////////////////   Sección de conductor   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Conductor</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtconductor}
                                    onChange={this.escribir("txtconductor")}>
                                </input>

                                {this.state.vermsgerrortxtconductor ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtconductor}</p>
                                :null}

                                {/* ////////////////////   Sección de vehículo   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Vehículo</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtvehiculo}
                                    onChange={this.escribir("txtvehiculo")}>
                                </input>

                                {this.state.vermsgerrortxtvehiculo ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtvehiculo}</p>
                                :null}

                                {/* ////////////////////   Sección de placas   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Placas</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtplacas}
                                    onChange={this.escribir("txtplacas")}>
                                </input>

                                {this.state.vermsgerrortxtplacas ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtplacas}</p>
                                :null}

                                {/* ////////////////////   Sección de lote   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Lote</p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtlote")}>
                                                <option disabled="disabled" selected={this.state.txtlote==0 ? true : false}>Seleccionar</option>
                                                {this.state.listalotes == undefined || this.state.listalotes.length == 0 ?
                                                    null
                                                :
                                                    this.state.listalotes.map((i) => (
                                                        i.idcatalogo_rancho == this.state.txtrancho ?
                                                            <option
                                                                value={i.idcatalogo_ranchos_lotes_cultivo}
                                                                key={"tipo_" + i.idcatalogo_ranchos_lotes_cultivo}
                                                                selected={i.idcatalogo_ranchos_lotes_cultivo==this.state.txtlote ? true : false}
                                                            >
                                                            {i.lote}
                                                            </option>
                                                        :
                                                            null
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrortxtlote ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtlote}</p>
                                :null}

                                {/* ////////////////////   Sección de cultivo   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Cultivo</p>
                                {this.state.txtlote == 0 ?
                                    <input
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'}
                                        placeholder='Escoge un lote'
                                        readOnly>
                                    </input>
                                :
                                    this.state.listalotes == undefined || this.state.listalotes.length == 0 ?
                                        null
                                    :
                                        this.state.listalotes.map((i) => (
                                            i.idcatalogo_ranchos_lotes_cultivo == this.state.txtlote ?
                                                <input
                                                    className='lblTextoModal letraNormal'
                                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                                    type={'text'}
                                                    value={i.cultivo}
                                                    readOnly>
                                                </input>
                                            :null
                                        ))
                                }

                                {/* ////////////////////  Si el tipo de corte del lote es rejas se mostarán los inputs de rejas entrada y rejas salida   ////////////////////////////////////// */}
                                {this.state.infolote != undefined && this.state.infolote.idtipo_corte == 1 ? (
                                    <>
                                        {/* ////////////////////  Sección de rejas entrada   ////////////////////////////////////// */}
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rejas entrada</p>
                                        <input
                                            className='lblTextoModal letraNormal'
                                            style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                            type={'text'}
                                            value={this.state.txtrejasentrada}
                                            onChange={this.escribir("txtrejasentrada")}>
                                        </input>

                                        {this.state.vermsgerrortxtrejasentrada ?
                                            <p className="msgError letraNormal" style={{ color: this.state.colormsgerror}}>{this.state.msgerrortxtrejasentrada}</p>
                                        :null}

                                        {/* ////////////////////  Sección de rejas salida   ////////////////////////////////////// */}
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rejas salida</p>
                                        <input
                                            className='lblTextoModal letraNormal'
                                            style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                            type={'text'}
                                            value={this.state.txtrejassalida}
                                            onChange={this.escribir("txtrejassalida")}>
                                        </input>

                                        {this.state.vermsgerrortxtrejassalida ?
                                            <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtrejassalida}</p>
                                        :null}
                                    </>
                                ) : null}

                                {/* ////////////////////  Sección de folio traslado   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Folio de traslado</p>
                                <input
                                    className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'}
                                    value={this.state.txtfoliotraslado}
                                    onChange={this.escribir("txtfoliotraslado")}>
                                </input>

                                {this.state.vermsgerrortxtfoliotraslado ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtfoliotraslado}</p>
                                :null}

                                {/* ////////////////////  Sección de hora de salida   ////////////////////////////////////// */}
                                <div style={{display:"flex", marginBottom:"20px"}}>
                                    <div className='col-6 subEncabezado d-flex flex-column justify-content-center ' style={{width:"47%", marginLeft:"0px", marginRight:"auto"}}>
                                        <label className=" lblSubTitulosModal letraTitulos" style={{color: this.state.estilosmodal.colorlblsubtitulosmodal}}>Hora salida: </label>
                                        <select
                                            className=" "
                                            style={{border:"1px solid black", paddingLeft:"0px", paddingRight:"20px", paddingTop:"10px", paddingBottom:"10px", outline:"none"}}
                                            value={this.state.horainicial}
                                            onChange={(e) => this.actualizarHoraSalida({ accionorigen: 2, state: "horainicial", valor: e.target.value })}
                                        >
                                            {/* Genera las opciones de hora inicial del 00 al 23 */}
                                            {Array.from({ length: 24 }, (_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')} selected={this.state.txthorasalida.split(":")[0]==i.toString().padStart(2, '0') ? true:false} >
                                                    {i.toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-6  subEncabezado d-flex flex-column justify-content-end 'style={{width:"47%", marginLeft:"auto", marginRight:"0px"}} >
                                        <label className=" lblSubTitulosModal letraTitulos" style={{color: this.state.estilosmodal.colorlblsubtitulosmodal}} >Minuto salida:</label>
                                        <select
                                            className=" "
                                            style={{border:"1px solid black", paddingLeft:"0px", paddingRight:"20px", paddingTop:"10px", paddingBottom:"10px", outline:"none"}}
                                            value={this.state.minutoinicial}
                                            onChange={(e) => this.actualizarHoraSalida({ accionorigen: 2, state: "minutoinicial", valor: e.target.value })}
                                        >
                                            {/* Genera las opciones de minutos del 00 al 59 */}
                                            {Array.from({ length: 60 }, (_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')} selected={this.state.txthorasalida.split(":")[1]==i.toString().padStart(2, '0') ? true:false}>
                                                    {i.toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {this.state.vermsgerrortxthorasalida ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxthorasalida}</p>
                                :null}

                                {/* ////////////////////  Sección de cliente   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Cliente </p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtcliente")}>
                                            <option disabled="disabled" selected={this.state.txtcliente==0 ? true : false}>Seleccionar</option>
                                                {this.state.listaclientes == undefined || this.state.listaclientes.length == 0 ?
                                                    null
                                                :
                                                    this.state.listaclientes.map((i) => (
                                                        <option
                                                            value={i.idcatalogo_clientes}
                                                            key={"tipo_" + i.idcatalogo_clientes}
                                                            selected={i.idcatalogo_clientes==this.state.txtcliente ? true : false}
                                                        >
                                                            {i.nombre}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrortxtcliente ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcliente}</p>
                                :null}

                            </div>
                        </div>

                        {/* ////////////////////  Sección de botones   ////////////////////////////////////// */}
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}