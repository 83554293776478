import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalBuscarProveedorCotizacion.css";
import { estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalSustituirProductosRectificar extends React.Component {

    constructor(props) {
        super(props)
        //console.log("modal de productos", this.props)
        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion,
            titulo: props.titulomodal,

            listaproductos: this.props.listaproductos,

            productoseleccionado: undefined,
            idproductoseleccionado: 0,
            idsproductoseleccionado: [],
            productoseleccionados: [],

            estilosmodal: estilosModal(),
        }
    }

    async componentDidMount() {
        
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {

        //console.log("guardarproductosseleccionados", this.state.idsproductoseleccionado, this.state.listaproductos)

        // Mandar datos del item de producto seleccionado
        var pseleccionados = [];
        
        this.state.idsproductoseleccionado.forEach((i) => {
            this.state.listaproductos.map((p) => {
                if(p.idproducto==i  && p.sustitutode!="" && p.sustitutode !="Sustituido" || p.idproducto==i  && p.sustitutode=="" && p.sustitutode ==""){
                    pseleccionados.push(p)
                }
            })
        })

       this.showModal(pseleccionados)
        
    }

    seleccionarProducto = async (item) => {
        
        if(this.state.idsproductoseleccionado.indexOf(item.idproducto)!=-1){
            this.state.idsproductoseleccionado.splice(this.state.idsproductoseleccionado.indexOf(item.idproducto),1);
        }else{
            this.state.idsproductoseleccionado.push(item.idproducto)
            await this.setState({confirmarenvio: true})
        }
        
        await this.setState({})
    }

    procesarProductos = () => {
     
        var todosProductos = this.props.listaproductos;
        var tarjetaProductos = this.props.productostarjeta;
        var productosSustitutosTarjeta = [];
            
        todosProductos.map((pd)=>{
            if(this.props.accion==3){
                if(pd.sustitutode!="Sustituido"){
                    productosSustitutosTarjeta.push(pd)
                }
            }else{
                if(pd.sustitutode!="Sustituido" && pd.sustitutode!=""){
                    var aux = tarjetaProductos.filter((item) => item.idproducto==pd.sustitutode)
                    if(aux.length > 0){
                        productosSustitutosTarjeta.push(pd)
                    }
                }
            }
        })
    
        return productosSustitutosTarjeta;
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo} </label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                
                                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                    {this.props.isProductoGeneral==true ? <label className='lblTexto letraTitulos'>Selecciona un producto general: </label> : <label className='lblTexto letraTitulos'>Selecciona un agroquímico: </label>}
                                    
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTablaProd">
                                            <Table hover>
                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.procesarProductos().length>0 && this.props.accion==3 ? this.procesarProductos().map((item, index) => (
                                                            item.pendientes > 0.00 ?
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarProducto(item)} className={this.state.idsproductoseleccionado.indexOf(item.idproducto) != -1 ? "trFondoActivoGreen" : ""}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.producto}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {"Disponibles "+item.pendientes}
                                                                </td>
                                                            </tr> : null
                                                        )) : this.procesarProductos().length>0 && this.props.accion!=3 ? this.procesarProductos().map((item, index) => (
                                                            item.cantidad > 0 && !(item.sustituir=="" && item.sustitutode=="") ?
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarProducto(item)} className={this.state.idsproductoseleccionado.indexOf(item.idproducto) != -1 ? "trFondoActivoGreen" : ""}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.producto}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {"Disponibles "+item.cantidadVariable}
                                                                </td>
                                                            </tr> : null
                                                        )) : null
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >AGREGAR</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

