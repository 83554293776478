//respuestas rápidas 
const campovacio = "Este campo es obligatorio"

////////////////////   /validaciones login  //////////////////////////////
export function validarUser(data) {
   let validator = /^[A-Za-z0-9]+$/
   let x = validator.test(data);
   var aviso = "Escribe solo letras y números";

   if (data == "") {
      aviso = campovacio
   }
   return { codigo: (x) ? ("valid") : "invalid", mensaje: aviso };
}

export function validarPassword(data) {
   let validator = /^[A-Za-z0-9]+$/
   let x = validator.test(data);
   var aviso = "Escribe solo letras y números";
   if (data == "") {
      aviso = campovacio
   }
   return { codigo: (x) ? ("valid") : "invalid", mensaje: aviso };
}


export function validarPasswordEliminar(data) {
   let validator = /^[A-Za-z0-9]+$/
   let x = validator.test(data);
   var aviso = "Escribe una contraseña válida";
   if (data == "") {
      aviso = campovacio
   }
   return { codigo: (x) ? ("valid") : "invalid", mensaje: aviso };
}


////////////////////   validaciones Generales  //////////////////////////////
export function validarLetrasNumeros(data) {
   let validator = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{2,100}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras y números";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

/*
export function validarSoloLetras(data) {
   let validator = /^[a-zA-zñÑáéíóúÁÉÍÓÚ ]{2,60}$/
   let x = validator.test(data);

   var aviso = "Escribe solo letras";

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };
}*/


////////////////////// validación longitud texto //////////////////////////
export function validarLetrasNumerosLongitud(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Escribe solo letras y números";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////////////////////// validación longitud texto //////////////////////////
export function validarLetrasNumerosLongitudV2(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permiten letras y números";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

// Se aagrega y espacios en el mensaje
export function validarLetrasNumerosEspacios(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permiten letras, números y espacios";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarLetrasNumerosEspaciosGuiones(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permiten letras, números, guiones y espacios";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

// Misma funcionalidad de validarLetrasNumerosEspaciosGuiones, pero con mensaje diferente
export function validarLetrasNumerosEspaciosGuionesProducto(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Escribe un registro válido";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarLetrasNumerosEspaciosGuionesProductoPuntoComa(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Escribe un registro válido";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarLetrasNumerosEspaciosGuionesProductoPuntoComaCaracteres(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Escribe un registro válido";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////// Valida que el texto solo sea letras y números a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ//////
export function validarSoloLetrasNumeros(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permiten letras y números";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////
export function validarLetrasNumerosPuntoComa(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permiten letras, números y espacios";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarLetrasNumerosPuntoComaOpcional(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]*$`);
   var aviso = "Solo se permiten letras, números y espacios";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (longitudvalida) {
      x = false
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };
}



export function validarLetrasNumerosPuntoComaPorcentaje(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permiten letras, números y espacios";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////////////////////// validación email //////////////////////////
export function validateEmail(data) {
   let validator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[[a-zA-Z0-9-]+[a-zA-Z])$/
   let x = validator.test(data);
   var aviso = "Escribe un email válido.";
   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarTelefono(data) {
   let validator = /^[0-9]{10}$/
   let x = validator.test(data);

   var aviso = "Escribe solo 10 dígitos para el teléfono";
   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarTelefono2(data) {
   let validator = new RegExp(`^[0-9]{10}$`);
   var aviso = "Solo se permiten números";

   let x = validator.test(data);
   let longitudvalida = data.length != 10;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Escribe un teléfono válido`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}


export function validarSoloNumeros(data) {

   let validator = /^[0-9]{1,10}$/
   let x = validator.test(data);

   var aviso = "Escribe solo números";
   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}



////////////////////// validador de texto en mayúscula con números //////////////////////////
export function validarLetrasMayusculasYNumeros(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[A-Z0-9]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permite letras mayúsculas y números sin espacios";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

/////////////// Validador de texto en mayúscula con números, con aviso distinto ///////////////
export function validarLetrasMayusculasNumerosSE(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[A-Z0-9ÑÁÉÍÓÚ]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permiten letras y números sin espacios";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////////////////////// validador de texto solo letras con espacios //////////////////////////
export function validarLetrasConEspacios(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permiten letras y espacios";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////////////////////// validador de texto solo letras  //////////////////////////
export function validarSoloLetrass(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-ZñÑáéíóúÁÉÍÓÚ]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permiten letras";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////////////////////// validador de texto solo letras con espacios //////////////////////////
export function validarSoloLetrasYEspacios(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permiten letras y espacios";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////////////////////// validador longitud //////////////////////////
export function validarLongitud(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;

   var aviso = "";
   let longitudminimavalida = data.length < longitudminima;
   let longitudmaximavalida = data.length > longitudmaxima;
   if (data == "") {
      aviso = campovacio
   } else if (longitudminimavalida) {
      aviso = `Este campo no puede tener menos de ${longitudminima} caracteres`;
   } else if (longitudmaximavalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (aviso) ? "invalid" : "valid",
      mensaje: aviso
   };


}

////////////////////// Validador de RFC  //////////////////////////
export function validarRFC(data, longitudminima) {
   let validator = new RegExp(`^[A-Za-z0-9]{${longitudminima},${longitudminima}}$`);
   var aviso = "Escribe un RFC válido";

   let x = validator.test(data);

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////////////////////// Validador de Código Postal  //////////////////////////
export function validarCodigoPostal(data) {
   let validator = new RegExp(`^[0-9]{5}$`);
   var aviso = "Solo se permiten números";

   let x = validator.test(data);
   let longitudvalida = data.length == 5;

   if (data == "") {
      aviso = campovacio
   } else if (!longitudvalida) {
      aviso = `Escribe un código postal válido`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}


////////////////////// Validador de Registro PROFEPRIS //////////////////////////
export function validarRegistroCOFEPRIS(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Escribe un registro válido";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////////////////////// Validador de Intervalo de Seguridad //////////////////////////
export function validarIntervaloSeguridad(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$ -]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Escribe un intervalo válido";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////////////////////// Validador de Archivo Cargado [.jpeg, .png] //////////////////////////
export function validarArchivoCargadoJpgJpegPng(data) {
   let validator = new RegExp(`(.jpg|.jpeg|.png)$`)
   var aviso = "Solo imágenes JPG, JPEG o PNG"

   let x = (data == null) ? true : validator.test(data.name.toLocaleLowerCase())

   /*if (data == null) {
      aviso = "Selecciona una imagen de mapa"
   }*/

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}


export function validarArchivoCargadoJpgJpegPngPdf(data) {
   let validator = new RegExp(`(.jpg|.jpeg|.png|.pdf)$`)
   var aviso = "Solo imágenes JPG, JPEG o PNG"

   let x = (data == null) ? true : validator.test(data.name.toLocaleLowerCase())

   /*if (data == null) {
      aviso = "Selecciona una imagen de mapa"
   }*/

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarArchivoDimensionMB(base64) {
     
      // Convertir a megabytes
      const sizeInMB = base64.size / (1024 * 1024);
       const aviso = "La imagen debe tener menos de 2MB"

      // Comparar con 3MB
      return {
         codigo: sizeInMB < 2 ? ("valid") : "invalid",
         mensaje: aviso
      }  

}


////////////////////// validador de texto con números //////////////////////////
export function validarNumeros(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[0-9]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permite números enteros";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarNumerosDecimales(data) {
   let validator = new RegExp(`^[0-9]+([.])?([0-9]+)?$`);
   var aviso = "Solo se permiten números";

   let x = validator.test(data);
   //let longitudvalida = data.length == 5;

   if (data == "") {
      aviso = campovacio
   } /*else if (!longitudvalida) {
      aviso = `Escribe un valor valido`;
   }*/

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarNumerosDecimales2(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[0-9]+([.])?([0-9]+)?$`);
   var aviso = "Escribe un número válido";

   var x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
      x = false
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarNumerosDecimales3(data, longitud) {
   let { longitudminimaentera, longitudmaximaentera, longitudminimadecimal, longitudmaximadecimal } = longitud
   let validator = new RegExp(`^([0-9]{${longitudminimaentera},${longitudmaximaentera}})([.][0-9]{${longitudminimadecimal},${longitudmaximadecimal}})?$`)
   let generalvalidator = new RegExp(`^[0-9]*([.][0-9]+)?$`)
   var aviso = "Escribe un número válido"

   var x = validator.test(data)
   var numerosinrestricciones = generalvalidator.test(data)

   if (data == "") {
      aviso = campovacio
      x = false
   } else if (numerosinrestricciones && !x) {
      aviso = `Solo se permiten hasta ${longitudmaximaentera} digitos enteros con ${longitudmaximadecimal} decimales`;
      x = false
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarNumerosConDosDecimales(data, digitos) {
   let { longitudenterominimo, longitudenteromaximo, longituddecimalminimo, longituddecimalmaximo } = digitos;
   let validator = new RegExp(`^[0-9]{${longitudenterominimo},${longitudenteromaximo}}?([.])?([0-9]{${longituddecimalminimo},${longituddecimalmaximo}})?$`);
   var aviso = "Solo se permiten números";

   let x = validator.test(data);

   let longitudvalida = data.length > 1 && data.length < 12;
   if (data == "") {
      aviso = campovacio
   } else if (!longitudvalida) {
      aviso = `Solo esta permitido 9 digitos enteros y 2 decimales`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };


}

export function validarPH(data) {
   let validator = new RegExp(`^([1]|17)`);
   var aviso = "Solo se permiten números del 1 al 17";

   let x = false;
   if (data > 0 && data < 18)
      x = true;

   if (data == "") {
      aviso = campovacio
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarPorcentajeConcentracion(data) {
   let validator = new RegExp('^(100([.][0]{1,2})?|([0-9]{0,2})?([.][0-9]{1,2})?)[%]$')
   var aviso = "Ingrese un registro válido. Ejemplo: 12.5%"

   let x = validator.test(data)
   let numeroporcentaje = parseFloat(data.substring(0, data.length - 1))
   let rangovalido = numeroporcentaje >= 0.1 && numeroporcentaje <= 100

   if (data == "") {
      aviso = campovacio
   } else if (!rangovalido) {
      x = false
      var aviso = "Solo se permiten porcentajes del 0.1% al 100.00%"
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   }

}

////////////////////// validación longitud texto //////////////////////////
export function validarPasswordPerfilUsuario(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Escribe solo letras y números";

   let x = validator.test(data);
   let longitudvalidami = data.length < longitudminima
   let longitudvalidama = data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalidami) {
      aviso = `Este campo no permite menos de ` + longitudminima + ` caracteres`;
   } else if (longitudvalidama) {
      aviso = `Este campo no puede superar más de ` + longitudmaxima + ` caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarNumerosDecimalesPresupuesto(data, longitud) {
   let { longitudminimaentera, longitudmaximaentera, longitudminimadecimal, longitudmaximadecimal } = longitud
   let validator = new RegExp(`^([0-9]{${longitudminimaentera},${longitudmaximaentera}})([.][0-9]{${longitudminimadecimal},${longitudmaximadecimal}})?$`)
   let generalvalidator = new RegExp(`^[0-9]*([.][0-9]+)?$`)
   var aviso = "Solo se permiten números enteros y decimales"

   var x = validator.test(data)
   var numerosinrestricciones = generalvalidator.test(data)

   if (data == "") {
      aviso = 'Ingresa un presupuesto'
      x = false
   } else if (numerosinrestricciones && !x) {
      aviso = `Solo se permiten hasta ${longitudmaximaentera} digitos enteros con ${longitudmaximadecimal} decimales`;
      x = false
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

export function validarNumerosDecimalesAlmacenCombustible(data, longitud) {
   let { longitudminimaentera, longitudmaximaentera, longitudminimadecimal, longitudmaximadecimal } = longitud
   let validator = new RegExp(`^([0-9]{${longitudminimaentera},${longitudmaximaentera}})([.][0-9]{${longitudminimadecimal},${longitudmaximadecimal}})?$`)
   let generalvalidator = new RegExp(`^[0-9]*([.][0-9]+)?$`)
   var aviso = "Solo se permiten números"

   var x = validator.test(data)
   var numerosinrestricciones = generalvalidator.test(data)

   if (data == "") {
      aviso = campovacio
      x = false
   } else if (numerosinrestricciones && !x) {
      aviso = `Solo se permiten hasta ${longitudmaximaentera} digitos enteros con ${longitudmaximadecimal} decimales`;
      x = false
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}

////////////////////// Validación descripción orden trabajo //////////////////////////
export function validarDescripciónOrdenTrabajo(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ_;:,."/#¿?()&%$\s\S\n  =]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "No se permiten caracteres especiales";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}







/////////////////////////// validar números de precios con 4 después del punto decimal, y con separaciones con comas 
export function formatoNumero(valor) {
   var internationalNumberFormat = new Intl.NumberFormat('es-MX', {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
      currency: "MXN",
   });
   return internationalNumberFormat.format(valor)
}

export function formatoNumero2(valor) {
   var internationalNumberFormat = new Intl.NumberFormat('es-MX', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      roundingMode: "trunc", // para indicar si queremos que el redondeo se aplique o no
      currency: "MXN",
   });
   return internationalNumberFormat.format(valor)
}

export function formatoNumeroTecla(valor, numdigitos) {
   var internationalNumberFormat = new Intl.NumberFormat('es-MX', {
      minimumFractionDigits: numdigitos,
      maximumFractionDigits: numdigitos,
      roundingMode: "trunc", // para indicar si queremos que el redondeo se aplique o no
      currency: "MXN",
   });
   return internationalNumberFormat.format(valor)
}



///////////////////////////////// validar porcentaje ////////////////////////////////////////


export function validarPorcentaje(data, longitud) {
   let { longitudminimadecimal, longitudmaximadecimal } = longitud
   let validator = new RegExp(`^(0?)([.][0-9]{${longitudminimadecimal},${longitudmaximadecimal}})?$`)
   let generalvalidator = new RegExp(`^[0-9]*([.][0-9]+)?$`)
   var aviso = "Escribe un número válido"

   var x = validator.test(data)
   var numerosinrestricciones = generalvalidator.test(data)

   if (data == "") {
      aviso = campovacio
      x = false
   } else if (numerosinrestricciones && !x) {
      aviso = `Solo se permiten hasta 2 dígitos decimales entre 0 y 1`;
      x = false
   } else if (parseFloat(data) <= 0) {
      aviso = `Solo se permiten  hasta 2 dígitos decimales entre 0 y 1`;
      x = false
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}




////////////////////// validador de texto en mayúscula con números //////////////////////////
export function validarLetrasMayusculasYNumerosNomina(data, longitud) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[A-Z0-9]{${longitudminima},${longitudmaxima}}$`);
   var aviso = "Solo se permite letras mayúsculas y números sin espacios";

   let x = validator.test(data);
   let longitudvalida = data.length < longitudminima || data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio
   } else if (longitudvalida) {
      aviso = `Este campo no puede tener más de ${longitudmaxima} caracteres, ni menos de ${longitudminima}`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };

}