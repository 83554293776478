import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { BiEdit } from "react-icons/bi";
import { BsEye } from "react-icons/bs";
import { ImDownload3 } from 'react-icons/im'
import { Redirect } from 'react-router-dom';
import { FaTrashAlt, FaCalendar } from "react-icons/fa";
import { getListaZonas, getListaRanchosDisponibles, postCatalogoTrasladoRecepcion, postCatalogoTrasladoRecepcionEliminar } from '../../Services/Api';
import ModalConfirarAccion from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalConfirmarAccion';
import ModalFormularioBitacoraRecepcionFruta from '../../Complements/Modales/ModalFormularioBitacoraRecepcionFruta/ModalFormularioBitacoraRecepcionFruta';
import ModalFormularioTicketRecepcionFruta from '../../Complements/Modales/ModalFormularioTicketRecepcionFruta/ModalFormularioTicketRecepcionFruta';
import ModalFormularioFolioTrasladoRecepcionFruta from '../../Complements/Modales/ModalFormularioFolioTrasladoRecepcionFruta/ModalFormularioFolioTrasladoRecepcionFruta';
import ModalVerImagenRecepcionFruta from '../../Complements/Modales/ModalVerImagenRecepcionFruta/ModalVerImagenRecepcionFruta';

const todoslosfiltrosdisponibles = -1

export default class CatalogoBitacoraRecepcionFruta extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      listazonas: [],
      listaranchos: [],
      fechabusquedainicio: new Date(),
      fechabusquedafin: new Date(),
      txtfechabusquedainicio: "",
      txtfechabusquedafin: "",


      clienteselecto: todoslosfiltrosdisponibles,
      listaclientes: [],
      frutaselecta: todoslosfiltrosdisponibles,
      listafrutas: [],

      elementosfiltrados: [],

      loadingspinners: true,

      idcatalogo: 0,
      modal: false,
      modalverimagen: false,
      modalticket: false,
      modalfoliotraslado: false,

      accionmodal: 1,
      titulomodal: '',
      itemseleccionado: null,
      modalconfirmaraccion: false,
      itemeliminar: undefined,

      tabla: {
        encabezados: [
          "Fecha",
          "Hora recepción",
          "Folio traslado",
          //"Tipo Fruta",
          "Recibe",
          "#Rejas",
          "Peso inicial",
          "Peso final",
          "Neto",
          "#Ticket",
          "Cliente",
          "Comprobante",
          "Ticket",
          "",
          ""],
        dimensiones: [
          "120px",
          "150px",
          "130px",
          //"120px",
          "120px",
          "80px",
          "115px",//
          "115px",//
          "115px",//
          "130px",
          "140px",
          "180px",
          "180px",
          "120px",
          "130px"
        ],
        mindimensiones: [
          "120px",
          "150px",
          "130px",
          //"120px",
          "120px",
          "80px",
          "115px",//
          "115px",//
          "115px",//
          "130px",
          "140px",
          "180px",
          "180px",
          "120px",
          "130px"
        ],
        elementos: []
      },

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 10,
      listapaginada: [],
      filtrolistapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,

      colorcbxtipolbl: estiloComboBox().colorcbxlbl,

      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,

      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    //obtiene catalogo de zonas
    var respzonas = await getListaZonas();
    if (this.manejadorLlamadasApis(respzonas)) {
      return;
    }

    //obtiene catalogo de ranchos
    var respranchos = await getListaRanchosDisponibles();
    if (this.manejadorLlamadasApis(respranchos)) {
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = [];

    var fechaactualFin = new Date();
    const fechaa = fechaactualFin.getDate();
    fechaactualFin.setDate(fechaa);
    const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

    var fechaactualIni = new Date();
    fechaactualIni.setDate(fechaactualIni.getDate() - 7);
    const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');

    this.setState({
      txtfechabusquedainicio: defaultValueFechaInicial,
      txtfechabusquedafin: defaultValue,
      fechabusquedainicio: fechaactualIni,
      tabla: tabla,
      listazonas: respzonas.data,
      listaranchos: respranchos.data,
    })

    setTimeout(() => {
      this.consultarCatalogoBitacoraRecepcionFruta()
      this.dimensiones();
      window.addEventListener("resize", this.dimensiones);
      this.setState({ loadingspinners: false })
    }, 300);
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  filtrarCombo = async (e) => {
    let itemselecto = e.target.value
    var filtroclientes = this.state.tabla.elementos;
    let clienteselecto=this.state.clienteselecto
    let frutaselecta= this.state.frutaselecta

    if (e.target.id == "cliente") {
      clienteselecto=itemselecto
      
    }  else if (e.target.id == "fruta") {
      frutaselecta=itemselecto
    }

    this.setState({ frutaselecta: frutaselecta , clienteselecto: clienteselecto })    

    if (clienteselecto != todoslosfiltrosdisponibles) {
      filtroclientes = filtroclientes.filter((item) => item.cliente == clienteselecto)
    }

/*    if (frutaselecta != todoslosfiltrosdisponibles) {
    filtroclientes = filtroclientes.filter((item) => item.personarecibe == frutaselecta)
    }*/

  

    if (filtroclientes.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroclientes)

  }



  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);
  }

  cambiarContenido = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        elementosfiltrados: array,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, array),
        //filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);
  }







  consultarCatalogoBitacoraRecepcionFruta = async (llave, valor) => {
    var formatofechaini = "";
    var formatofechafin = "";
    var fechainiaux = 'txtfechabusquedainicio' == llave ? valor : this.state.txtfechabusquedainicio
    var fechafinaux = 'txtfechabusquedafin' == llave ? valor : this.state.txtfechabusquedafin

    if (fechainiaux.length > 0) {
      var [year, month, day] = fechainiaux.split("-");
      formatofechaini = `${day}/${month}/${year}`
    }

    if (fechafinaux.length > 0) {
      var [year, month, day] = fechafinaux.split("-");
      formatofechafin = `${day}/${month}/${year}`
    }

    var requestbody = {
      fechainicio: formatofechaini,
      fechafin: formatofechafin,
    }

    var fechasvalidas = await this.validarFechas(formatofechaini, formatofechafin)
    if (fechasvalidas == 1) {
      this.setState({ loadingspinners: true })
      var resptrasladofruta = await postCatalogoTrasladoRecepcion(requestbody);
      if (this.manejadorLlamadasApis(resptrasladofruta)) {
        return;
      }

      var tabla = this.state.tabla;
      //generar lista de clientes local
      let listaclientes = [...new Set(resptrasladofruta.data.map(item => item.cliente))];
      let listafrutas = [...new Set(resptrasladofruta.data.map(item => item.personarecibe))];
      tabla.elementos = resptrasladofruta.data;
      this.setState({
        tabla: tabla,
        listaclientes: listaclientes,
        listafrutas: listafrutas
      })


      this.cambiarContenido(1, tabla.elementos);
      this.setState({ loadingspinners: false })
    }
  }

  validarFechas = async (formatofechaini, formatofechafin) => {
    var fechainicio = new Date();
    var fechafin = new Date();
    var fechainiciovalido = false;
    var fechafinvalido = false;
    if (formatofechaini.length > 0) {
      fechainiciovalido = true;
      var fechainicial = formatofechaini.split("/");
      fechainicio.setFullYear(fechainicial[2], fechainicial[1], fechainicial[0]);
    }

    if (formatofechafin.length > 0) {
      fechafinvalido = true;
      var fechafinal = formatofechafin.split("/");
      fechafin.setFullYear(fechafinal[2], fechafinal[1], fechafinal[0]);
    }

    if (fechainiciovalido && fechafinvalido) {
      if (fechainicio <= fechafin) {
        return 1;
      } else {
        toast.info("Fecha inicio no puede ser mayor que fecha fin", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        var tabla = this.state.tabla;
        tabla.elementos = [];

        await this.setState({
          tabla: tabla
        })
        this.cambairContenido(1);
        return 2;
      }
    }
    return 0;
  }



  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  actualizarTabla = async () => {
    this.setState({ loadingspinners: true, errordeacceso: false })
    this.componentDidMount();
  }

  format_numero = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  }

  format_numero_decimales = (number) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
  }

  format_kilogramos = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'unit', unit: 'kilogram', minimumFractionDigits: 2 }).format(number);
  }

  cambiarFecha = (item) => {
    this.setState({ [item.llave]: item.valor })

    let day = `0${item.valor.getDate()}`.slice(-2)
    let month = `0${item.valor.getMonth() + 1}`.slice(-2)
    let year = item.valor.getFullYear()
    this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

    this.consultarCatalogoBitacoraRecepcionFruta(['txt' + item.llave], year + '-' + month + '-' + day)
  }

  verModal = async (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nueva bitácora recepción de fruta', accionmodal: 1, modal: true })
    }
    else if (ban.opcion == 2) { //Editar
      this.setState({ titulomodal: 'Editar bitácora recepción de fruta', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)), modal: true });
    }
    else if (ban.opcion == 3) { //Eliminar
      this.setState({ titulomodal: 'Confirmar acción', accionmodal: "registro", modalconfirmaraccion: true, itemeliminar: ban.contenido });
    }
    else if (ban.opcion == 4) { //Cargar imagen de folio traslado
      this.setState({ titulomodal: ban.contenido.folio, accionmodal: 1, modalfoliotraslado: true, idcatalogo: ban.contenido.idcatalogo_bitacora_traslados_recepcion })
    }
    else if (ban.opcion == 5) {//Cargar imagen de ticket
      this.setState({ titulomodal: ban.contenido.ticket, accionmodal: 1, modalticket: true, idcatalogo: ban.contenido.idcatalogo_bitacora_traslados_recepcion })
    }
    else if (ban.opcion == 6) {//Ver imagen de folio traslado
      this.setState({ titulomodal: ban.contenido.folio, modalverimagen: true, imagen: ban.contenido.imagencomprobante })
    }
    else if (ban.opcion == 7) {//Ver imagen de ticket
      this.setState({ titulomodal: ban.contenido.ticket, modalverimagen: true, imagen: ban.contenido.imagenticket })
    }
    this.setState({ loadingspinners: false });
    //this.actualizarTabla();
  }

  cerrarModal = (accion) => {
    this.setState({ modal: !this.state.modal, loadingspinners: false })
    if (accion == 1) {
      this.setState({ loadingspinners: false })
      toast.success("Bitácora creada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Bitácora editada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
  }

  cerrarModalFolioTraslado = (accion) => {
    this.setState({ modalfoliotraslado: !this.state.modalfoliotraslado, loadingspinners: false })
    if (accion == 1) {
      this.setState({ loadingspinners: false })
      toast.success("Folio agregado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Folio actualizado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
  }

  cerrarModalTicket = (accion) => {
    this.setState({ modalticket: !this.state.modalticket, loadingspinners: false })
    if (accion == 1) {
      this.setState({ loadingspinners: false })
      toast.success("Ticket agregado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Ticket actualizado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
  }

  cerrarModalVerImagen = () => {
    this.setState({ modalverimagen: !this.state.modalverimagen, loadingspinners: false })
  }

  eliminarElemento = async (elemento) => {
    var resp = undefined;
    this.setState({ modalconfirmaraccion: !this.state.modalconfirmaraccion })
    if (elemento.accion == 1) {
      this.setState({ loadingspinners: true })
      let json = {
        "idcatalogo_bitacora_traslados_recepcion": elemento.item.idcatalogo_bitacora_traslados_recepcion
      }
      var resp = await postCatalogoTrasladoRecepcionEliminar(json);

      if (resp.code == 0) {
        this.componentDidMount();
        toast.success("Se borró el elemento exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      else if (resp.code == 300) {
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if (resp.code == 400) {
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if (resp.code == 1000) {
        this.setLoadingSpinner(false);
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
    }
  }

  render() {
    return (
      <>
        {/*///////// Sección de modales, spinner y redireccionamiento ////////*/}
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}

        <ToastContainer />
        {(this.state.loadingspinners == true) ?
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
          : null}

        {(this.state.redireccionando) ?
          <Acciones bandera={'goreferencias'} />
          : null}

        {(this.state.modal == true) ?
          <ModalFormularioBitacoraRecepcionFruta
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModal}
            show={this.state.modal}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            listazonas={this.state.listazonas}
            listaranchos={this.state.listaranchos}
            setLoadingSpinner={this.setLoadingSpinner}
          />
          : null}

        {(this.state.modalverimagen == true) ?
          <ModalVerImagenRecepcionFruta
            titulomodal={this.state.titulomodal}
            imagen={this.state.imagen}
            cerrarModal={this.cerrarModalVerImagen}
            show={this.state.modalverimagen}
            setLoadingSpinner={this.setLoadingSpinner}
          />
          : null}

        {(this.state.modalfoliotraslado == true) ?
          <ModalFormularioFolioTrasladoRecepcionFruta
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalFolioTraslado}
            show={this.state.modalfoliotraslado}
            accion={this.state.accionmodal}
            id={this.state.idcatalogo}
            setLoadingSpinner={this.setLoadingSpinner}
          />
          : null}

        {(this.state.modalticket == true) ?
          <ModalFormularioTicketRecepcionFruta
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalTicket}
            show={this.state.modalticket}
            accion={this.state.accionmodal}
            id={this.state.idcatalogo}
            setLoadingSpinner={this.setLoadingSpinner}
          />
          : null}

        {(this.state.modalconfirmaraccion == true) ?
          <ModalConfirarAccion
            titulomodal={this.state.titulomodal}
            cerrarModalConfirmar={this.eliminarElemento}
            show={this.state.modalconfirmaraccion}
            cuerpo={this.state.accionmodal}
            item={this.state.itemeliminar}
          />
          : null}

        {/*///////// Sección de navbar ////////*/}
        <Navbar />

        {/*///////// Sección de encabezado y filtros ////////*/}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">
            <div className='row paddingEncabezados align-items-end'>

              {/* ////////////////////  Título  ////////////////////////////////////// */}
              <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Bitácora recepción de fruta</label>
              </div>

              {/* ////////////////////   Filtro de fechas   ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='' >
                  <div className='row contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar, paddingRight: "5px" }}>

                    {/* ////////////////////   Fecha inicio   ////////////////////////////////////// */}
                    <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                      <label className='lblTexto letraTitulos ' style={{ color: this.state.colortipolbl, paddingLeft: "8px" }}>Fecha de:</label>
                    </div>
                    <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                      <DatePicker
                        selected={this.state.fechabusquedainicio}
                        onChange={(date) => this.cambiarFecha({ llave: "fechabusquedainicio", valor: date })}
                        dateFormat="dd/MM/yyyy"
                        locale="es"
                        customInput={
                          <DateButton />
                        }
                      />
                    </div>

                    {/* ////////////////////   Fecha fin   ////////////////////////////////////// */}
                    <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, textAlign: 'center' }}>a:</label>
                    </div>
                    <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                      <DatePicker
                        selected={this.state.fechabusquedafin}
                        onChange={(date) => this.cambiarFecha({ llave: "fechabusquedafin", valor: date })}
                        dateFormat="dd/MM/yyyy"
                        locale="es"
                        customInput={
                          <DateButton />
                        }
                      />
                    </div>

                  </div>
                </div>
              </div>


              {/* /////////////// buscador de clientes ////////////////////////// */}

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Cliente</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" id="cliente" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarCombo(event)}>
                      <option selected={this.state.clienteselecto == todoslosfiltrosdisponibles} value={todoslosfiltrosdisponibles}>Todos</option>
                      {this.state.listaclientes.map((i, index) => (
                        <option value={i} key={"tipo_" + index} selected={i == this.state.clienteselecto} >{i} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/*<div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Fruta</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" id="fruta" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarCombo(event)}>
                      <option selected={this.state.frutaselecta == todoslosfiltrosdisponibles} value={todoslosfiltrosdisponibles}>Todas</option>
                      {this.state.listafrutas.map((i, index) => (
                        <option value={i} key={"tipo_" + index} selected={i == this.state.frutaselecta} >{i} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>*/}





              {/* ////////////////////   Botón de refrescar lista   ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              {/* ////////////////////   Botón para agregar nuevo registro   ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.verModal.bind(this, { opcion: "1" })}
                >+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>
        <br />

        {/* ////////////////////   Sección de tabla   ////////////////////////////////////// */}
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximo2 ">
            <div className="containerSubTabla">

              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {this.state.listapaginada.map((item, index) => (
                    <tr key={"tabla" + index}>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorencabezadostabla }}>
                        {item.fecha}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla }}>
                        {item.hora}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorencabezadostabla }}>
                        {item.folio}
                      </td>
                      {/*<td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorencabezadostabla }}>
                        Tipo Fruta
                      </td>*/}
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorencabezadostabla }}>
                        {item.personarecibe}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4], color: this.state.colorencabezadostabla, textAlign: 'right' }}>
                        {this.format_numero(item.rejas)}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5], color: this.state.colorencabezadostabla, textAlign: 'right' }}>
                        {this.format_kilogramos(item.pesoinicial)}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6], color: this.state.colorencabezadostabla, textAlign: 'right' }}>
                        {this.format_kilogramos(item.pesofinal)}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7], color: this.state.colorencabezadostabla, textAlign: 'right' }}>
                        {this.format_kilogramos(item.pesoneto)}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[8], width: this.state.tabla.dimensiones[8], color: this.state.colorencabezadostabla }}>
                        {item.ticket}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[9], width: this.state.tabla.dimensiones[9], color: this.state.colorencabezadostabla }}>
                        {item.cliente}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[10], width: this.state.tabla.dimensiones[10], color: this.state.colorencabezadostabla }}>
                        {item.imagencomprobante != "" ?
                          <div style={{ display: "flex", width: "100%" }}>
                            <label
                              onClick={() => this.verModal({ opcion: "6", contenido: item })}
                              style={{ color: this.state.colorverlbl, margin: "auto" }}
                            >
                              <u>
                                <BsEye className="icoBotones" />
                                <b className='btnVerTabla'>Ver </b>
                              </u>
                            </label>
                            <a href={item.imagencomprobante} download style={{ width: "40px !important", margin: "auto", textAlign: 'center' }}>
                              <div className='divBotonesTrash' style={{ width: "40px", marginRight: '10px' }}>
                                <ImDownload3 className="icoBotonesTrash" />
                              </div>
                            </a>
                            <button className='btnEditar'
                              style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, textAlign: 'center', marginLeft: '10px' }}
                              onClick={this.verModal.bind(this, { opcion: "4", contenido: item })}>
                              <BiEdit className="icoBotones" />
                            </button>
                          </div>
                          :
                          <div style={{ display: "flex", width: "100%" }}>
                            <button
                              className='btnEditar'
                              style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, textAlign: 'center', marginLeft: '10px' }}
                              onClick={this.verModal.bind(this, { opcion: "4", contenido: item })}
                            >
                              <BiEdit className="icoBotones" />
                            </button>
                          </div>
                        }
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[11], width: this.state.tabla.dimensiones[11], color: this.state.colorencabezadostabla }}>
                        {item.imagenticket != "" ?
                          <div style={{ display: "flex", width: "100%" }}>
                            <label
                              onClick={() => this.verModal({ opcion: "7", contenido: item })}
                              style={{ color: this.state.colorverlbl, margin: "auto" }}
                            >
                              <u>
                                <BsEye className="icoBotones" />
                                <b className='btnVerTabla'>Ver </b>
                              </u>
                            </label>
                            <a href={item.imagenticket} download style={{ width: "40px !important", margin: "auto", textAlign: 'center' }}>
                              <div className='divBotonesTrash' style={{ width: "40px", marginRight: '10px' }}>
                                <ImDownload3 className="icoBotonesTrash" />
                              </div>
                            </a>
                            <button
                              className='btnEditar'
                              style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, textAlign: 'center', marginLeft: '10px' }}
                              onClick={this.verModal.bind(this, { opcion: "5", contenido: item })}
                            >
                              <BiEdit className="icoBotones" />
                            </button>
                          </div>
                          :
                          <div style={{ display: "flex", width: "100%" }}>
                            <button
                              className='btnEditar'
                              style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, textAlign: 'center', marginLeft: '10px' }}
                              onClick={this.verModal.bind(this, { opcion: "5", contenido: item })}
                            >
                              <BiEdit className="icoBotones" />
                            </button>
                          </div>
                        }
                      </td>
                      <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[12], width: this.state.tabla.dimensiones[12] }}>
                        <button
                          className='btnEditar'
                          style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar }}
                          onClick={this.verModal.bind(this, { opcion: "2", contenido: item })}
                        >
                          <BiEdit className="icoBotones" />Editar
                        </button>
                      </td>
                      <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[13], width: this.state.tabla.dimensiones[13] }}>
                        <button
                          className='btnEditar'
                          style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar }}
                          onClick={this.verModal.bind(this, { opcion: "3", contenido: item })}
                        >
                          <FaTrashAlt className="icoBotones" />Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

            </div>
          </div>
        </div>

        {/* ////////////////////   Sección de paginación   ////////////////////////////////////// */}
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.elementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e, this.state.elementosfiltrados)}
          />
        </div>
      </>
    )
  }
}

{/* ////////////////////   Función de redireccionamiento   ////////////////////////////////////// */ }
function Acciones({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}

{/* ////////////////////   Botón de calendario   ////////////////////////////////////// */ }
const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
  </button>
));